import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  LogOutIcon,
  UsersRoundIcon,
  TrendingUpIcon,
  CircleDashedIcon,
  TablePropertiesIcon,
  HomeIcon,
  HandCoins,
  MenuIcon,
  XIcon,
  GitForkIcon,
  ChevronLeftIcon,
  SettingsIcon,
} from "lucide-react";

import Logo from "../assests/Logo.jpg";
import { cn } from "../utils/utils";
import { logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";

// Hover: Object containing icon components for the sidebar
const Icons = {
  home: <HomeIcon className="mr-5 h-[18px] w-[18px]" />,
  home2: <HomeIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  users: <UsersRoundIcon className="mr-5 h-[18px] w-[18px]" />,
  users2: <UsersRoundIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  advice: <TrendingUpIcon className="mr-5 h-[18px] w-[18px]" />,
  advice2: <TrendingUpIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  status: <CircleDashedIcon className="mr-5 h-[18px] w-[18px]" />,
  status2: <CircleDashedIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  universal: <TablePropertiesIcon className="mr-5 h-[18px] w-[18px]" />,
  universal2: <TablePropertiesIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  strategy: <GitForkIcon className="mr-5 h-[18px] w-[18px] -rotate-90" />,
  strategy2: <GitForkIcon className="mr-0 ml-1 h-[24px] w-[24px] -rotate-90" />,
  settings: <SettingsIcon className="mr-5 h-[18px] w-[18px]" />,
  settings2: <SettingsIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  plan: <HandCoins className="mr-5 h-[18px] w-[18px]" />,
  plans2: <HandCoins className="mr-0 ml-1 h-[24px] w-[24px]" />,
};

// Hover: Array of navigation items for the sidebar
const sideBarNavItems = [
  {
    title: "Home",
    href: "/admin",
    path: "admin",
    icon: "home",
    icon2: "home2",
    tooltip: "Home",
  },
  {
    title: "Client List",
    href: "/admin/client-list",
    path: "client-list",
    icon: "users",
    icon2: "users2",
    tooltip: "Client List",
  },
  {
    title: "Advice Status",
    href: "/admin/send-recommendation-status",
    path: "send-recommendation-status",
    icon: "status",
    icon2: "status2",
    tooltip: "Advice Status",
  },
  {
    title: "Plans",
    href: "/admin/admin-plans",
    path: "admin-plans",
    icon: "plan",
    icon2: "plans2",
    tooltip: "Plans",
  },
  {
    title: "Model Portfolio",
    href: "/admin/admin-strategy",
    path: "admin-strategy",
    icon: "strategy",
    icon2: "strategy2",
    tooltip: "Strategy",
  },
  {
    title: "Settings",
    href: "/admin/admin-settings",
    path: "admin-settings",
    icon: "settings",
    icon2: "settings2",
    tooltip: "Settings",
  },
];

// Hover: Main component for the admin dashboard navigation
const AdminDashboardNav = ({
  isExpanded,
  toggleExpand,
  adminEmail,
  adminName,
  adminImgUrl,
  ...props
}) => {
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [showLogoutModel, setShowLogoutModel] = useState(false);

  // Hover: Function to handle user logout
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  // Hover: Retrieve user details from local storage
  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  // Hover: Generate user initials from full name
  const intials =
    userDetails && userDetails[4]
      ? userDetails[4]
          .split(" ")
          .map((name) => name[0])
          .join("")
          .toUpperCase()
      : "";

  // Hover: Render the sidebar navigation
  const renderSidebarNav = () => (
    <nav
      className={`bg-black grid items-start ${
        isExpanded ? "gap-y-0" : "gap-y-1"
      }`}
    >
      {sideBarNavItems.map((item, index) => {
        const Icon = Icons[item.icon];
        const Icon2 = Icons[item.icon2];
        return (
          item.href && (
            <div key={index}>
              <Link to={item.disabled ? "/" : item.href}>
                <div
                  className={cn(
                    "group relative text-sm border-l-4 hover:bg-[#ffffff]/10 hover:text-gray-300 text-[15px] text-zinc-500 font-normal font-poppins leading-[22px] flex items-center",
                    path === item.href
                      ? "bg-[#ffffff]/10 border-l-4 border-[#ffffff] text-[#ffffff]"
                      : "border-l-4 border-transparent bg-transparent",
                    item.disabled && "cursor-not-allowed opacity-80",
                    isExpanded ? "px-[24px] py-[10px]" : "pl-[10px] py-[10px]"
                  )}
                >
                  {isExpanded ? Icon : Icon2}
                  <span>{isExpanded ? item.title : ""}</span>
                  {!isExpanded && (
                    <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-black text-white">
                      {item.tooltip}
                    </div>
                  )}
                </div>
              </Link>
            </div>
          )
        );
      })}
    </nav>
  );

  // Hover: Render the logout button
  const renderLogoutButton = () => (
    <div
      onClick={() => setShowLogoutModel(true)}
      className={cn(
        "w-full group relative cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#ffffff]/10 hover:text-gray-300 text-[15px] text-zinc-500 font-normal font-poppins leading-[22px] flex items-center",
        isExpanded ? "px-[24px] py-[10px]" : "pl-[10px] py-[10px]"
      )}
    >
      <LogOutIcon
        className={`${
          isExpanded ? "h-[18px] w-[18px] mr-5" : "h-[24px] w-[24px] mr-0 ml-1"
        } text-zinc-500 group-hover:text-gray-300`}
      />
      <span>{isExpanded ? "Logout" : ""}</span>
      {!isExpanded && (
        <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-black text-white">
          Logout
        </div>
      )}
    </div>
  );

  // Hover: Render the user profile section
  const renderUserProfile = () =>
    isExpanded ? (
      <div className="absolute bottom-2 w-full flex h-[60px] space-x-4 items-center py-4 text-white text-center border-t-[1px] border-[#ffffff]/20 px-6">
        <div className="flex space-x-3 items-center text-white text-center">
          {adminImgUrl && adminImgUrl !== "" && adminImgUrl !== null ? (
            <div className="flex items-center justify-center rounded-full ring-[3px] ring-[#ffffff]/20 w-[40px] h-[40px] cursor-pointer">
              <img
                src={adminImgUrl}
                alt="Profile Image"
                width={50}
                className="rounded-full"
              />
            </div>
          ) : (
            <div className="flex font-medium font-poppins text-[20px] items-center justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[45px] h-[45px] p-1 cursor-pointer">
              {adminEmail.slice(0, 2).toUpperCase()}
            </div>
          )}
          <div className="flex flex-col items-start">
            <span className="text-[15px] text-[#F2F2F2] font-poppins font-medium">
              {adminName}
            </span>
            <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal">
              {adminEmail}
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className="w-full absolute bottom-2 flex items-center justify-center text-white text-center mb-2">
        {adminImgUrl && adminImgUrl !== "" && adminImgUrl !== null ? (
          <div className="cursor-pointer flex items-center justify-center rounded-full ring-[3px] ring-[#ffffff]/20 w-[40px] h-[40px]">
            <img
              src={adminImgUrl}
              alt="Profile Image"
              width={40}
              className="rounded-full"
            />
          </div>
        ) : (
          <div className="cursor-pointer flex font-medium font-poppins text-[20px] items-center justify-center rounded-full border-[2px] border-[#16A085] w-[40px] h-[40px] p-1">
            {adminEmail.slice(0, 2).toUpperCase()}
          </div>
        )}
      </div>
    );

  // Hover: Render the logout confirmation modal
  const renderLogoutModal = () =>
    showLogoutModel && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
        <div className="relative">
          <div
            className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
            onClick={() => setShowLogoutModel(false)}
          >
            <XIcon className="w-5 h-5 mr-2" />
          </div>
          <div className="w-[480px] px-12 py-8 bg-white border-[#000000]/20 rounded-md">
            <div className="text-[20px] font-medium font-poppins text-center">
              Are you sure you want to logout?
            </div>
            <div className="mt-8 text-[18px] font-normal font-poppins text-center">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                <button
                  onClick={() => setShowLogoutModel(false)}
                  className="flex items-center justify-center bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-8 py-2 cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  onClick={onLogout}
                  className="flex items-center justify-center bg-[#ff0000] text-white text-[18px] font-medium font-poppins rounded-md px-8 py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={`sticky top-0 hidden lg:flex h-screen bg-black ${
        isExpanded ? "w-[300px]" : "w-[65px]"
      } transition-width duration-300`}
    >
      <aside className="max-h-screen w-full flex-col lg:flex bg-black border-r-[1px] border-[#454646]/40">
        <div
          className={`w-full flex flex-row items-center ${
            isExpanded ? "px-6 py-8" : "pl-[10px] py-6"
          }`}
        >
          {isExpanded ? (
            <div className="w-full flex items-center justify-between">
              <div className="flex space-x-3">
                <img
                  onClick={toggleExpand}
                  src={Logo}
                  alt="Logo"
                  className="rounded-md w-[40px] h-[40px]"
                />
                <div className="text-lg font-medium font-poppins text-[#f2f2f2] leading-[40px]">
                  {whiteLabelText}
                </div>
              </div>
              <div
                onClick={toggleExpand}
                className="flex items-center text-white cursor-pointer"
              >
                <ChevronLeftIcon
                  strokeWidth={3}
                  className="h-6 w-6 text-white/80"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-3">
              <div
                onClick={toggleExpand}
                className="flex items-center text-white cursor-pointer"
              >
                <MenuIcon className="h-[30px] w-[30px] text-white/80" />
              </div>
              <img
                src={Logo}
                alt="Logo"
                className="mx-auto rounded-md w-[36px] h-[36px]"
              />
            </div>
          )}
        </div>

        {renderSidebarNav()}
        {renderLogoutButton()}
        {renderUserProfile()}
      </aside>
      {renderLogoutModal()}
    </div>
  );
};

// Hover: Maps the authentication state to component props
const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

// Hover: Connects the component to the Redux store and exports it
export default connect(mapStateToProps)(AdminDashboardNav);
