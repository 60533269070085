// test environment server

const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_API_URL;
const serverUrl = process.env.REACT_APP_NODE_SERVER_API_URL;

const server = {
  ccxtServer: {
    baseUrl: ccxtUrl,
  },
  server: {
    baseUrl: serverUrl,
  },
  // server: {
  //   baseUrl: `http://localhost:8002/`,
  // },
};

export default server;
