import React from "react";
import Model1 from "./assests/Model_Portfolio/model1.png";
import Model2 from "./assests/Model_Portfolio/model2.png";
import Model3 from "./assests/Model_Portfolio/model3.png";
import Model4 from "./assests/Model_Portfolio/model4.png";
import Model5 from "./assests/Model_Portfolio/model5.png";
import Model6 from "./assests/Model_Portfolio/model6.png";
import "react-loading-skeleton/dist/skeleton.css";
import { Info } from "lucide-react";
import meter from "./assests/icons/meter.svg";

const Card = ({ image, modelName, cagr, returns, investment, fees, risk }) => (
  <div className="relative font-poppins w-[350px] h-[290px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_3px_4px_0px_rgba(0,0,0,0.06)] py-4">
    <div className="flex items-start gap-x-4 p-4">
      <div className="overflow-hidden rounded-lg">
        <img
          src={image}
          alt={modelName}
          className="lg:h-[55px] md:w-[90px] md:h-[55px] sm:w-[30px] sm:h-[30px] object-cover"
        />
      </div>
      <div>
        <h2 className="text-lg font-semibold text-[#000000]">{modelName}</h2>
        <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
          We're registered research analysts creating thematic & quantamental...{" "}
          <span className="text-[#55A7F1] cursor-pointer">Read More</span>
        </p>
      </div>
    </div>
    <div className="flex w-full mt-[4px] border-t-[1px] border-b-[1px] border-[#000000]/10">
      <div className="flex flex-col space-y-1 w-1/3 py-1.5 pl-5 lg:pl-5 border-r-[1px] border-[#000000]/10">
        <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
          CAGR
        </div>
        <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold pl-2">
          {cagr}
        </p>
      </div>
      <div className="flex flex-col space-y-1 w-1/3 py-1.5 lg:px-2 border-r-[1px] border-[#000000]/10">
        <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
          2Y Returns
        </div>
        <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold pl-2">
          {returns}
        </p>
      </div>
      <div className="flex flex-col space-y-1 w-1/3 py-1.5 lg:px-1 rounded-md">
        <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
          Min. Investment
        </div>
        <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold pl-2">
          {investment}
        </p>
      </div>
    </div>
    <div className="flex flex-col items-start justify-between px-5 mt-4">
      <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
        <div className="flex items-center">
          <Info size={13} />
          <p className="text-[12px] lg:text-[13px] px-1 lg:px-2 py-0 font-medium pl-2">
            Monthly Fees: {fees}
          </p>
        </div>
        <div className="flex items-center px-3 py-[1px] rounded-md">
          <img src={meter} className="w-[17.57px] h-[12px]" alt="Meter" />
          <span className="text-[12px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium pl-2">
            {risk}
          </span>
        </div>
      </div>
    </div>
    <div className="p-4">
      <div className="flex space-x-2 w-full">
        <button className="w-full h-10 py-2 px-3 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-sm md:text-sm lg:text-sm font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear">
          Performance
        </button>
        <button className="w-full h-10 py-2 px-3 rounded-md bg-black text-white text-sm md:text-sm lg:text-sm font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear">
          Invest Now
        </button>
      </div>
    </div>
  </div>
);

const PortfolioSection = () => {
  const cardData = [
    {
      image: Model1,
      modelName: "Alpha 100",
      cagr: "+75.2%",
      returns: "+775.2%",
      investment: "₹1,29,500",
      fees: "₹250",
      risk: "Highly Risky",
    },
    {
      image: Model2,
      modelName: "Alpha 100",
      cagr: "+60.5%",
      returns: "+670.3%",
      investment: "₹1,50,000",
      fees: "₹300",
      risk: "Moderate Risk",
    },
    {
      image: Model3,
      modelName: "Alpha 100",
      cagr: "+85.7%",
      returns: "+800.1%",
      investment: "₹1,10,000",
      fees: "₹200",
      risk: "Low Risk",
    },
    {
      image: Model4,
      modelName: "Alpha 100",
      cagr: "+45.3%",
      returns: "+560.2%",
      investment: "₹1,40,000",
      fees: "₹270",
      risk: "High Risk",
    },
    {
      image: Model5,
      modelName: "Alpha 100",
      cagr: "+55.6%",
      returns: "+720.0%",
      investment: "₹1,30,000",
      fees: "₹290",
      risk: "Moderate Risk",
    },
    {
      image: Model6,
      modelName: "Alpha 100",
      cagr: "+70.9%",
      returns: "+750.4%",
      investment: "₹1,20,000",
      fees: "₹260",
      risk: "Low Risk",
    },
  ];

  return (
    <div className="flex justify-center pt-[3rem]" id="modelPortfolio">
      <div className="max-w-screen-lg w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4  lg:gap-x-[88px] gap-y-6 justify-items-center">
          <div className="col-span-full text-center text-[28px] sm:text-3xl md:text-4xl  font-bold">
            Model Portfolios for Every Investor
          </div>
          {cardData.map((card, index) => (
            <Card
              key={index}
              image={card.image}
              modelName={card.modelName}
              cagr={card.cagr}
              returns={card.returns}
              investment={card.investment}
              fees={card.fees}
              risk={card.risk}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
