import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  CalendarDays,
  // CircleCheckBigIcon,
  // RefreshCcwIcon,
  Undo2Icon,
  XIcon,
} from "lucide-react";
import IsMarketHours from "../../utils/isMarketHours";
import toast from "react-hot-toast";
// import axios from "axios";
// import server from "../../utils/serverConfig";
// import { FaFire } from "react-icons/fa";

const IgnoreStockCard = ({
  id,
  tradeId,
  symbol,
  date,
  Quantity,
  action,
  orderType,
  exchange,
  advisedRangeLower,
  advisedRangeHigher,
  rationale,
  recommendationStock,
  setRecommendationStock,
  setStockDetails,
  stockDetails,
  setOpenReviewTrade,
  handleIgnoredTrades,
  ltp,
  setOpenTokenExpireModel,
  todayDate,
  expireTokenDate,
  broker,
  setOpenZerodhaModel,
  getLTPForSymbol,
}) => {
  const [ignoreTradesLoading, setIgnoreTradesLoading] = useState(false);

  const handleIncreaseStockQty = (symbol) => {
    const newData = recommendationStock.map((stock) =>
      stock.Symbol === symbol
        ? { ...stock, Quantity: stock.Quantity + 1 }
        : stock
    );
    setRecommendationStock(newData);
  };

  const handleDecreaseStockQty = (symbol) => {
    const newData = recommendationStock.map((stock) =>
      stock.Symbol === symbol
        ? { ...stock, Quantity: Math.max(stock.Quantity - 1, 0) }
        : stock
    );
    setRecommendationStock(newData);
  };

  const handleQuantityInputChange = (symbol, value) => {
    if (!value || value == "") {
      const newData = recommendationStock.map((stock) =>
        stock.Symbol === symbol ? { ...stock, Quantity: "" } : stock
      );
      setRecommendationStock(newData);
    } else {
      const newData = recommendationStock.map((stock) =>
        stock.Symbol === symbol
          ? { ...stock, Quantity: parseInt(value) }
          : stock
      );
      setRecommendationStock(newData);
    }
  };

  const handleSingleSelectStock = (symbol, tradeId) => {
    const isMarketHours = IsMarketHours();

    if (!isMarketHours) {
      toast.error("Orders cannot be placed outside Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    }

    if (broker === "Zerodha") {
      const isSelected = stockDetails.some(
        (selectedStock) => selectedStock.Symbol === symbol
      );
      if (isSelected) {
        setStockDetails(
          stockDetails.filter(
            (selectedStock) => selectedStock.Symbol !== symbol
          )
        );
      } else {
        const updatedStock = recommendationStock.find(
          (item) => item.Symbol === symbol
        );
        if (updatedStock) {
          const newStock = {
            user_email: updatedStock.user_email,
            trade_given_by: updatedStock.trade_given_by,
            tradingSymbol: updatedStock.Symbol,
            transactionType: updatedStock.Type,
            exchange: updatedStock.Exchange,
            segment: updatedStock.Segment,
            productType: updatedStock.ProductType,
            orderType: updatedStock.OrderType,
            price: updatedStock.Price,
            quantity: updatedStock.Quantity,
            priority: updatedStock.Priority,
            tradeId: updatedStock.tradeId,
            user_broker: broker,
          };
          setStockDetails([newStock]);
          setOpenZerodhaModel(true);
        }
      }
    } else {
      if (todayDate > expireTokenDate) {
        setOpenTokenExpireModel(true);
      } else {
        const isSelected = stockDetails.some(
          (selectedStock) => selectedStock.Symbol === symbol
        );
        if (isSelected) {
          setStockDetails(
            stockDetails.filter(
              (selectedStock) => selectedStock.Symbol !== symbol
            )
          );
        } else {
          const updatedStock = recommendationStock.find(
            (item) => item.Symbol === symbol
          );
          if (updatedStock) {
            const newStock = {
              user_email: updatedStock.user_email,
              trade_given_by: updatedStock.trade_given_by,
              tradingSymbol: updatedStock.Symbol,
              transactionType: updatedStock.Type,
              exchange: updatedStock.Exchange,
              segment: updatedStock.Segment,
              productType: updatedStock.ProductType,
              orderType: updatedStock.OrderType,
              price: updatedStock.Price,
              quantity: updatedStock.Quantity,
              priority: updatedStock.Priority,
              tradeId: updatedStock.tradeId,
            };
            setStockDetails([newStock]);
            setOpenReviewTrade(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    // Sync the stockDetails with the updated data
    const updatedSelectedStocks = stockDetails.map((selectedStock) => {
      const updatedStock = recommendationStock.find(
        (stock) =>
          stock.Symbol === selectedStock.tradingSymbol &&
          stock.tradeId === selectedStock.tradeId
      );
      return updatedStock
        ? { ...selectedStock, quantity: updatedStock.Quantity }
        : selectedStock;
    });
    setStockDetails(updatedSelectedStocks);
  }, [recommendationStock]);

  const [rationaleModal, setRationaleModal] = useState(false);

  const openRationaleModal = () => {
    setRationaleModal(true);
  };

  const closeRationaleModal = () => {
    setRationaleModal(false);
  };

  const advisedRangeCondition =
    (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
    (advisedRangeHigher === null && advisedRangeLower === null) ||
    (advisedRangeHigher > 0 &&
      advisedRangeLower > 0 &&
      parseFloat(advisedRangeHigher) >= parseFloat(ltp) &&
      parseFloat(ltp) >= parseFloat(advisedRangeLower)) ||
    (advisedRangeHigher > 0 &&
      advisedRangeLower === 0 &&
      advisedRangeLower === null &&
      parseFloat(advisedRangeHigher) >= parseFloat(ltp)) ||
    (advisedRangeLower > 0 &&
      advisedRangeHigher === 0 &&
      advisedRangeHigher === null &&
      parseFloat(advisedRangeLower) <= parseFloat(ltp));

  return (
    <div
      key={id}
      className={`relative font-poppins w-full rounded-xl bg-white shadow-[3px_6px_30px__0px_rgb(0,0,0,0.15)]  py-4   }`}
    >
      {rationaleModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
          <div className="relative">
            <div
              className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
              onClick={closeRationaleModal}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="w-[320px] px-4 py-8 md:w-[620px] md:px-10 md:py-10 bg-white  border-[#000000]/20 rounded-md">
              <h3 className=" text-[#000000] text-[18px] lg:text-[28px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                Rationale for {symbol}
              </h3>

              <p className=" mt-6 text-[14px] md:text-[16px] text-[#95989C] font-medium font-poppins text-left ">
                {rationale === "" ? (
                  <>
                    This recommendation is based on a comprehensive analysis of
                    the company's growth potential and value metrics. This
                    recommendation also accounts for potential future risks,
                    ensuring a balanced approach to maximizing returns while
                    mitigating uncertainties. Please contact your advisor for
                    any queries.
                  </>
                ) : (
                  rationale
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className={`flex flex-col  items-start justify-between  px-5`}>
        <div
          className={`${
            !advisedRangeCondition ? "opacity-60" : "opacity-100"
          } w-full group relative flex items-start justify-between text-base text-[#000000]/80  font-poppins font-semibold`}
        >
          <div className="flex">
            <p className=" truncate max-w-[190px]"> {symbol} </p>

            <span className="ml-[2px] pt-[3px] text-[10px] text-[#000000]/80 font-normal">
              {exchange}
            </span>

            {symbol.length >= 18 && (
              <div className="hidden group-hover:flex absolute max-w-[250px] top-[24px] text-[13px] left-0 rounded-[4px]  text-center px-3 pt-1 bg-[#ffffff] border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)] text-[#000000]/80 ">
                <p className="max-w-[250px] text-[#000000]/80"> {symbol} </p>
                <span className="ml-[2px] pt-[3px] text-[8px] text-[#000000]/80 font-normal">
                  {exchange}
                </span>
                <div className="absolute -top-1 left-[20px] transform -translate-x-1/2 w-2 h-2 bg-[#ffffff] border-t-[1px] border-l-[1px] border-[#000000]/10 rotate-45"></div>
              </div>
            )}
          </div>

          <div
            className={`${
              action?.toLowerCase() === "buy"
                ? "bg-[#16A085]/10 text-[#16A085] "
                : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
            } flex items-center px-3 py-[1px] rounded-md
            ${!advisedRangeCondition ? "opacity-60" : "opacity-100"} 
            `}
          >
            <span className=" text-[14px] lg:text-[14px] px-2 lg:px-2 py-0  font-poppins font-semibold">
              {action}
            </span>
          </div>
        </div>

        <div className="w-full flex  items-center justify-between">
          <span
            className={`
              ${
                !advisedRangeCondition ? "opacity-60" : "opacity-100"
              } flex text-sm text-[#000000]/80  font-poppins font-semibold   items-center`}
          >
            {" "}
            {symbol ? `₹ ${getLTPForSymbol(symbol)}` : "-"}
            {/*{symbol ? `₹getLTPForSymbol{symbol}` : "-"}*/}
          </span>

          <div>
            {!advisedRangeCondition ? (
              <div className="opacity-100 flex flex-row justify-end mt-[0px] text-[9px] text-[#3D7DE4] font-semibold font-poppins leading-[18px]">
                *Price is out of advised range
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className={`${
          !advisedRangeCondition ? "opacity-60" : "opacity-100"
        } flex items-start mt-[4px] h-[28px]`}
      >
        <p className="inline-block text-[10px] text-[#95989C] font-normal  px-5">
          <span className=" text-[10px] text-[#000000]/70 font-medium font-poppins">
            Rationale :{" "}
          </span>
          {rationale.length < 100 ? (
            rationale
          ) : (
            <>
              {rationale?.slice(0, 65)}...
              <span
                onClick={openRationaleModal}
                className="cursor-pointer text-[10px] text-[#55A7F1] flex-shrink-0 ml-1"
              >
                View More
              </span>
            </>
          )}
        </p>
      </div>

      <div
        className={`${
          !advisedRangeCondition ? "opacity-60" : "opacity-100"
        } flex w-full   mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10 `}
      >
        <div className="flex flex-col space-y-1 w-1/3  py-1.5 pl-5 lg:pl-5  border-r-[1px] border-[#000000]/10">
          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins">
            Order Type
          </div>
          <div className="text-[13px] font-poppins font-medium text-[#000000] capitalize ">
            {orderType?.toLowerCase()} Order
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-1/3 items-center  py-1.5 px-2  border-r-[1px] border-[#000000]/10">
          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins text-center">
            Quantity
          </div>

          <div className="flex  flex-row items-center justify-between  text-[12px] text-[#000000]/80 font-poppins font-semibold">
            <button
              onClick={() => handleDecreaseStockQty(symbol)}
              disabled={Quantity <= 1}
              className=" cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
            >
              -
            </button>

            <input
              type="text"
              value={Quantity}
              onChange={(e) =>
                handleQuantityInputChange(symbol, e.target.value)
              }
              className="flex flex-1 items-center justify-center  w-[45px] h-[20px] font-poppins text-[12px] mx-1 text-center border border-gray-300 rounded"
            />
            <button
              onClick={() => handleIncreaseStockQty(symbol)}
              className=" cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
            >
              +
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-1/3 items-center   py-1.5 lg:px-1   rounded-md">
          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins">
            Advised Range
          </div>
          <div className="text-[11px] text-[#000000] font-poppins font-semibold">
            {advisedRangeLower && advisedRangeHigher ? (
              <span>
                ₹{advisedRangeLower}- ₹{advisedRangeHigher}
              </span>
            ) : advisedRangeLower ? (
              <span> ₹{advisedRangeLower}</span>
            ) : advisedRangeHigher ? (
              <span>₹{advisedRangeHigher}</span>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>

      <div
        className={`${
          !advisedRangeCondition ? "opacity-60" : "opacity-100"
        } flex flex-row items-center justify-between mt-3  pb-1 px-5`}
      >
        <div className="text-[#000000]80 text-xs  font-medium flex flex-row items-center">
          <CalendarDays size={16} className="" />
          <span className="ml-2">{moment(date).format("Do MMM YYYY")}</span>
          <span className="mx-2">|</span>{" "}
          <span>{moment(date).format("HH:mm A")}</span>{" "}
          {/* Use HH:mm for 24-hour format */}
        </div>
      </div>

      <div
        className={`${
          !advisedRangeCondition ? "opacity-60" : "opacity-100"
        } flex mt-[6px] px-5`}
      >
        <div className="flex space-x-2 w-full">
          <button
            className="w-full  flex items-center  justify-center   py-2 px-2 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-[13px] lg:text-[14px] font-poppins font-medium  hover:scale-[1.03] transition-all duration-150 ease-linear"
            onClick={() => handleIgnoredTrades(id, setIgnoreTradesLoading)}
          >
            {ignoreTradesLoading === true ? (
              <span className="flex flex-row justify-center ml-3 ">
                <svg
                  className="h-4  w-4 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : (
              <span className="flex items-center  justify-center">
                <Undo2Icon
                  strokeWidth={3}
                  className="text-[#000000]/50 w-4 h-4 mr-1"
                />
                Revert to Home
              </span>
            )}
          </button>

          {!advisedRangeCondition ? (
            <button className=" cursor-not-allowed w-full  py-2 px-2 rounded-md bg-black text-white text-[13px] lg:text-[14px]  font-poppins font-medium  hover:scale-[1.03] transition-all duration-150 ease-linear">
              Trade Now
            </button>
          ) : (
            <button
              className="w-full  py-2 px-2 rounded-md bg-black text-white text-[14px] lg:text-[12px]  font-poppins font-medium  hover:scale-[1.03] transition-all duration-150 ease-linear"
              onClick={() => handleSingleSelectStock(symbol, tradeId)}
            >
              Trade Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default IgnoreStockCard;
