import React, { useEffect, useState } from 'react'
import YouTube from "react-youtube";

import {
  X,
  AlertTriangle,
  Info,
  ChevronLeft,
  ClipboardList,
  ArrowLeft
} from 'lucide-react'
import DDPI from "../../assests/DDPI.png"

import Checked from "../../assests/checked.svg"
// import DDPI from "../../assests/DDPI.png"
import Ddpi from '../../assests/DDPI.svg'
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function DdpiModal({ isOpen = false, setIsOpen = () => { },

  userDetails }


) {
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;

  // console.log("userdetails",userDetails);



  const proceedWithTpin = async () => {
    try {
      const response = await fetch(`https://ccxtprod.alphaquark.in/zerodha/auth-sell`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          apiKey: zerodhaApiKey,
          accessToken: userDetails?.jwtToken
        }),
      });

      if (!response.ok) {
        throw new Error('Response was not ok');
      }

      const data = await response.json();
      console.log('API Response:', data);

      if (data.status === 0) {
        // window.location.href = data.auth_url;
        // window.open(data.auth_url, '_blank');
        // Calculate center position for the popup
        // Calculate center position for the popup
        const popupWidth = 800; // Adjust the width as necessary
        const popupHeight = 600; // Adjust the height for a smaller popup

        const left = (window.innerWidth - popupWidth) / 2 + window.screenX;
        const top = (window.innerHeight - popupHeight) / 2 + window.screenY + 30;

        // Open in a centered pop-up window
        const popup = window.open(
          data.auth_url,
          'AuthorizationPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        setIsOpen(false); // Close the DDPI modal

        const popupChecker = setInterval(() => {
          if (popup && popup.closed) {
            clearInterval(popupChecker); // Stop checking once the popup is closed
            window.location.reload();
          }
        }, 300);

      } else {
        console.error('Error in response:', data.message);
        alert(data.message || ' error occurred.');
      }
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };




  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none" showCloseButton={false}>
        <div className="fixed inset-0  flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-[740px] max-h-[90vh] overflow-y-auto sm:h-[323px] relative border-t border-l">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
            <div className="flex flex-col sm:flex-row h-full items-center">
              <div className="w-full sm:w-[440px] p-6 sm:pl-10 flex flex-col justify-between">
                <div>
                  <div className="flex items-start gap-4 mb-4">
                    <AlertTriangle className="text-red-500 shrink-0 mt-1" />
                    <div>
                      <h2 className="text-xl font-semibold font-poppins text-[#000000B3]">DDPI Inactive: Proceed with TPIN Mandate</h2>
                    </div>
                  </div>
                  <ul className="space-y-3 text-sm text-gray-600 mb-6 pl-10 font-poppins">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      Use TPIN for a temporary authorization to sell selected stocks while DDPI is inactive
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      This secure, one-time mandate allows smooth transactions until DDPI is active
                    </li>
                  </ul>
                </div>
                {/* <div className="flex flex-row sm:gap-0 gap-4"> */}

                <button
                  onClick={proceedWithTpin}
                  className="w-full sm:w-[240px] h-[45px] bg-red-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-red-600 transition-colors sm:ml-4 mt-4 sm:mt-0"
                >
                  Proceed with Authorization to Sell
                </button>

                {/* <button
    className="w-full sm:w-[240px] h-[45px] bg-gray-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-gray-600 transition-colors sm:ml-4 mt-4 sm:mt-0"
    onClick={() => setIsOpen(false)}
>
 Not Approved
</button> */}
                {/* </div> */}


              </div>
              <div className="w-full sm:w-[240px] h-[224px] flex items-center justify-center mt-4 sm:mt-0">
                <img
                  src={Ddpi}
                  alt="DDPI illustration"
                  width={2800}
                  height={600}
                  className="w-full h-full object-contain rounded-tl-[10px]"
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}


export function ActivateNowModel({ isOpen = false, setIsOpen = () => { }, onActivate = () => { } }) {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none">
        <div className="fixed inset-0  bg-black bg-opacity-50 flex items-center justify-center z-50 p-2 sm:p-4">
          <div className="w-full max-w-[1016px] sm:h-[450px] sm:ml-[300px] bg-white rounded-lg overflow-hidden relative">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-black opacity-30 hover:opacity-100 transition-opacity"
            >
              <X size={18} />
            </button>
            <div className="max-w-[916px] mx-auto my-2 sm:my-4 md:my-2 flex flex-col lg:flex-row">
              <div className="w-full lg:w-1/2 flex justify-center items-center mb-3 sm:mb-6 lg:mb-0 p-2 sm:p-4">
                <div className="aspect-square w-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] relative">
                  <img
                    src={Ddpi}
                    alt="DDPI Illustration"
                    className="rounded-lg absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="flex-1 px-3 sm:px-4 md:px-6 lg:pl-8 pt-0 lg:pt-4">
                <h2 className="font-poppins text-base sm:text-lg md:text-xl lg:text-2xl font-semibold leading-tight mb-2 sm:mb-4 md:mb-6 text-center lg:text-left">
                  Save Time and Effort by <br />Enabling DDPI!
                </h2>
                <ul className="space-y-2 sm:space-y-3 md:space-y-4">
                  <li className="flex items-start">
                    <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                    <p className="font-poppins text-xs font-normal sm:text-sm  leading-4 sm:leading-5 md:leading-6">
                      <span className="font-semibold">Instant Selling:</span> Sell your holdings instantly after DDPI activation without needing a T-PIN or OTP.
                    </p>
                  </li>
                  <li className="flex items-start">
                    <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                    <p className="font-poppins text-xs sm:text-sm font-normal  leading-4 sm:leading-5 md:leading-6">
                      <span className="font-semibold">Seamless Liquidation:</span> Liquidate your holdings without the hassle of daily pre-authorization for each sell order.
                    </p>
                  </li>
                  <li className="flex items-start">
                    <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                    <p className="font-poppins text-xs sm:text-sm font-normal leading-4 sm:leading-5 md:leading-6">
                      <span className="font-semibold">Faster Transactions: </span>Enjoy smoother and quicker trading experiences with fewer barriers.
                    </p>
                  </li>
                </ul>
                <div className="flex justify-center lg:justify-start mt-3 sm:mt-4 md:mt-10">
                  <button
                    onClick={onActivate}
                    className="w-full max-w-[200px] sm:max-w-[250px] h-[40px] sm:h-[46px] rounded-lg text-white font-poppins font-semibold text-xs sm:text-sm bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 transition-colors duration-300"
                  >
                    Activate DDPI Now &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </DialogContent>
    </Dialog>
  )
}







export function ActivateTopModel( userDetails ) {
  const [showModal, setShowModal] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  
  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };
  
 

  const brokerInstructions = {
    "Dhan": {
      title: 'Dhan Broker: How to Authorize Stocks for Selling',
      directLink: 'https://knowledge.dhan.co/support/solutions/articles/82000900258-from-where-ddpi-service-can-be-activated-',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your Dhan account.',
        'Navigate to the DDPI activation section.',
        'Follow the on-screen instructions to complete the DDPI activation process.',
      ],
    },
  
    // "AliceBlue": {
    //   title: 'Aliceblue Broker: How to Authorize Stocks for Selling',
    //   videoId: 'https://youtu.be/ncFGDQAARhM',
    //   steps: [
    //     '1. Log in to your Aliceblue account. ',
    //     '2. Navigate to Portfolio > Holdings, and click the Authorize button located below the Portfolio Value.',
    //     '3. In the CDSL interface, select the stocks to authorize, click Authorize, and proceed to CDSL.',
    //     '4. Enter your TPIN and OTP for verification. If required, generate a TPIN before proceeding. ',
    //     '5. Upon successful authorization, you will be redirected to the Portfolio screen.',
    //     '6. Go back to the our platform and attempt to sell your stocks again',
        
    //   ],
    // },
  
    "Zerodha": {
      title: 'Zerodha: How to Authorize Stocks for Selling',
      directLink: 'https://support.zerodha.com/category/account-opening/online-account-opening/other-online-account-opening-related-queries/articles/activate-ddpi',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your Zerodha account.',
        'Navigate to the Profile or Settings section.',
        'Find the DDPI activation option and follow the prompts.',
      ],
    },
  
    "Angel One": {
      title: 'Angel One: How to Authorize Stocks for Selling',
      directLink: 'https://www.angelone.in/knowledge-center/demat-account/how-to-set-up-ddpi-on-angel-on',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your AngelOne account.',
        'Access the Profile section.',
        'Find the DDPI option and complete the activation steps.',
      ],
    },
  };
  const handleActivateClick = () => {
    if (instructions.directLink) {
      window.open(instructions.directLink, '_blank', 'noopener,noreferrer');
    } else {
      setShowModal(true);
    }
  };
  
  const broker = userDetails?.userDetails?.user_broker
// console.log("userdetailBroker",broker)

  // console.log("broker",broker)
  const instructions = brokerInstructions[broker] || {}

  return (
    <>
       <div className="flex items-center gap-2 w-auto sm:w-[680px] h-[120px] sm:h-[40px] bg-[#FFC90733] rounded-br-[20px] px-4">
        <Info className="w-22px sm:w-[14px] text-black" />
        <p className="font-poppins text-xs sm:text-[13px] font-semibold py-2 sm:py-4">
          Enable DDPI for faster trades and seamless transactions.
        </p>
        <button
          className="w-[250px] h-[26px] sm:w-[135px] sm:h-[26px] rounded-md sm:ml-14 text-white font-poppins font-semibold text-[11px] sm:text-xs border-[1px] border-[#A4751F] bg-gradient-to-r from-[#A4751F] via-yellow-500 to-[#BC9038] hover:from-yellow-500 hover:to-yellow-700 transition-colors duration-300 flex items-center justify-center"
          // onClick={() => setShowModal(true)}
          onClick={handleActivateClick}

        >
          <span className="mr-1">Activate DDPI &gt;&gt; </span>
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 overflow-auto p-4">
          <Toaster position="top-center" reverseOrder={true} />

          <div className="w-full max-w-[580px] bg-white shadow-lg">
            <div className="p-4 pb-6 relative font-poppins">
              <div className="mb-8">
                <button
                  onClick={() => setShowModal(false)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <div >
                {instructions.videoId && (
                  <div className="w-full aspect-video">
                    <YouTube
                      className={`videoIframe `}
                        videoId={instructions.videoId}
                       title="YouTube video player"
                      
                    ></YouTube>
                  </div>
                )}
    
                <div className="space-y-1.5">
                  <h2 className="text-base font-semibold font-poppins">{instructions.title}</h2>
    
                  <ol className="space-y-1.5 text-sm pb-4">
                    {instructions.steps && instructions.steps.map((step, index) => (
                      <li key={index} className="flex flex-col gap-1">
                        <div className="flex items-start gap-1">
                          <span className="font-semibold font-poppins">{index + 1}.</span>
                          <div className="flex flex-col space-y-1">
                            <div
                              className="flex items-center space-x-2 flex-wrap"
                              data-tooltip-id="copy-tooltip"
                              data-tooltip-content="COPY"
                            >
                              <span className="break-all">{step}</span>
                              {step.includes('http') && (
                                <button
                                  onClick={() => handleCopy(step.match(/https?:\/\/[^\s]+/)[0])}
                                  aria-label="Copy link"
                                >
                                  <ClipboardList className="w-4 h-4 cursor-pointer text-gray-300 hover:text-gray-600 transition-colors ml-2 flex-shrink-0" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <Tooltip id="copy-tooltip" />
        </div>
      )}
    </>
  )
}





export function AngleOneTpinModal({ isOpen, setIsOpen, userDetails, edisStatus, tradingSymbol }) {
  const [loading, setLoading] = useState(false)

//   const proceedWithTpin = async () => {
//     // console.log("userDetails of ddpi page",userDetails);
// // console.log("edisStatus", edisStatus);
//     setLoading(true)
//     try {
//       const response = await fetch('https://apiconnect.angelone.in/rest/secure/angelbroking/edis/v1/generateTPIN', {

//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//          'PrivateKey': 'api_key', 
//          'SourceID': 'WEB', 
//          'ClientLocalIP': '172.29.24.173', 
//          'ClientPublicIP': '172.29.12.16', 
//          'MACaddress': 'e0:d5:5e:91:23:d4', 
//          'UserType':' USER', 
//          'FORWARDED-FOR': '172.29.12.16', 
//          'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9....', 
//         },
//         body: JSON.stringify({
          
//           DPId: edisStatus?.data?.DPId ,
//           ReqId: edisStatus?.data?.ReqId ,
//           BOID: edisStatus?.data?.BOID,
//           pan: 'EYTPM0748E'
//         })
//       }); 

//       const data = await response.json();

//       if (data.status && data.data) {
//         const { dpId, reqId, transDetails } = data.data;

//         // Create and submit the form
//         const form = document.createElement('form');
//         form.method = 'POST';
//         form.action = 'https://edis.cdslindia.com/eDIS/VerifyDIS/';
//         form.style.display = 'none';

//         const fields = { DPId: dpId, ReqId: reqId, Version: '1.1', TransDtls: transDetails };

//         Object.entries(fields).forEach(([key, value]) => {
//           const input = document.createElement('input');
//           input.type = 'hidden';
//           input.name = key;
//           input.value = value;
//           form.appendChild(input);
//         });

//         document.body.appendChild(form);
//         form.submit();
//       } else if (data.errorcode === 'AG1000') {
//         toast.info('You have already been registered with CDSL for today.');
//       } else {
//         throw new Error(data.message || 'Failed to generate TPIN');
//       }
//     } catch (error) {
//       console.error('Error in API call:', error);
//       toast.error(error.message || 'An error occurred while generating TPIN');
//     } finally {
//       setLoading(false);
//     }
//   };
const proceedWithTpin = async () => {
    const formHtml = `
      <!DOCTYPE html>
      <html>
      <script>window.onload = function() { document.getElementById("submitBtn").click(); }</script>
      <body>
        <form 
          name="frmDIS" 
          method="post"
          action="https://edis.cdslindia.com/eDIS/VerifyDIS/"
          style="display:none;"
        >
          <input type="hidden" name="DPId" value="${
            edisStatus?.data?.DPId || ""
          }" />
          <input type="hidden" name="ReqId" value="${
            edisStatus?.data?.ReqId || ""
          }" />
          <input type="hidden" name="Version" value="1.1" />
          <input type="hidden" name="TransDtls" value="${
            edisStatus?.data?.TransDtls || ""
          }" />
          <input type="hidden" name="returnURL" value="https://test.alphaquark.in/stock-recommendation" />
          <input id="submitBtn" type="submit" />
        </form>
      </body>
      </html>
    `;

    // Create new window and submit form
  //   const newWindow = window.open("", "_blank");
  //   if (newWindow) {
  //     newWindow.document.write(formHtml);
  //     newWindow.document.close();
  //   }
  // };
// Open a new popup window
const popupWidth = 800; // Adjust the width as necessary
const popupHeight = 600; // Adjust the height for a smaller popup
const left = (window.innerWidth - popupWidth) / 2 + window.screenX;
const top = (window.innerHeight - popupHeight) / 2 + window.screenY + 30;

 // Open a new window for the form submission
 const popup = window.open(
  "", 
  "_blank", 
  `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`
);

if (popup) {
  popup.document.write(formHtml);  // Write the form HTML to the new window
  popup.document.close();  // Close the document stream to complete rendering
  
  setIsOpen(false); // Close the DDPI modal

  // Popup checker to reload the main window when popup is closed
  const popupChecker = setInterval(() => {
    if (popup && popup.closed) {
      clearInterval(popupChecker); // Stop checking once the popup is closed
      window.location.reload(); // Reload the main window
    }
  }, 300);
}
};
  


  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none" showCloseButton={false}>

        <div className="fixed inset-0  flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-[740px] max-h-[90vh] overflow-y-auto sm:h-[323px] relative border-t border-l">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
            <div className="flex flex-col sm:flex-row h-full items-center">
              <div className="w-full sm:w-[440px] p-6 sm:pl-10 flex flex-col justify-between">
                <div>
                  <div className="flex items-start gap-4 mb-4">
                    <AlertTriangle className="text-red-500 shrink-0 mt-1" />
                    <div>
                      <h2 className="text-xl font-semibold font-poppins text-[#000000B3]">DDPI Inactive: Proceed with TPIN Mandate</h2>
                    </div>
                  </div>
                  <ul className="space-y-3 text-sm text-gray-600 mb-6 pl-10 font-poppins">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      Use TPIN for a temporary authorization to sell selected stocks while DDPI is inactive
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      This secure, one-time mandate allows smooth transactions until DDPI is active
                    </li>
                  </ul>
                </div>
                {/* <div className="flex flex-row sm:gap-0 gap-4"> */}

                <button
                  onClick={proceedWithTpin}
                  className="w-full sm:w-[240px] h-[45px] bg-red-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-red-600 transition-colors sm:ml-4 mt-4 sm:mt-0"
                >
                  Proceed with Authorization to Sell
                </button>


              </div>
              <div className="w-full sm:w-[240px] h-[224px] flex items-center justify-center mt-4 sm:mt-0">
                <img
                  src={Ddpi}
                  alt="DDPI illustration"
                  width={2800}
                  height={600}
                  className="w-full h-full object-contain rounded-tl-[10px]"
                />
              </div>
            </div>
          </div>
        </div>
         

      </DialogContent>
    </Dialog>
  )
}







export function DhanTpinModal({ isOpen, setIsOpen, userDetails, dhanEdisStatus,stockTypeAndSymbol,singleStockTypeAndSymbol }) {
  const [loading, setLoading] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for controlling popup visibility
  const [tpin, setTpin] = useState(''); // State for capturing the TPIN entered by the user
  const [matchedData, setMatchedData] = useState(null);
  const [matchedIsin, setMatchedIsin] = useState(null);
  const [showNoHoldingModal, setShowNoHoldingModal] = useState(false)

  useEffect(() => {
    const shouldOpenPopup = localStorage.getItem('openDhanPopup')
    if (shouldOpenPopup === 'true') {
      setIsPopupOpen(true)
      localStorage.removeItem('openDhanPopup')
    }
  }, [])

// console.log("stockTypeAndSymbol",stockTypeAndSymbol)

console.log("dhanEdisStatus",dhanEdisStatus)







useEffect(() => {
//   if (stockTypeAndSymbol && dhanEdisStatus && dhanEdisStatus.data) {
//     // Find the first SELL order
//     const sellOrder = stockTypeAndSymbol.find(stock => stock.Type === "SELL");
    
//     if (sellOrder) {
//       // Find matching order in dhanEdisStatus
//       const matchedOrder = dhanEdisStatus.data.find(order => 
//         order.symbol === sellOrder.Symbol && order.exchange === sellOrder.Exchange
//       );

//       if (matchedOrder) {
//         setMatchedData({
//           isin: matchedOrder.isin,
//           symbol: matchedOrder.symbol,
//           exchange: matchedOrder.exchange
//         });

//         setMatchedIsin(matchedOrder.isin);

//         console.log("Matched Data:", {
//           isin: matchedOrder.isin,
//           symbol: matchedOrder.symbol,
//           exchange: matchedOrder.exchange
//         });
      
//       } else {
//         console.log("No matching order found in dhanEdisStatus");
//         setShowNoHoldingModal(true);

//       }
//     } else {
//       console.log("No SELL order found in stockTypeAndSymbol");
//       setShowNoHoldingModal(true);

//     }
//   }
// }, [stockTypeAndSymbol, dhanEdisStatus]);
// if (stockTypeAndSymbol && dhanEdisStatus && dhanEdisStatus.data) {
//   // Handle array of stocks
//   const sellOrder = stockTypeAndSymbol.find(stock => stock.Type === "SELL");
  
//   if (sellOrder) {
//     const matchedOrder = dhanEdisStatus.data.find(order => 
//       order.symbol === sellOrder.Symbol && order.exchange === sellOrder.Exchange
//     );

//     if (matchedOrder) {
//       setMatchedData({
//         isin: matchedOrder.isin,
//         symbol: matchedOrder.symbol,
//         exchange: matchedOrder.exchange
//       });
//       setMatchedIsin(matchedOrder.isin);
//     } else {
//       setShowNoHoldingModal(true);
//     }
//   }
// } else if (singleStockTypeAndSymbol ) {
//   // Handle single stock
//   if (singleStockTypeAndSymbol.type === "SELL" && dhanEdisStatus && dhanEdisStatus.data) {
//     const matchedOrder = dhanEdisStatus.data.find(order => 
//       order.symbol === singleStockTypeAndSymbol.symbol
//     );

//     if (matchedOrder) {
//       setMatchedData({
//         isin: matchedOrder.isin,
//         symbol: matchedOrder.symbol,
//         exchange: matchedOrder.exchange
//       });
//       setMatchedIsin(matchedOrder.isin);
//     } else {
//       setShowNoHoldingModal(true);
//     }
//   }
// }
// }, [stockTypeAndSymbol, singleStockTypeAndSymbol, dhanEdisStatus]);
if (dhanEdisStatus && dhanEdisStatus.data) {
  let stockToMatch = null;

  if (Array.isArray(stockTypeAndSymbol) && stockTypeAndSymbol.length > 0) {
    console.log("Handling array of stocks");
    stockToMatch = stockTypeAndSymbol.find(stock => stock.Type === "SELL");
  } else if (singleStockTypeAndSymbol && singleStockTypeAndSymbol.type === "SELL") {
    console.log("Handling single stock");
    stockToMatch = {
      Symbol: singleStockTypeAndSymbol.symbol,
      Exchange: singleStockTypeAndSymbol.exchange || 'NSE' // Assuming NSE if not provided
    };
  }

  console.log("Stock to match:", stockToMatch);

  if (stockToMatch) {
    const matchedOrder = dhanEdisStatus.data.find(order => 
      order.symbol === stockToMatch.Symbol && 
      (order.exchange === stockToMatch.Exchange || !stockToMatch.Exchange)
    );

    console.log("Matched order:", matchedOrder);

    if (matchedOrder) {
      setMatchedData({
        isin: matchedOrder.isin,
        symbol: matchedOrder.symbol,
        exchange: matchedOrder.exchange
      });
      setMatchedIsin(matchedOrder.isin);
    } else {
      console.log("No matching order found");
      setShowNoHoldingModal(true);
    }
  } else {
    console.log("No SELL order found");
    setShowNoHoldingModal(true);
  }
} else {
  console.log("dhanEdisStatus or its data is not available");
}
}, [stockTypeAndSymbol, singleStockTypeAndSymbol, dhanEdisStatus]);

  // const proceedWithDhanTpin = async () => {
  //   console.log("userDetails of ddpi page", userDetails);
  // console.log("Matched Data before API call:", matchedData);
  // console.log("Matched ISIN before API call:", matchedIsin);

  //   setLoading(true);
  //   if (dhanEdisStatus && (!dhanEdisStatus.data || dhanEdisStatus.data.length === 0)) {
  //     setShowNoHoldingModal(true);
  //     return;
  //   }

  //   try {
  //     const broker = userDetails.user_broker;

      
  //     // Logic for "Dhan"

  //    if (broker === "Dhan") {
  //       try {
  //         // Hit the first API to generate the TPIN
  //         const response = await fetch('https://ccxtprod.alphaquark.in/dhan/generate-tpin', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             clientId: userDetails?.clientCode,
  //             accessToken: userDetails?.jwtToken
  //           }),
  //         });

  //         const data = await response.json();

  //         if (data.status === 0) {
  //           toast.success("TPIN generated successfully for Dhan.");
  //           setIsPopupOpen(true);
  //           // // toast.success("TPIN generated successfully for Dhan.");
  //           // localStorage.setItem('openDhanPopup', 'true');
  //           // window.location.reload();
     
  //         } else {
  //           throw new Error(data.message || 'Failed to generate TPIN for Dhan');
  //         }
  //         // localStorage.setItem('openDhanPopup', 'true');
  //         // window.location.reload();
  //                 } catch (error) {
  //         console.error('Error in API call:', error);
  //         toast.error(error.message || 'An error occurred while generating or entering TPIN');
  //       }
  //     }

  //   } catch (error) {
  //     console.error('Error in API call:', error);
  //     toast.error(error.message || 'An error occurred while generating TPIN');
  //   } finally {
  //     setLoading(false);
  //   }
  // };


 
  // const handleDhanTpinSubmit = async () => {
  //   try {
  //     if (!tpin) {
  //       toast.error("Please enter the TPIN.");
  //       return;
  //     }
  
  //     if (userDetails?.user_broker === "Dhan") {
  //       // Call the enter-tpin API for Dhan
  //       const response = await fetch('https://ccxtprod.alphaquark.in/dhan/enter-tpin', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           clientId: userDetails?.clientCode,
  //           accessToken: userDetails?.jwtToken,
  //           isin: matchedData.isin,
  //           symbol: matchedData.symbol,
  //           exchange: matchedData.exchange          
  //         }),
  //       });
  


  //       const data = await response.json();

  //       if (data.status === 0) {
      
  //         toast.success(data.message || "Operation successful.");
          
  //         setIsPopupOpen(false);
  //         setIsOpen(false); 

  //         // localStorage.setItem('openDhanPopup', 'true');
        
  //         // window.location.reload();
  //       } else {
  //         // Handle failure case if status is not 0
  //         // Show the message received in the response as an error toast
  //         toast.error(data.message || 'Failed to enter TPIN');

  //       }
  //     }
  //     setIsPopupOpen(false);
  //   } catch (error) {
      
  //     console.error('Error in entering TPIN:', error);
  //     toast.error(error.message || 'An error occurred while entering TPIN');
  //     setIsPopupOpen(false);
  //  setIsOpen(false)
  //   }
  // };

 
  const proceedWithDhanTpin = async () => {
    console.log("userDetails of ddpi page", userDetails);
    console.log("Matched Data before API call:", matchedData);
    console.log("Matched ISIN before API call:", matchedData.isin);

    setLoading(true);
    const dhanEdisStatus = userDetails.dhanEdisStatus;
    if (dhanEdisStatus && (!dhanEdisStatus.data || dhanEdisStatus.data.length === 0)) {
      setShowNoHoldingModal(true);
      setLoading(false);
      return;
    }

    try {
      const broker = userDetails.user_broker;

      if (broker === "Dhan") {
        // Generate TPIN
        const generateTpinResponse = await fetch('https://ccxtprod.alphaquark.in/dhan/generate-tpin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientId: userDetails?.clientCode,
            accessToken: userDetails?.jwtToken
          }),
        });

        const generateTpinData = await generateTpinResponse.json();

        if (generateTpinData.status === 0) {
          toast.success("TPIN generated successfully for Dhan.");

          // Immediately call enter-tpin API
          const enterTpinResponse = await fetch('https://ccxtprod.alphaquark.in/dhan/enter-tpin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              clientId: userDetails?.clientCode,
              accessToken: userDetails?.jwtToken,
              isin: matchedData.isin,
              symbol: matchedData.symbol,
              exchange: matchedData.exchange          
            }),
          });

          const enterTpinData = await enterTpinResponse.json();

          if (enterTpinData.status === 0) {
            toast.success(enterTpinData.message || "Operation successful.");
          
            if (enterTpinData?.data?.edisFormHtml) {
              const popupWidth = 800;
              const popupHeight = 600;
              const left = (window.innerWidth - popupWidth) / 2 + window.screenX;
              const top = (window.innerHeight - popupHeight) / 2 + window.screenY + 0;

              const popup = window.open(
                "", 
                "_blank", 
                `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`
              );

              if (popup) {
                popup.document.write(enterTpinData.data.edisFormHtml);
                popup.document.close();
                
                setIsOpen(false);

                const popupChecker = setInterval(() => {
                  if (popup && popup.closed) {
                    clearInterval(popupChecker);
                    window.location.reload();
                  }
                }, 300);
              } else {
                toast.error('Please allow popups for this site to complete the EDIS process.');
              }
            } else {
              toast.error('EDIS form data not received.');
            }
          } else {
            throw new Error(enterTpinData.message || 'Failed to enter TPIN');
          }
        } else {
          throw new Error(generateTpinData.message || 'Failed to generate TPIN for Dhan');
        }
      }
    } catch (error) {
      console.error('Error in API call:', error);
      toast.error(error.message || 'An error occurred during the process');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setTpin('');
    setIsPopupOpen(false); 
    setIsOpen(false); 
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none" showCloseButton={false}>
      {showNoHoldingModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[700px]">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold">No Holdings</h3>
                <button onClick={() => setIsOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X className="w-6 h-6" />
                </button>
              </div>
              <p className=" font-bold text-red-600">  Unable to place orders. Each order must have sufficient holdings to proceed.
              </p>
              </div>
          </div>
        )}

      {!isPopupOpen && !showNoHoldingModal ?(

        <div className="fixed inset-0  flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-[740px] max-h-[90vh] overflow-y-auto sm:h-[323px] relative border-t border-l">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
            <div className="flex flex-col sm:flex-row h-full items-center">
              <div className="w-full sm:w-[440px] p-6 sm:pl-10 flex flex-col justify-between">
                <div>
                  <div className="flex items-start gap-4 mb-4">
                    <AlertTriangle className="text-red-500 shrink-0 mt-1" />
                    <div>
                      <h2 className="text-xl font-semibold font-poppins text-[#000000B3]">DDPI Inactive: Proceed with TPIN Mandate</h2>
                    </div>
                  </div>
                  <ul className="space-y-3 text-sm text-gray-600 mb-6 pl-10 font-poppins">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      Use TPIN for a temporary authorization to sell selected stocks while DDPI is inactive
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      This secure, one-time mandate allows smooth transactions until DDPI is active
                    </li>
                  </ul>
                </div>
                {/* <div className="flex flex-row sm:gap-0 gap-4"> */}

                <button
                  onClick={proceedWithDhanTpin}
                  className="w-full sm:w-[280px] h-[45px] bg-red-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-red-600 transition-colors sm:ml-10 mt-4 sm:mt-0"
                >
                  Proceed with dhan Authorization  to Sell
                </button>


              </div>
              <div className="w-full sm:w-[240px] h-[224px] flex items-center justify-center mt-4 sm:mt-0">
                <img
                  src={Ddpi}
                  alt="DDPI illustration"
                  width={2800}
                  height={600}
                  className="w-full h-full object-contain rounded-tl-[10px]"
                />
              </div>
            </div>
          </div>
        </div>
        ) : isPopupOpen && !showNoHoldingModal ? (
        // ) : (


          // {isPopupOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 shadow-lg w-[400px]">
              <h3 className="text-3xl font-semibold mb-6 font-poppins text-center text-gray-700">TPIN Verification</h3>
              <div className='flex flex-row items-center mb-6'>
                <span className="mr-2 font-poppins">Enter TPIN :</span>
                <input
                  type="password"
                  value={tpin}
                  onChange={(e) => setTpin(e.target.value)}
                  className="flex-grow px-3 py-2 border rounded-3xl"
                  placeholder="Enter your TPIN"
                />
              </div>
              <div className="flex justify-center space-x-4">
                <button
                  // onClick={handleDhanTpinSubmit}
                  className="px-6 py-2 bg-[#5ACAC9] font-semibold text-white rounded-2xl hover:bg-blue-600 transition-colors duration-300"
                >
                  Verify
                </button>
                <button
                  onClick={handleCancel}
                  className="px-6 py-2 bg-[#5ACAC9] font-semibold text-white rounded-2xl hover:bg-gray-400 transition-colors duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          
        ) : null}

      </DialogContent>
    </Dialog>
  )
}



export function OtherBrokerModel({ userDetails, onContinue ,setShowOtherBrokerModel,openReviewModal,setOpenReviewTrade  }) {
  const [isOpen, setIsOpen] = useState(true)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isSellAllowed, setIsSellAllowed] = useState(false)

  const [showMainModal, setShowMainModal] = useState(true)
  const [showHowToAuthorize, setShowHowToAuthorize] = useState(false)

  const brokerInstructions = {
    "IIFL Securities": {
      title: 'IIFL Securities Broker : Steps to Authorize Stocks for Selling  ',
      videoId: 'N0KXx4vuThw',
      steps: [
        '1. Log in to your IIFL Securities account.',  
        '2. Tap on the Holdings tab at the bottom of the screen.',
        '3. Select the stocks to sell, click Transfer, and then click **Authorize Now.' , 
        '4. Complete TPIN verification and OTP authentication.' , 
        '5. After successful authorization, return to the platform to retry selling orders.'
      ],
    },
    "ICICI Direct": {
      title: 'ICICI Broker:Steps to Enable Mandate for Selling',
      videoId: 'https://www.youtube.com/embed/icici_ddpi_video_id',
      steps: [
        '1. Log in to ICICI Direct Customer portal and select the Portfolio tab.',
        '2. Click Add Mandate near the Refresh icon above Overall Gain.  ',
        '3. Select the advised stock, click Proceed, enter your MPIN, and submit. ',
        '4. Accept the T&C, enter the OTP, and click Submit.  ',
        '5. After the success message appears, click OK and try executing the sell order again on the Alphaquark platform.',
      ],
    },
    "Upstox": {
      title: 'Upstox Broker: How to Authorize Stocks for Selling ',
      videoId: 'eD6aQ07Ommw',
      steps: [
        '1. Log in to your Upstox account.  ',
        '2. Go to the Holdings tab and click Authorize next to the Day P&L value.',
        '3. Select Authorize with T-PIN.',
        '4. Click Continue to CDSL.',
        '5. Enter your T-PIN (or generate a new one if needed) and verify it, then enter the OTP for authentication.',
        '6. Once verified, return to the Alphaquark platform and place your sell order.',
      ],
    },
    "Kotak Securities": {
      title: 'Kotak Securities: Steps to authorize Stocks for Selling',
      videoId: 'https://www.youtube.com/embed/kotak_ddpi_video_id',
      steps: [
       
      ],
    },
    "HDFC Securities": {
      title: 'HDFC Broker: Steps to authorize Stocks for Selling',
      videoId: 'UI2LD9s3CNw',
      steps: [
        '1. Login to your HDFC Broker account. ',
        '2. Navigate to Portfolio > Demat Balance > Equity.',
        '3. Click Raise eDIS Request, select stock(s), and submit for authorization.',
        '4. Accept the Terms and Conditions, click **Authorize Now, and use **Forgotten TPIN if needed.',
        '5. Complete authorization on CDSL by entering your TPIN and OTP. ',
        '6. After successful authorization, click OK and retry the sell order on Alphaquark.',
      ],
    },
    "AliceBlue": {
      title: 'Aliceblue Broker: How to Authorize Stocks for Selling',
      videoId: 'https://www.youtube.com/embed/aliceblue_ddpi_video_id',
      steps: [
        '1. Log in to your Aliceblue account.  ',
        '2. Navigate to Portfolio > Holdings, and click the Authorize button located below the Portfolio Value.  ',
        '3. In the CDSL interface, select the stocks to authorize, click Authorize, and proceed to CDSL.  ',
        '4. Enter your TPIN and OTP for verification. If required, generate a TPIN before proceeding.',
        '5. Upon successful authorization, you will be redirected to the Portfolio screen.',
        '6. Go back to the our platform and attempt to sell your stocks again.'
      ],
    },
    "Dhan": {
      title: 'Angel One: How to Authorize Stocks for Selling',
      videoId: 'https://www.youtube.com/embed/angelone_ddpi_video_id',
      steps: [
      
      ],
    },
  };
  

  const broker = userDetails?.user_broker
  const instructions = brokerInstructions[broker] || {}

 

  const [showOtherBroker, setShowOtherBroker] = useState(false)


  const handleContinue = () => {
    setIsOpen(false)
    setShowOtherBrokerModel(false)
    openReviewModal()  
    onContinue();
  }
 
  const handleClose = () => {
    setIsOpen(false);  // Close the modal
    setShowOtherBrokerModel(false);  // Close the other broker model
    setShowMainModal(false);  // Close the main modal
    setShowHowToAuthorize(false);  // Hide authorization instructions
    setIsAuthorized(false);  // Reset authorization flag
    setIsSellAllowed(false); 
  }

  if (!isOpen) return null


  
  const openHowToAuthorize = () => {
    setShowMainModal(false)
    setShowHowToAuthorize(true)
  }

  const closeHowToAuthorize = () => {
    setShowHowToAuthorize(false)
    setShowMainModal(true)
  }


  const handleRetrySellOrder = () => {
    console.log("Retrying sell order...")
    // Add your retry sell order logic here
    closeHowToAuthorize()
  }

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };




  
  return (
    // <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 overflow-auto p-4">
    //   <div className="w-full max-w-[580px] bg-white shadow-lg rounded-lg">
    //     <div className="p-4 pb-6 relative font-poppins">
    //       <div className="mb-8">
    //         <button
    //           onClick={handleClose}
    //           className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
    //           aria-label="Close"
    //         >
    //           <X className="w-6 h-6" />
    //         </button>
    //       </div>

    //       <div className="w-full max-w-[450px] mx-auto">
    //         <div className="w-full aspect-video bg-gray-200 mb-3">
    //           <iframe
    //             width="100%"
    //             height="100%"
    //             src={instructions.videoUrl}
    //             title="YouTube video player"
    //             frameBorder="0"
    //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //             allowFullScreen
    //           ></iframe>
    //         </div>

    //         <div className="space-y-1.5">
    //           <h2 className="text-base font-semibold font-poppins">{instructions.title}</h2>

    //           <ol className="space-y-1.5 text-sm pb-4">
    //             {instructions.steps.map((step, index) => (
    //               <li key={index} className="flex flex-col gap-1">
    //                 <div className="flex items-start gap-1">
    //                   <span className="font-semibold font-poppins">{index + 1}.</span>
    //                   <div className="flex flex-col space-y-1">
    //                     <div
    //                       className="flex items-center space-x-2 flex-wrap"
    //                       data-tooltip-id="copy-tooltip"
    //                       data-tooltip-content="COPY"
    //                     >
    //                       <span className="break-all">{step}</span>
    //                       {step.includes('http') && (
    //                         <button
    //                           onClick={() => handleCopy(step.match(/https?:\/\/[^\s]+/)[0])}
    //                           aria-label="Copy link"
    //                         >
    //                           <ClipboardList className="w-4 h-4 cursor-pointer text-gray-300 hover:text-gray-600 transition-colors ml-2 flex-shrink-0" />
    //                         </button>
    //                       )}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </li>
    //             ))}
    //           </ol>
    //         </div>

    //        <div className="mt-3 flex justify-center">
    //           <button
    //             className="w-full sm:w-[240px] h-[45px] bg-red-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-red-600 transition-colors"
    //             onClick={handleContinue}
    //           >
    //             Continue
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Tooltip id="copy-tooltip" />
    // </div>
  
    <>
    {showMainModal && (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
        <div className="w-full max-w-[700px] bg-white shadow-lg rounded-lg relative">
          <button
            className="absolute top-2 right-2 p-2 text-gray-500 font-light hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full"
            aria-label="Close"
            onClick={handleClose}
            >
            <X className="w-6 h-6" />
          </button>
          <div className="flex flex-col h-full px-4 sm:px-8 py-7 justify-between">
            <div>
              <div className="flex items-start gap-4 mb-4">
                <AlertTriangle className="w-7 h-7 mt-0.5 text-[#E43D3D] flex-shrink-0" />
                <div>
                  <h2 className="text-xl font-semibold font-poppins leading-[26px] mb-2">
                    Action Required: Stock Authorization to Sell
                  </h2>
                  <ul className="list-disc pl-5 space-y-4">
                    <li className="text-[13px] font-light font-poppins text-gray-600">
                      Your  broker doesn&apos;t have EDIS flow
                    </li>
                    <li className="text-[13px] font-light font-poppins text-gray-600">
                      Please authorize your stocks manually on your  broker before can try selling <br />
                      orders from here again.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center mt-8">
                <input
                  type="checkbox"
                  id="authorized"
                  className="ml-0 sm:ml-10 text-gray-600 mr-2"
                  checked={isSellAllowed}
                  onChange={(e) => setIsSellAllowed(e.target.checked)}
                />
                <label htmlFor="authorized" className="text-sm font-poppins">
                  I&apos;ve authorized the sell of the above stocks
                </label>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-start items-center space-y-4 sm:space-y-0 sm:space-x-4 sm:mt-4 sm:pb-2 sm:ml-9">
              <button
                className={`w-full sm:w-[170px] h-[41px] rounded-md font-poppins text-sm text-white ${
                  isSellAllowed ? "bg-[#E43D3D]" : "bg-[#E43D3D] bg-opacity-50 cursor-not-allowed"
                }`}
                disabled={!isSellAllowed}
                onClick={handleContinue}
              >
                Retry sell order
              </button>
              <button
                className="w-full sm:w-[170px] h-[41px] px-4 font-poppins text-sm font-medium border border-[#E43D3D] text-[#E43D3D] rounded-md"
                onClick={openHowToAuthorize}
              >
                How to Authorize &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    )}

    {showHowToAuthorize && (
         <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 overflow-auto p-4">
      <div className="w-full max-w-[580px] bg-white shadow-lg rounded-lg">
        <div className="p-4 pb-6 relative font-poppins">
          <div className="mb-8">
            
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
{/* 
          <div className="w-full max-w-[450px] mx-auto">
            <div className="w-full aspect-video bg-gray-200 mb-3">
              <iframe
                width="100%"
                height="100%"
                src={instructions.videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}

            <div >
                {instructions.videoId && (
                  <div className="w-full aspect-video">
                    <YouTube
                      className={`videoIframe `}
                        videoId={instructions.videoId}
                       title="YouTube video player"
                      
                    ></YouTube>
                  </div>
                )}

            <div className="space-y-1.5">
              <h2 className="text-base font-semibold font-poppins">{instructions.title}</h2>

              <ol className="space-y-1.5 text-sm pb-4">
                {instructions?.steps?.map((step, index) => (
                  <li key={index} className="flex flex-col gap-1">
                    <div className="flex items-start gap-1">
                      <span className="font-semibold font-poppins">{index + 1}.</span>
                      <div className="flex flex-col space-y-1">
                        <div
                          className="flex items-center space-x-2 flex-wrap"
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <span className="break-all">{step}</span>
                          {step.includes('http') && (
                            <button
                              onClick={() => handleCopy(step.match(/https?:\/\/[^\s]+/)[0])}
                              aria-label="Copy link"
                            >
                              <ClipboardList className="w-4 h-4 cursor-pointer text-gray-300 hover:text-gray-600 transition-colors ml-2 flex-shrink-0" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>

                <div className="flex items-center gap-1.5 mb-1.5 pt-3">
                  <input
                    type="checkbox"
                    id="authorized-how-to"
                    className="text-gray-600 scale-90"
                    checked={isAuthorized}
                    onChange={(e) => setIsAuthorized(e.target.checked)}
                  />
                  <label htmlFor="authorized-how-to" className="text-xs">
                    I&apos;ve authorized the sell of the above stocks
                  </label>
                </div>
              </div>

              <div className="mt-3">
                <button
                  className="w-full sm:w-[160px] h-[32px] rounded text-xs font-poppins text-white bg-[#E43D3D] hover:bg-[#D32F2F] disabled:bg-opacity-50 disabled:cursor-not-allowed"
                  disabled={!isAuthorized}
                  onClick={handleContinue}
                >
                  Retry sell order
                </button>
              </div>
            </div>
          </div>
        </div>
        <Tooltip id="copy-tooltip" />
      </div>
    )}
  </>
  )
}

export function AfterPlaceOrderDdpiModal({ onClose,userDetails }) {


  const [showActivateNowModel, setShowActivateNowModel] = useState(false)

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };

  const brokerInstructions = {
    "IIFL Securities": {
      title: 'IIFL Broker: Enable DDPI Instructions',
      videoId: 'N0KXx4vuThw',
      steps: [
        '1. Log in to your IIFL Securities account.',  
        '2. Tap on the Holdings tab at the bottom of the screen.',
        '3. Select the stocks to sell, click Transfer, and then click **Authorize Now.' , 
        '4. Complete TPIN verification and OTP authentication.' , 
        '5. After successful authorization, return to the platform to retry selling orders.'
      ],
    },
    "ICICI Direct": {
      title: 'ICICI Direct: Enable DDPI Instructions',
      videoId: 'https://www.youtube.com/embed/icici_ddpi_video_id',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Visit https://secure.icicidirect.com/trading/equity/my-watchlist and click on the Portfolio tab.',
        'Next to the Refresh icon, you will see Add Mandate text, just above the Overall Gain.',
        'Select the stock you received advice for and click Proceed. Enter your MPIN and click Submit.',
        'Check the box for T&C for Clients, enter the OTP sent to your mobile and email, then click Submit. A success message will appear. Click OK to finish.',
      ],
    },
    "Upstox": {
      title: 'Upstox: Enable DDPI Instructions',
      directLink: 'https://help.upstox.com/support/solutions/articles/260205-how-do-i-activate-ddpi-poa-on-upstox-',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your Upstox account.',
        'Go to the Settings or Profile section.',
        'Look for the DDPI activation option and follow the prompts to complete the process.',
      ],
    },
    "Kotak": {
      title: 'Kotak Securities: Enable DDPI Instructions',
      videoId: 'https://www.youtube.com/embed/kotak_ddpi_video_id',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Access your Kotak Securities trading platform.',
        'Navigate to the Account or Settings section.',
        'Find the DDPI activation option and follow the guided steps to activate it.',
      ],
    },
    "HDFC": {
      title: 'HDFC Securities: Enable DDPI Instructions',
      videoId: 'https://www.youtube.com/embed/hdfc_ddpi_video_id',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your HDFC Securities account.',
        'Go to the Profile or Account Settings area.',
        'Locate the DDPI activation section and complete the required steps.',
      ],
    },
    // "AliceBlue": {
    //   title: 'AliceBlue: Enable DDPI Instructions',
    //   directLink: 'https://aliceblueonline.com/support/account-opening/ddpi-activation-guide/',
    //   steps: [
    //     'If you have not enabled DDPI, please enable it by following these steps:',
    //     'Sign in to your AliceBlue trading account.',
    //     'Find the DDPI activation option in the Account or Settings menu.',
    //     'Follow the provided instructions to activate DDPI for your account.',
    //   ],
    // },
    "Dhan": {
      title: 'Dhan Broker: Enable DDPI Instructions',
      directLink: 'https://knowledge.dhan.co/support/solutions/articles/82000900258-from-where-ddpi-service-can-be-activated-',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your IIFL account.',
        'Navigate to the DDPI activation section.',
        'Follow the on-screen instructions to complete the DDPI activation process.',
      ],
    },
  
    "AliceBlue": {
      title: 'AliceBlue: Enable DDPI Instructions',
      videoId: 'https://www.youtube.com/embed/aliceblue_ddpi_video_id',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Sign in to your AliceBlue trading account.',
        'Find the DDPI activation option in the Account or Settings menu.',
        'Follow the provided instructions to activate DDPI for your account.',
      ],
    },
  
    "Zerodha": {
      title: 'Zerodha: Enable DDPI Instructions',
      directLink: 'https://support.zerodha.com/category/account-opening/online-account-opening/other-online-account-opening-related-queries/articles/activate-ddpi',

      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your Zerodha account.',
        'Navigate to the Profile or Settings section.',
        'Find the DDPI activation option and follow the prompts.',
      ],
    },
  
    "Angel One": {
      title: 'AngelOne: Enable DDPI Instructions',
      directLink: 'https://www.angelone.in/knowledge-center/demat-account/how-to-set-up-ddpi-on-angel-one',
      steps: [
        'If you have not enabled DDPI, please enable it by following these steps:',
        'Log in to your AngelOne account.',
        'Access the Profile section.',
        'Find the DDPI option and complete the activation steps.',
      ],
    },
  };
  

 const broker = userDetails?.user_broker
  const instructions = brokerInstructions[broker] || {}

  // console.log("Details of broker",broker)



  const handleActivateDDPiNow = () => {
    // Close the current modal if it's open, and show the new modal
    if (instructions.directLink) {
      window.open(instructions.directLink, '_blank', 'noopener,noreferrer');
      onClose(); 
    } else {
    setShowActivateNowModel(true);
    }
  };

  const closeModal = () => {
    setShowActivateNowModel(false); // Close the activate modal
    onClose(); // Close the parent modal
  };

  const handleBackButton = () => {
    setShowActivateNowModel(false);
  };

  return (
    
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-2 sm:p-4">
      {!showActivateNowModel ? ( 
      <div className="w-full inset-0 max-w-[1016px] sm:h-[450px]  bg-white rounded-lg overflow-hidden relative">
        <button 
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-black opacity-30 hover:opacity-100 transition-opacity"
          onClick={onClose}
        >
          <X size={18} />
        </button>
        <div className="max-w-[916px] mx-auto my-2 sm:my-4 md:my-2 flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 flex justify-center items-center mb-3 sm:mb-6 lg:mb-0 p-2 sm:p-4">
            <div className="aspect-square w-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] relative">
              <img
                src={DDPI}
                alt="DDPI Illustration"
                className="rounded-lg absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex-1 px-3 sm:px-4 md:px-6 lg:pl-8 pt-0 lg:pt-4">
            <h2 className="font-poppins text-base sm:text-lg md:text-xl lg:text-2xl font-semibold leading-tight mb-2 sm:mb-4 md:mb-6 text-center lg:text-left">
              Save Time and Effort by <br/>Enabling DDPI!
            </h2>
            <ul className="space-y-2 sm:space-y-3 md:space-y-4">
              <li className="flex items-start">
                <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                <p className="font-poppins text-xs font-normal sm:text-sm leading-4 sm:leading-5 md:leading-6">
                  <span className="font-semibold">Instant Selling:</span> Sell your holdings instantly after DDPI activation without needing a T-PIN or OTP.
                </p>
              </li>
              <li className="flex items-start">
                <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                <p className="font-poppins text-xs sm:text-sm font-normal leading-4 sm:leading-5 md:leading-6">
                  <span className="font-semibold">Seamless Liquidation:</span> Liquidate your holdings without the hassle of daily pre-authorization for each sell order.
                </p>
              </li>
              <li className="flex items-start">
                <img src={Checked} width={14} height={14} className="mr-2 mt-1 flex-shrink-0" alt="Checkmark" />
                <p className="font-poppins text-xs sm:text-sm font-normal leading-4 sm:leading-5 md:leading-6">
                  <span className="font-semibold">Faster Transactions: </span>Enjoy smoother and quicker trading experiences with fewer barriers.
                </p>
              </li>
            </ul>
            <div className="flex justify-center lg:justify-start mt-3 sm:mt-4 md:mt-10">
              <button 
                className="w-full max-w-[200px] sm:max-w-[250px] h-[40px] sm:h-[46px] rounded-lg text-white font-poppins font-semibold text-xs sm:text-sm bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 transition-colors duration-300"
                onClick={handleActivateDDPiNow}
              >
                Activate DDPI Now &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
      ) :( 
        showActivateNowModel && (
          <div className="fixed inset-0  flex items-center justify-center z-50 overflow-auto p-4">
          <div className="w-full max-w-[580px] bg-white shadow-lg rounded-lg">
            <div className="p-4 pb-6 relative font-poppins">
              <div className="mb-8 justify-between items-center">


              <button
                  onClick={handleBackButton}
                  className="text-gray-500 hover:text-gray-700 flex items-center"
                  aria-label="Back"
                >
                  <ArrowLeft className="w-5 h-5 mr-1" />
                  <span className="text-sm">Back</span>
                </button>

                <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  aria-label="Close"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
    
              <div className="w-full max-w-[450px] mx-auto">
                <div className="w-full aspect-video bg-gray-200 mb-3">
                  <iframe
                    width="100%"
                    height="100%"
                    src={instructions.videoId}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
    
                <div className="space-y-1.5">
                  <h2 className="text-base font-semibold font-poppins">{instructions.title}</h2>
    
                  <ol className="space-y-1.5 text-sm pb-4">
                    {instructions.steps.map((step, index) => (
                      <li key={index} className="flex flex-col gap-1">
                        <div className="flex items-start gap-1">
                          <span className="font-semibold font-poppins">{index + 1}.</span>
                          <div className="flex flex-col space-y-1">
                            <div
                              className="flex items-center space-x-2 flex-wrap"
                              data-tooltip-id="copy-tooltip"
                              data-tooltip-content="COPY"
                            >
                              <span className="break-all">{step}</span>
                              {step.includes('http') && (
                                <button
                                  onClick={() => handleCopy(step.match(/https?:\/\/[^\s]+/)[0])}
                                  aria-label="Copy link"
                                >
                                  <ClipboardList className="w-4 h-4 cursor-pointer text-gray-300 hover:text-gray-600 transition-colors ml-2 flex-shrink-0" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>

                </div>

      
              </div>
            </div>
          </div>
          <Tooltip id="copy-tooltip" />
        </div>        )
)
    }
        </div>
  );
}


export function FyersTpinModal({ isOpen, setIsOpen, userDetails }) {
  const [loading, setLoading] = useState(false);
  const [cdslHtml, setCdslHtml] = useState('');

  const proceedWithFyersTpin = async () => {
    setLoading(true);
    try {
      const broker = userDetails.user_broker;

      if (broker === "Fyers") {
        // Generate 
        console.log("hit")
        const generateTpinResponse = await fetch('https://ccxtprod.alphaquark.in/fyers/tpin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientId: userDetails?.clientCode,
            accessToken: userDetails?.jwtToken
          }),
        });

        const generateTpinData = await generateTpinResponse.json();
        console.log("generateTpinData",generateTpinData)

        if (generateTpinData.status === 0) {
          toast.success("TPIN generation request sent successfully.");
          setIsOpen(false); // set  modal  to close

        //   openPopupWindow(submitHoldingsData.data);
        // } else {
        //   throw new Error(submitHoldingsData.message || 'Failed to fetch CDSL authorization page');
        }
      } else {
        throw new Error("generateTpinData.message ||", 'Failed to generate TPIN for Fyers');
      }
    }  catch (error) {
    console.error('Error in API call:', error);
    toast.error(error.message || 'An error occurred during the process');
  } finally {
    setLoading(false);
  }
};
const openPopupWindow = (formHtml) => {
  const popupWidth = 800;
  const popupHeight = 600;
  const left = (window.innerWidth - popupWidth) / 2 + window.screenX;
  const top = (window.innerHeight - popupHeight) / 2 + window.screenY + 30;

  const popup = window.open(
    "", 
    "_blank", 
    `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`
  );

  if (popup) {
    popup.document.write(formHtml);
    popup.document.close();
    
    setIsOpen(false);

    const popupChecker = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(popupChecker);
        window.location.reload();
      }
    }, 300);
  } else {
    toast.error('Please allow popups for this site to complete the CDSL authorization process.');
  }
};
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
    <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none" showCloseButton={false}>

      <div className="fixed inset-0  flex items-center justify-center p-4">
        <div className="bg-white rounded-lg w-full max-w-[740px] max-h-[90vh] overflow-y-auto sm:h-[323px] relative border-t border-l">
          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            aria-label="Close"
          >
            <X className="w-6 h-6" />
          </button>
          <div className="flex flex-col sm:flex-row h-full items-center">
            <div className="w-full sm:w-[440px] p-6 sm:pl-10 flex flex-col justify-between">
              <div>
                <div className="flex items-start gap-4 mb-4">
                  <AlertTriangle className="text-red-500 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-xl font-semibold font-poppins text-[#000000B3]">DDPI Inactive: Proceed with TPIN Mandate</h2>
                  </div>
                </div>
                <ul className="space-y-3 text-sm text-gray-600 mb-6 pl-10 font-poppins">
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    Use TPIN for a temporary authorization to sell selected stocks while DDPI is inactive
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    This secure, one-time mandate allows smooth transactions until DDPI is active
                  </li>
                </ul>
              </div>
              {/* <div className="flex flex-row sm:gap-0 gap-4"> */}

              <button
                onClick={proceedWithFyersTpin}
                className="w-full sm:w-[240px] h-[45px] bg-red-500 text-white rounded-[6px] text-[13px] font-medium font-poppins hover:bg-red-600 transition-colors sm:ml-4 mt-4 sm:mt-0"
              >
                Proceed with Authorization to Sell
              </button>


            </div>
            <div className="w-full sm:w-[240px] h-[224px] flex items-center justify-center mt-4 sm:mt-0">
              <img
                src={Ddpi}
                alt="DDPI illustration"
                width={2800}
                height={600}
                className="w-full h-full object-contain rounded-tl-[10px]"
              />
            </div>
          </div>
        </div>
      </div>
       

    </DialogContent>
  </Dialog>
  )
   
}