import React, { useState, useEffect } from "react";
import { Trash2, XIcon, MinusIcon, PlusIcon, RefreshCw } from "lucide-react";
import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import server from "../../utils/serverConfig";

const ReviewTradeModel = ({
  calculateTotalAmount,
  stockDetails,
  getLTPForSymbol,
  setStockDetails,
  setOpenReviewTrade,
  placeOrder,
  loading,
  funds,
  openReviewTrade,
  getCartAllStocks,
  broker,
}) => {
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const isDesktop = useMediaQuery("(min-width: 830px)");
  const fullUrl = window.location.origin;
  const [selectedOption, setSelectedOption] = useState("");
  const [inputFixSizeValue, setInputFixValue] = useState("");
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock
    );
    setStockDetails(newData);
  };

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: stock.quantity + 1 }
        : stock
    );
    setStockDetails(newData);
  };

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = parseInt(value) || 0;
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: newQuantity }
        : stock
    );
    setStockDetails(newData);
  };

  // const handleDecreaseStockQty = (symbol, tradeId) => {
  //   const newData = stockDetails.map((stock) => {
  //     if (stock.tradingSymbol === symbol && stock.tradeId === tradeId && stock.quantity > 1) {
  //       const newQuantity = stock.quantity - 1;
  //       return { ...stock, quantity: newQuantity };
  //     }
  //     return stock;
  //   });
  //   setStockDetails(newData);
  //   updateFixedSizeIfNeeded(newData, symbol, tradeId);
  // };

  // const handleIncreaseStockQty = (symbol, tradeId) => {
  //   const newData = stockDetails.map((stock) => {
  //     if (stock.tradingSymbol === symbol && stock.tradeId === tradeId) {
  //       const newQuantity = stock.quantity + 1;
  //       return { ...stock, quantity: newQuantity };
  //     }
  //     return stock;
  //   });

  //   setStockDetails(newData);
  //   updateFixedSizeIfNeeded(newData, symbol, tradeId);
  // };

  const handleSelectStock = async (symbol, tradeId) => {
    const isSelected = stockDetails.some(
      (selectedStock) =>
        selectedStock.tradingSymbol === symbol &&
        selectedStock.tradeId === tradeId
    );

    if (isSelected) {
      // If the stock is already selected, remove it from the cart
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/remove-from-cart`,
          { tradeId }
        );

        setStockDetails(
          stockDetails.filter(
            (selectedStock) =>
              selectedStock.tradingSymbol !== symbol ||
              selectedStock.tradeId !== tradeId
          )
        );
        getCartAllStocks();
      } catch (error) {
        console.error("Error removing stock from cart", error);
      }
    } else {
      // If the stock is not selected, add it to the cart
      const updatedStock = stockDetails.find(
        (item) => item.Symbol === symbol && item.tradeId === tradeId
      );
      if (updatedStock) {
        const newStock = {
          user_email: updatedStock.user_email,
          trade_given_by: updatedStock.trade_given_by,
          tradingSymbol: updatedStock.Symbol,
          transactionType: updatedStock.Type,
          exchange: updatedStock.Exchange,
          segment: updatedStock.Segment,
          productType: updatedStock.ProductType,
          orderType: updatedStock.OrderType,
          price: updatedStock.Price,
          quantity: updatedStock.Quantity,
          priority: updatedStock.Priority,
          tradeId: updatedStock.tradeId,
          user_broker: broker,
        };
        setStockDetails([...stockDetails, newStock]);

        try {
          await axios.post(
            `${server.server.baseUrl}api/cart/add/add-to-cart`,
            newStock
          );
          getCartAllStocks();
        } catch (error) {
          console.error("Error adding stock to cart", error);
        }
      }
    }
  };

  const handleFixSize = () => {
    if (selectedOption === "fix-size" && inputFixSizeValue) {
      const fixedSize = parseFloat(inputFixSizeValue);
      const updatedStockDetails = stockDetails.map((stock) => {
        const currentPrice = parseFloat(getLTPForSymbol(stock.tradingSymbol));
        const newQuantity = Math.floor(fixedSize / currentPrice);
        return { ...stock, quantity: newQuantity };
      });
      setStockDetails(updatedStockDetails);
    }
  };

  const handleReset = () => {
    setSelectedOption("");
    setInputFixValue("");
  };

  const hasZeroQuantity = stockDetails.some((stock) => stock.quantity === 0);

  const renderContent = () => (
    <div className="flex flex-col w-full h-full bg-white rounded-[8px] ">
      <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-poppins font-semibold text-gray-900">Review Trade Details</h2>
        {hasZeroQuantity && (
          <p className="mt-2 text-sm text-[#EA2D3F] font-semibold">
            Please select quantity to place order
          </p>
        )}
        {parseFloat(calculateTotalAmount()) > parseFloat(funds) && (
          <p className="mt-2 text-sm font-poppins text-amber-600">
            Note: Orders may be rejected due to insufficient broker balance of ₹{parseFloat(funds).toFixed(2)}.
          </p>
        )}
      </div>

      <div className="flex-1 overflow-auto custom-scroll min-h-[300px] sm:min-w-[600px] sm:max-h-[400px] px-4 sm:px-6 py-4">
        {stockDetails.map((ele) => (
          <div key={ele.tradeId} className="flex items-center justify-between py-3 border-b border-gray-200 last:border-b-0">
            <div className="flex-1 sm:mr-4 max-w-[30%] min-w-[30%]">
              <p className="text-[14px] sm:text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900 truncate">{ele.tradingSymbol}</p>
              <span className={`inline-flex items-center px-1 py-0.5 rounded text-sm font-semibold ${
                ele.transactionType?.toUpperCase() === "BUY"
                  ? "text-[#16A085] text-[14px] font-poppins font-medium"
                  : " text-[#EA2D3F] text-[14px] font-poppins font-medium"
              }`}>
                {ele.transactionType?.toUpperCase()}
              </span>
            </div>
            <div className="flex items-center min-w-[5%] sm:min-w-[10%] space-x-2">
              <button
                onClick={() =>
                  handleDecreaseStockQty(ele.tradingSymbol, ele.tradeId)
                }
                disabled={ele.quantity <= 1}
                className="p-1 rounded-full hover:bg-gray-200 disabled:opacity-50"
              >
                <MinusIcon className="h-3 w-3 sm:h-4 sm:w-4" />
              </button>
              <input
                type="text"
                value={ele.quantity}
                onChange={(e) => handleQuantityInputChange(ele.tradingSymbol, e.target.value, ele.tradeId)}
                className="w-14 sm:w-20 text-center font-poppins border border-gray-300 rounded-md selection:bg-transparent"
              />
              <button
                onClick={() =>
                  handleIncreaseStockQty(ele.tradingSymbol, ele.tradeId)
                }
                className="p-1 rounded-full hover:bg-gray-200"
              >
                <PlusIcon className="h-3 w-3 sm:h-4 sm:w-4 " />
              </button>
            </div>



            <div className=" w-[10%] text-right ">
              <p className="text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900">₹{getLTPForSymbol(ele.tradingSymbol)}</p>
              <p className="text-xs sm:ml-0 text-gray-500  font-poppins">{ele.orderType?.toLowerCase()} {ele.orderType === "LIMIT" || ele.orderType === "STOP" ? `(${ele.price})` : null}</p>
            </div>
            <button
              onClick={() => handleSelectStock(ele.tradingSymbol, ele.tradeId)}
              className="ml-10 sm:ml-4 p-2  text-[#000000]/80  text-[15px] cursor-pointer hover:text-red-500"
            >
              <Trash2
              size={18}
             className="h-4 w-4 sm:h-5 sm:w-5" />
            </button>
          </div>
        ))}
      </div>

      <div className="px-3 sm:px-6  border-t border-gray-200">
        <h3 className=" sm:hidden text-base font-medium font-poppins text-gray-900 pt-3 mb-1 ">Scale Quantity by</h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-4 pt-3 min-h-[40px] max-h-[40px]">
            <input
              type="radio"
              id="fix-size"
              name="options"
              value="fix-size"
              checked={selectedOption === "fix-size"}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="h-4 w-4"
              style={{ accentColor: "#20A48A" }}
            />
                  <h3 className="hidden sm:block text-base font-medium font-poppins text-gray-900  pr-3">Scale Quantity by</h3>

            <label
              htmlFor="fix-size"
              className="text-sm font-medium text-gray-700"
            >
              Fix Size
            </label>
            {selectedOption === "fix-size" && (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={inputFixSizeValue}
                  onChange={(e) => setInputFixValue(e.target.value)}
                  className="w-[97px] sm:w-24 px-2 py-1 text-sm border border-gray-300 rounded-md"
                  placeholder="Enter value"
                />
                <button
                  onClick={handleFixSize}
                  className={`px-2 sm:px-3 py-1 text-sm font-medium text-white rounded-md ${
                    !inputFixSizeValue ? 'bg-gray-400 cursor-not-allowed' : 'bg-black '
                  }`}                >
                  Update
                </button>
                <button
                  onClick={handleReset}
                  className="p-1 text-gray-600 hover:text-gray-900 focus:outline-none"
                >
                  <RefreshCw className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderFooter = () => (
    <div className="px-4 sm:px-6 py-4 bg-gray-50 border-t rounded-[8px] border-gray-200">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-700">Total Amount:</p>
          <p className="text-2xl font-semibold text-gray-900">₹ {calculateTotalAmount()}</p>
        </div>
        <button
          className={` px-6 py-2 text-[18px] font-medium rounded-md font-poppins text-white
        w-[160px] h-[40px] flex items-center justify-center  ${
            hasZeroQuantity
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-black "
          } text-white `}
          onClick={placeOrder}
          disabled={hasZeroQuantity}
        >
          {loading ? <LoadingSpinner /> : "Place Order"}
        </button>
      </div>
    </div>
  );

  // if (isDesktop) {
    return (
      <Dialog open={openReviewTrade} onOpenChange={setOpenReviewTrade}>
        <DialogContent className="sm:max-w-[600px] p-0">
          {renderContent()}
          {renderFooter()}
        </DialogContent>
      </Dialog>
    );
  }

  // return (
  //   <Drawer open={openReviewTrade} onOpenChange={setOpenReviewTrade}>
  //     <DrawerContent className="h-[90vh] flex flex-col">
  //       <div className="flex-1 overflow-y-auto">{renderContent()}</div>
  //       <DrawerFooter className="px-0">{renderFooter()}</DrawerFooter>
  //     </DrawerContent>
  //   </Drawer>
  // );
// };

export default ReviewTradeModel;