import React, { useState, useEffect } from "react";
import { XIcon, ChevronRight, ChevronDown, ChevronUp } from "lucide-react";
import Checked from "../../assests/checked.svg";
import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import { CountryCode } from "../../utils/CountryCode";

const style = {
  selectDiv2:
    "flex items-center px-2 py-2  rounded-lg mx-3 md-3 text-black-500  hover:bg-black/10 hover:text-black-500  ease-linear duration-150 cursor-pointer",
};

const PlanSubscribeModal = ({
  userEmail,
  name,
  setName,
  setMobileNumber,
  mobileNumber,
  setPanNumber,
  panNumber,
  strategyDetails,
  paymentModal,
  setPaymentModal,
  handleCardClick,
  selectedCard,
  handleOk,
  loading,
  setInvestAmount,
  invetAmount,
  isSubscribed,
  setCountryCode = () => {},
  countryCode = "+91",
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);
  const [isNameComplete, setIsNameComplete] = useState(false);
  const [isPanComplete, setIsPanComplete] = useState(false);
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState("");

  // Set default country code on component mount
  useEffect(() => {
    const defaultCountry = CountryCode.find(
      (country) => country.value === "+91"
    ); // India as default
    if (defaultCountry && !countryCode) {
      setSelectedCountry(defaultCountry);
      setCountryCode(defaultCountry.value);
    }
  }, []);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setIsNameComplete(value.trim().split(/\s+/).length >= 2);
  };

  const handlePanChange = (e) => {
    const value = e.target.value.toUpperCase();
    const sanitizedValue = value.replace(/[^A-Z0-9]/g, "").slice(0, 10);
    setPanNumber(sanitizedValue);
    setIsPanComplete(sanitizedValue.length === 10);
  };

  const handleMobileNumberChange = (e) => {
    let value = e.target.value;

    // Remove any non-numeric characters
    value = value.replace(/\D/g, "");

    // If number is 12 digits, remove first two digits
    if (value.length === 12) {
      value = value.slice(2);
    }

    // Limit to 10 digits
    value = value.slice(0, 10);

    setMobileNumber(value);
    setIsMobileNumberComplete(value.length === 10);

    if (value.length > 0 && value.length !== 10) {
      setMobileNumberError("Mobile number should be 10 digits");
    } else {
      setMobileNumberError("");
    }
  };

  const filteredCountryCodes = CountryCode.filter((country) =>
    `${country.value} ${country.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setCountryCode(country.value);
    setShowCountryCode(false);
  };
  const renderContent = () => (
    <div className="flex flex-col sm:max-w-[580px] sm:min-w-[580px]  w-full sm:min-h-[420px] space-y-4 px-2 overflow-auto">
      <div className="text-2xl font-bold border-b pb-4">
        {isSubscribed
          ? `Renew ${strategyDetails?.name} Subscription`
          : `Invest in ${strategyDetails?.name}`}
      </div>
      <div className="px-2  pb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm  text-gray-700 font-semibold font-poppins">
              Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              onFocus={() => setIsNameComplete(false)}
              onBlur={() =>
                setIsNameComplete(name.trim().split(/\s+/).length >= 2)
              }
              placeholder="Enter your Name"
              className={`w-full h-12 rounded-md border ${
                isNameComplete ? "border-green-500" : "border-gray-300"
              } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm  text-gray-700 font-semibold font-poppins">
              Email<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={userEmail}
              disabled
              placeholder="Enter your Name"
              className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-normal text-gray-700">
              <div className="flex items-center font-semibold font-poppins">
                <span className="mr-2">
                  Phone Number<span className="text-red-500">*</span>
                </span>
                <span className="text-xs font-normal text-gray-500">
                  (WhatsApp enabled)
                </span>
              </div>
            </label>
            <div className="relative flex flex-row items-center">
              <div
                className={`h-10 mr-2 w-[20%] text-[#000000]/80 flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-2.5`}
                onClick={() => setShowCountryCode(!showCountryCode)}
              >
                <div className="text-[#000000]/80">
                  {selectedCountry ? selectedCountry.value : "+91"}
                </div>
                <div
                  className={`pl-1 ease-linear duration-150 text-[#000000]/25`}
                >
                  {showCountryCode ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </div>
              </div>

              {showCountryCode && (
                <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[rgba(185, 192, 187, 0.7)] max-h-72 overflow-y-auto">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="mx-3 px-3 py-2 mb-2 border rounded-md"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredCountryCodes.length > 0 ? (
                    filteredCountryCodes.map((country, index) => (
                      <div
                        key={index}
                        className={`pl-3 font-medium ${style.selectDiv2}`}
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country.value} {country.label}
                      </div>
                    ))
                  ) : (
                    <div className="pl-3 font-medium text-gray-500">
                      No results found
                    </div>
                  )}
                </div>
              )}

              <input
                type="tel"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                onFocus={() => setIsMobileNumberComplete(false)}
                onBlur={() =>
                  setIsMobileNumberComplete(mobileNumber?.length === 10)
                }
                placeholder="Enter your Mobile Number"
                className={`w-full h-10 rounded-md border ${
                  isMobileNumberComplete
                    ? "border-green-500"
                    : "border-gray-300"
                } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
              />
            </div>
            {mobileNumberError && (
              <p className="text-red-500 text-xs mt-1 font-poppins">
                {mobileNumberError}
              </p>
            )}
          </div>
          <div className="space-y-2">
            <label className="block text-sm  text-gray-700 font-semibold font-poppins">
              Pan Number<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={panNumber}
              onChange={handlePanChange}
              onFocus={() => setIsPanComplete(false)}
              onBlur={() => setIsPanComplete(panNumber?.length === 10)}
              maxLength={10}
              placeholder="Enter your PAN Number for KYC Check"
              className={`w-full h-10 rounded-md border ${
                isPanComplete ? "border-green-500" : "border-gray-300"
              } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
            />
          </div>
          {strategyDetails?.type === "model portfolio" ? (
            <div className="space-y-2">
              <label className="block text-sm  text-gray-700 font-semibold font-poppins">
                Invest Amount (₹)<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                value={invetAmount}
                onChange={(e) => setInvestAmount(e.target.value)}
                placeholder={`Amount must be ₹${strategyDetails?.minInvestment} or more`}
                className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="space-y-2">
        <label className="block text-sm  text-gray-700 font-semibold font-poppins">
          Plan Fees<span className="text-red-500">*</span>
        </label>
        <div className="grid grid-cols-2 sm:h-20  md:grid-cols-4 gap-4">
          {strategyDetails?.frequency?.length !== 0 ? (
            strategyDetails?.frequency?.map((offer, i) => (
              <div
                key={i}
                className={`p-3 rounded-lg cursor-pointer border ${
                  selectedCard === offer
                    ? "bg-[#E8F5F3] border-[#6AC2B1]"
                    : "bg-white border-gray-200"
                }`}
                onClick={() => handleCardClick(offer)}
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="text-sm">
                    {offer === "monthly"
                      ? "Monthly"
                      : offer === "quarterly"
                      ? "Quarterly"
                      : offer === "half-yearly"
                      ? "Half Yearly"
                      : "Yearly"}
                  </div>
                  <div
                    className={`w-4 h-4 rounded-full border flex items-center justify-center ${
                      selectedCard === offer
                        ? "border-[#6AC2B1] bg-[#6AC2B1]"
                        : "border-gray-300 bg-white"
                    }`}
                  >
                    {selectedCard === offer && (
                      <img src={Checked} className="w-3 h-3" alt="Checked" />
                    )}
                  </div>
                </div>
                <div className="font-medium">
                  ₹{strategyDetails?.pricing?.[offer]}
                </div>
              </div>
            ))
          ) : (
            <div
              className={`p-3 rounded-lg cursor-pointer border bg-[#E8F5F3] border-[#6AC2B1]`}
            >
              <div className="flex justify-between items-center mb-2">
                <div className="text-sm">One Time</div>
                <div className="w-4 h-4 ml-2  rounded-full border flex items-center justify-center border-[#6AC2B1] bg-[#6AC2B1]">
                  <img
                    src={Checked}
                    alt="Checked Icon"
                    className="w-4 h-4 md:w-6  "
                  />{" "}
                </div>
              </div>
              <div className="font-medium">₹{strategyDetails?.amount}</div>
            </div>
          )}
        </div>
      </div>
      <button
        disabled={
          (strategyDetails?.frequency?.length !== 0 && selectedCard === null) ||
          (strategyDetails?.type !== "bespoke" &&
            !(invetAmount >= strategyDetails?.minInvestment)) || // Only check invetAmount when type is not "bespoke"
          !userEmail ||
          !name ||
          !panNumber ||
          !mobileNumber
        }
        onClick={handleOk}
        className="w-full p-3 rounded-lg bg-black text-white font-medium hover:bg-black/90 disabled:bg-black/30 disabled:cursor-not-allowed transition-colors duration-150 flex items-center justify-center"
      >
        {loading ? (
          <LoadingSpinner size={16} />
        ) : (
          <>
            <span className="mr-2">
              {isSubscribed ? "Renew now" : "Subscribe now"}
            </span>
            <ChevronRight size={16} />
          </>
        )}
      </button>
    </div>
  );

  // if (isDesktop) {
  return (
    <Dialog open={paymentModal} onOpenChange={setPaymentModal}>
      <DialogContent className="max-w-2xl">{renderContent()}</DialogContent>
    </Dialog>
  );
  // }

  // return (
  //   <Drawer open={paymentModal} onOpenChange={setPaymentModal}>
  //     <DrawerContent className="focus:outline-none">
  //       <div className="relative">
  //         <button
  //           onClick={() => setPaymentModal(false)}
  //           className="absolute top-4 right-4"
  //         >
  //           <XIcon className="h-6 w-6" />
  //         </button>
  //         {renderContent()}
  //       </div>
  //     </DrawerContent>
  //   </Drawer>
  // );
};

export default PlanSubscribeModal;
