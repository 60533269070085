import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { CalendarDays } from "lucide-react";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";

import MPF_1 from "../../assests/mpf_1.png";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";

const RebalanceCard = ({
  data,
  frequency,
  setOpenRebalanceModal,
  modelName,
  userEmail,
  apiKey,
  jwtToken,
  secretKey,
  clientCode,
  viewToken,
  sid,
  serverId,
  setCaluculatedPortfolioData,
  repair,
  advisorName,
  setModelPortfolioModelId,
  setStoreModalName,
  setOpenTokenExpireModel,
  broker,
  setBrokerModel,
  funds,
  overView,
}) => {
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const [loading, setLoading] = useState(false);

  const handleAcceptRebalance = () => {
    const isMarketHours = IsMarketHours();
    setLoading(true);
    if (broker === undefined) {
      setBrokerModel(true);
      setLoading(false);
    } else if (funds?.status === false || funds?.status === 1) {
      setOpenTokenExpireModel(true);
      setLoading(false);
    } 
    else if (!isMarketHours) {
      setLoading(false);
      toast.error("Orders cannot be placed outside Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    } 
    else {
      if (repair) {
        setOpenRebalanceModal(true);
        setModelPortfolioModelId(data.model_Id);
        setStoreModalName(modelName);
        setLoading(false);
      } else {
        let payload = {
          userEmail: userEmail,
          userBroker: broker,
          modelName: modelName,
          advisor: advisorName,
          model_id: data.model_Id,
          userFund: funds?.data?.availablecash,
        };
        if (broker === "IIFL Securities") {
          payload = {
            ...payload,
            clientCode: clientCode,
          };
        } else if (broker === "ICICI Direct") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            secretKey: checkValidApiAnSecret(secretKey),
            sessionToken: jwtToken,
          };
        } else if (broker === "Upstox") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            apiSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
          };
        } else if (broker === "Angel One") {
          payload = {
            ...payload,
            apiKey: angelOneApiKey,
            jwtToken: jwtToken,
          };
        } else if (broker === "Zerodha") {
          payload = {
            ...payload,
            apiKey: zerodhaApiKey,
            accessToken: jwtToken,
          };
        } else if (broker === "Dhan") {
          payload = {
            ...payload,
            clientId: clientCode,
            accessToken: jwtToken,
          };
        } else if (broker === "Hdfc Securities") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            accessToken: jwtToken,
          };
        } else if (broker === "Kotak") {
          payload = {
            ...payload,
            consumerKey: checkValidApiAnSecret(apiKey),
            consumerSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
            viewToken: viewToken,
            sid: sid,
            serverId: serverId,
          };
        }
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        };
        axios
          .request(config)
          .then((response) => {
            setLoading(false);
            setCaluculatedPortfolioData(response.data);
            setOpenRebalanceModal(true);
            setModelPortfolioModelId(data.model_Id);
            setStoreModalName(modelName);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  return (
    <div
      className={`${
        repair ? "bg-[#DE8846]" : "bg-[#2A587C]"
      } relative font-poppins w-full rounded-xl  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]  py-4`}
    >
      {/* Tooltip Modal  */}

      <div
        className={`flex flex-col  items-start justify-between  mx-5 border-b border-[#ffffff]/20`}
      >
        <div className="flex space-x-2 py-2">
          <p className=" text-[12px] font-poppins text-[#ffffff]/80 font-light capitalize">
            {" "}
            Rebalance Update : {frequency}{" "}
          </p>
        </div>
      </div>
      <div className={`flex flex-col  items-start justify-between   px-6`}>
        <div className="flex flex-row space-x-6 max-h-[85px]   py-4">
          <img src={MPF_1} alt="Portfolio" />
          <div className="flex flex-col ">
            <p className=" text-xl font-medium font-poppins text-[#ffffff] capitalize">
              {" "}
              {data?.updatedModelName}{" "}
            </p>
            <span className="ml-[2px] pt-[8px] text-[12px] text-[#ffffff]/80 font-light">
              {overView?.split(" ").slice(0, 6).join(" ")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={` flex flex-row items-center justify-between mt-8  pb-1 px-6`}
      >
        <div className="text-[#ffffff] text-xs  font-medium flex flex-row items-center">
          <CalendarDays size={16} className="" />
          <span className="ml-2">
            {moment(data.rebalanceDate).format("Do MMM YYYY")}
          </span>
          <span className="mx-2">|</span>{" "}
          <span>{moment(data.rebalanceDate).format("HH:mm A")}</span>
        </div>
      </div>

      <div className={` flex mt-[10px] px-6 pb-4`}>
          <button
        className="w-full h-10 px-4 rounded-[4px] bg-white text-[#1D1D1F] text-xs sm:text-[13px] lg:text-[13px] font-poppins font-semibold  flex items-center justify-center"    
        // hover:scale-[1.03] transition-all duration-150 ease-linear
        onClick={handleAcceptRebalance}
          >
        <span className="w-full h-full flex items-center justify-center">

            {loading === true ? (
              <LoadingSpinner />
            ) : repair ? (
              "Repair Portfolio"
            ) : (
              "Accept Rebalance"
            )}
            </span>
          </button>
        </div>
      </div>
  );
};

export default RebalanceCard;
