import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { CircleXIcon, SearchIcon, XIcon, CloudUploadIcon } from "lucide-react";
import { CSVLink } from "react-csv";

import Excel from "../../assests/Icon/Excel.svg";
import AdminDashboardLayout from "../AdminDashboardLayout";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import server from "../../utils/serverConfig";
import GroupListTable from "./GroupListTable";
import ClientListNewTable from "./ClientListNewTable";
import FormatDateTime from "../../utils/formatDateTime";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-300  hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selected_2:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const GroupClientListSection = ({}) => {
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [userId, setUserId] = useState();
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);
  const [isGroupDataLoading, setIsGroupDataLoading] = useState(true);

  const getAllGroupsData = async (userId) => {
    try {
      setIsGroupDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-groups/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch groups");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const groupNames = data.data;

      // console.log("Group data:", groupNames);
      setAllGroupsData(data.data);
      // const allClientsDetails = groupNames.flatMap((group) => group.data);
      // console.log("Cleints Details data:", allClientsDetails);

      // setAllClientsData(allClientsDetails);
      setIsGroupDataLoading(false);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setIsGroupDataLoading(false);
      return [];
    }
  };

  const getAllClientsData = async (userId) => {
    try {
      setIsClientDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cleint data");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const clientsData = data.data;

      setAllClientsData(clientsData);
      setIsClientDataLoading(false);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      setIsClientDataLoading(false);
      return [];
    }
  };

  useEffect(() => {
    if (adminEmail) {
      getAdminDetails(adminEmail)
        .then((fetchedData) => {
          setUserId(fetchedData?._id);
          getAllClientsData(fetchedData?._id);
          getAllGroupsData(fetchedData?._id);
        })
        .catch((err) => console.log(err));
    }
  }, [adminEmail, isDataFetching]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("client-list");
  const [newGroupName, setNewGroupName] = useState("");
  const [addGroupModal, setAddGroupModal] = useState(false);

  const openAddGroupModal = () => {
    setAddGroupModal(true);
  };

  const closeAddGroupModal = () => {
    setAddGroupModal(false);
  };

  const handleAddGroupName = async () => {
    const groupData = {
      userId,
      creationDate: FormatDateTime(new Date()),
      groupName: newGroupName?.toLowerCase()?.replace(/ /g, "_"),
    };
    console.log("data", groupData);

    try {
      const response = await fetch(`${server.server.baseUrl}api/add-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(groupData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      getAllGroupsData(userId);
      getAllClientsData(userId);
      closeAddGroupModal();
      setNewGroupName("");

      toast.success("New group added successfully !!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#16a085",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "16px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#16a085",
          secondary: "#FFFAEE",
        },
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);

      toast.error("Error in adding new group !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const csvHeaders = [
    { label: "client_id", key: "client_id" },
    { label: "client_name", key: "client_name" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "groups", key: "groups" },
    { label: "location", key: "location" },
    { label: "telegram", key: "telegram" },
    { label: "pan", key: "pan" },
    { label: "comments", key: "comments" },
    { label: "subscription_id", key: "subscription_id" },
    { label: "start_date", key: "start_date" },
    { label: "plan", key: "plan" },
    { label: "capital", key: "capital" },
    { label: "charges", key: "charges" },
    { label: "invoice", key: "invoice" },
    { label: "expiry", key: "expiry" },
    { label: "acc_creation_date", key: "acc_creation_date" },
    { label: "updated_at", key: "updated_at" },
  ];

  const flattenClientsData = () => {
    return allClientsData.flatMap((client) => {
      // If the client has subscriptions, map through them
      if (client.subscriptions && client.subscriptions.length > 0) {
        return client.subscriptions.map((subscription) => ({
          client_id: client.clientId,
          client_name: client.clientName,
          email: client.email,
          phone: client.phone,
          groups: client.groups, // Keep groups as an array
          location: client.location,
          telegram: client.telegram,
          pan: client.pan,
          comments: client.comments,
          subscription_id: subscription?.subId || "",
          start_date: subscription?.startDate || "",
          plan: subscription?.plan || "",
          capital: subscription?.capital || "",
          charges: subscription?.charges || "",
          invoice: subscription?.invoice || "",
          expiry: subscription?.expiry || "",
          acc_creation_date: FormatDateTime(client.creationDate),
          updated_at: FormatDateTime(client.updatedAt),
        }));
      }

      // If the client has no subscriptions, return the client data with subscription fields as empty strings
      return {
        client_id: client.clientId,
        client_name: client.clientName,
        email: client.email,
        phone: client.phone,
        groups: client.groups, // Keep groups as an array
        location: client.location,
        telegram: client.telegram,
        pan: client.pan,
        comments: client.comments,
        subscription_id: "",
        start_date: "",
        plan: "",
        capital: "",
        charges: "",
        invoice: "",
        expiry: "",
        acc_creation_date: FormatDateTime(client.creationDate),
        updated_at: FormatDateTime(client.updatedAt),
      };
    });
  };

  const clientDataWithSubscriptions = flattenClientsData();

  const clearSearchInput = () => {
    setSearchQuery("");
  };

  const [showClientListUploadModal, setShowClientListUploadModal] =
    useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [loadingSampleCsv, setLoadingSampleCsv] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const downloadCSV = () => {
    setLoadingSampleCsv(true);

    setTimeout(() => {
      const csvContent = `client_name,email,phone,location,telegram,kyc,\nTest User,johndoe@example.com,7276689226,Delhi,@telegramId,DZPPR5601C`;
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "sampleClient.csv";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      setLoadingSampleCsv(false);
    }, 2000); // Simulate a 2-second delay for download
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!csvFile) {
      console.error("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("csv", csvFile);
    formData.append("userId", userId);

    // console.log("Form data ", csvFile);
    // console.log("Form data after file upload", formData);

    try {
      const response = await axios.post(
        `${server.server.baseUrl}api/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);

        setShowFileUploadModal(false);
        setShowClientListUploadModal(false);
        setCsvFile(null);
        await getAllGroupsData(userId);
        await getAllClientsData(userId);
        toast.success("Client List Added Successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error("Error Adding Client List !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      setLoading(false);
      setShowFileUploadModal(false);
      setShowClientListUploadModal(false);
      setCsvFile(null);
      getAllClientsData(userId);
    }

    setLoading(false);
  };

  const handleDivClickFileUpload = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setCsvFile(selectedFile);
    }
  };

  const cancelUpload = () => {
    setUploading(false);
    setCsvFile(null);
  };

  return (
    <AdminDashboardLayout>
      <div className="max-h-screen w-full items-center justify-center bg-[#ffffff]">
        <Toaster position="top-center" reverseOrder={true} />
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between sm:h-[50px]">
          <div className="grid grid-cols-2 w-full sm:w-auto font-poppins px-1">
            <button
              className={`
                  ${
                    selectedTab === "client-list"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                px-2 py-2 text-sm sm:text-base lg:px-8 lg:min-w-[120px] justify-center `}
              onClick={() => {
                setSelectedTab("client-list");
              }}
            >
              Client List
            </button>
            <button
              className={`
                  ${
                    selectedTab === "group-list"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                px-2 py-2 text-sm sm:text-base lg:px-8 lg:min-w-[120px] justify-center`}
              onClick={() => {
                setSelectedTab("group-list");
              }}
            >
              Group List
            </button>
          </div>

          <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-end sm:space-x-3 lg:px-8 w-full sm:w-auto">
            <div className="flex items-center space-x-2 px-4 sm:px-2 w-[90%] sm:w-[260px] mx-auto sm:mx-0 border-b-[1px] border-[#000000]/10 bg-transparent">
              {" "}
              <SearchIcon className="w-5 h-5 text-[#000000]/50" />
              <input
                placeholder={`Search By "Client Email"`}
                className="w-full bg-transparent py-3 px-4 rounded-md text-[#000000] text-[14px] font-poppins placeholder:text-[#000000]/50"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery?.length > 1 && (
                <CircleXIcon
                  onClick={clearSearchInput}
                  className="w-4 h-4 text-[#000000]/50"
                />
              )}
            </div>

            <div className="flex justify-center space-x-2 w-full sm:w-auto pb-4 sm:pb-0 px-4 sm:px-0">
              <button
                onClick={() => setShowClientListUploadModal(true)}
                className="flex items-center justify-center w-auto sm:w-auto disabled:bg-[#000000]/20 text-center bg-white border-[1px] border-[#000000]/30 disabled:cursor-not-allowed text-[#000000] text-[12px] sm:text-[14px] font-medium font-poppins rounded-[4px] px-2 sm:px-4 py-1 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear whitespace-nowrap"
              >
                {" "}
                <img src={Excel} alt="Excel Icon" width={20} className="mr-2" />
                Upload List
              </button>

              <CSVLink
                data={clientDataWithSubscriptions}
                headers={csvHeaders}
                filename={`client_data_list_${moment().format(
                  "YYYYMMDD_HHmmss"
                )}.csv`}
                className=""
              >
                <button className="flex items-center justify-center w-full disabled:bg-[#000000]/20 text-center bg-white border-[1px] border-[#000000]/30 disabled:cursor-not-allowed text-[#000000] text-[12px] sm:text-[14px] font-medium font-poppins rounded-[4px] px-2 sm:px-4 py-1 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear">
                  <img
                    src={Excel}
                    alt="Excel Icon"
                    width={20}
                    className="mr-2"
                  />
                  Download List
                </button>
              </CSVLink>
            </div>
          </div>
        </div>

        {selectedTab === "client-list" && (
          <>
            {isClientDataLoading && allClientsData.length === 0 ? (
              <div className="flex  items-center justify-center h-full w-full">
                          <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  
                   </div>
            ) : (
              <ClientListNewTable
                userId={userId}
                setIsDataFetching={setIsDataFetching}
                allClientsData={allClientsData}
                allGroupsData={allGroupsData}
                searchQuery={searchQuery}
                getAllClientsData={getAllClientsData}
              />
            )}
          </>
        )}
        {selectedTab === "group-list" && (
          <>
            {isGroupDataLoading && allGroupsData.length === 0 ? (
              <div className="flex  items-center justify-center h-full w-full">
                <LoadingSpinner />
              </div>
            ) : (
              <GroupListTable
                userId={userId}
                allClientsData={allClientsData}
                allGroupsData={allGroupsData}
                getAllGroupsData={getAllGroupsData}
                getAllClientsData={getAllClientsData}
                searchQuery={searchQuery}
                openAddGroupModal={openAddGroupModal}
              />
            )}
          </>
        )}

        {addGroupModal && (
          <div className="fixed inset-0 flex items-center font-poppins justify-center bg-black bg-opacity-50 z-50">
            <div className="relative">
              <div
                className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
                onClick={closeAddGroupModal}
              >
                <XIcon className="w-6 h-6 mr-2" />
              </div>
              <div className="bg-white p-6 rounded-lg w-[500px]">
                <h3 className="text-lg font-semibold mb-4">Add New Group </h3>
                <div className="grid grid-cols-1 gap-4 font-poppins">
                  <label className="flex flex-col">
                    <input
                      type="text"
                      name="newGroupName"
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      className="p-2 border rounded"
                    />
                  </label>
                </div>
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                    onClick={closeAddGroupModal}
                    className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-4 py-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddGroupName}
                    className="bg-[#1D1D1D] text-white text-[18px] font-medium font-poppins rounded-md px-4 py-2 hover:scale-[1.04] transition-all duration-150 ease-linear"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showClientListUploadModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative">
              <div
                className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
                onClick={() => setShowClientListUploadModal(false)}
              >
                <XIcon className="w-6 h-6 mr-2" />
              </div>
              <div className="bg-white  rounded-lg w-[650px] px-8 py-8">
                <div className="w-full flex flex-row justify-center">
                  <img src={Excel} alt="Excel" className="" />
                </div>
                <div className="mt-6 text-[18px] font-normal font-poppins text-center md:px-12">
                  If you have the template, upload the CSV directly. Otherwise,
                  download the sample file, fill out the template, and upload it
                  here.
                </div>
                <div className="flex flex-row gap-2 flex-wrap justify-center mt-8">
                  <div
                    className="bg-black text-white text-sm sm:text-[18px] font-medium font-poppins rounded-md px-4 sm:px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                    onClick={() => setShowFileUploadModal(true)}
                  >
                    Upload CSV
                  </div>
                  <div
                    className="ml-4 text-black text-sm sm:text-[18px] font-medium font-poppins border-2 border-[#000000]/20 rounded-md px-4 sm:px-10 py-3 cursor-pointer hover:scale-[1.04] hover:shadow-md  transition-all duration-150 ease-linear"
                    onClick={downloadCSV}
                  >
                    {" "}
                    {loadingSampleCsv
                      ? "Downloading..."
                      : "Download Sample CSV"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showFileUploadModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative bg-[#ffffff] w-full md:w-[35%] rounded-[10px] px-6 md:px-12 py-10 m-2 animate-modal">
              <div
                className="absolute right-2 top-4  text-[#000000]/50 font-bold hover:text-[#ff0000] cursor-pointer "
                onClick={() => setShowFileUploadModal(false)}
              >
                <XIcon className="w-6 h-6 mr-2" />
              </div>

              <div className=" text-black font-semibold font-poppins text-center text-[22px]">
                Upload CSV
              </div>

              <div className="flex flex-row justify-center text-black text-xl text-center mt-8 mb-3">
                <div
                  onClick={handleDivClickFileUpload}
                  className="border-2 border-dashed border-[#000000]/50 py-6 px-10 w-full cursor-pointer rounded-lg"
                >
                  <input
                    id="fileInput"
                    type="file"
                    accept=".csv"
                    // className="text-black font-medium w-full  file:mr-6 file:py-2 file:px-4 file:bg-[#000000]/80 file:font-medium file:border-none file:rounded-lg file:text-white hover:file:bg-[#000000]/90 file:cursor-pointer cursor-pointer"
                    className="hidden"
                    // onChange={(e) => setCsvFile(e.target.files[0])}
                    onChange={(e) => handleFileChange(e)}
                  />
                  <div className="flex flex-col items-center">
                    <CloudUploadIcon className="text-[#000000]/80 w-10 h-10" />
                    <p className="text-black font-medium font-poppins text-[16px]">
                      Click to Upload File
                    </p>
                  </div>
                </div>
              </div>
              {csvFile && (
                <div className="mt-4 p-4 border rounded-lg flex justify-between items-center">
                  <div>
                    <p className="font-medium">{csvFile.name}</p>
                  </div>
                  <button onClick={cancelUpload} className="text-red-500">
                    <XIcon size={24} />
                  </button>
                </div>
              )}

              <button
                className="w-full mt-8 text-center bg-[#000000] text-[#ffffff] disabled:bg-[#000000]/20 disabled:cursor-not-allowed  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                onClick={handleFileUpload}
                disabled={!csvFile || uploading}
              >
                {loading === true ? (
                  <span className="flex flex-row justify-center">
                    <svg
                      className="h-10 w-10 text-[#ffffff] animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                ) : (
                  <span className="text-lg font-semibold font-poppins">
                    Upload File
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </AdminDashboardLayout>
  );
};

export default GroupClientListSection;
