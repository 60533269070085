import React, { useState } from "react";
import { Link } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import Logo from "./assests/Logo.jpg";
import { auth } from "../firebase";

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const Navbar = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50  shadow bg-[#0F0F0F] ">
      <div className="container px-4 sm:px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href="/">
              <img
                className="w-auto h-6 sm:h-8 rounded-md"
                src={Logo}
                alt="Logo"
              />
            </a>
            <a
              href="/"
              className="font-montserrat text-white text-sm sm:text-xl ml-2"
            >
              {whiteLabelText}
            </a>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={toggleMenu}
              className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
              aria-label="toggle menu"
            >
              {!isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 8h16M4 16h16"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div
          className={`absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-black md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div
            className={`absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-black md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col md:flex-row md:mx-6">
              <Link
                to="modelPortfolio"
                smooth={true}
                duration={500}
                className="font-Poppins my-2   text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 md:mx-2 md:my-0"
              >
                {/* Model Portfolios */}
              </Link>
              <Link
                to="subscriptionSection"
                smooth={true}
                duration={800}
                className="font-Poppins my-2 text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 md:mx-2 md:my-0"
              >
                Plans
              </Link>
              <Link
                to="aboutSection"
                smooth={true}
                duration={1000}
                className="font-Poppins my-2 text-white cursor-pointer  transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 md:mx-2 md:my-0"
              >
                About Advisor
              </Link>
              <Link
                to="faqSection"
                smooth={true}
                duration={1000}
                className="font-Poppins my-2 cursor-pointer text-white  transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 md:mx-2 md:my-0"
              >
                FAQ
              </Link>
              <div className="flex justify-center lg:pl-5 md:block">
                <Link
                  className="relative text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"
                  href="/user"
                >
                  <button
                    className="px-4 py-2 text-sm font-poppins rounded-md font-medium tracking-wide text-black capitalize transform bg-white transition-all duration-200 ease-in-out "
                    onClick={handleClick}
                  >
                    {userEmail ? "Go to Dashboard" : "Login/Register"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
