import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon, Plus, AlertCircle, AlertTriangleIcon } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { debounce } from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { Smile } from "lucide-react";
import CommentModal from "./CommentModal";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full py-1.5 px-2 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9]",

  selectDiv:
    "flex flex-row justify-between items-center mx-2 px-2 py-2 rounded-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-black transition ease-in duration-200 cursor-pointer",
  inputStartDiv: " relative w-full   ",
};
const SendAdviceModel = ({
  fileName,
  closeSendAdviceModal,
  data,
  adminEmail,
  advisorName,
  email_password,
  setSendAdviceModal,
  setSelectedRows,
  adminRationale,
  advisorSpecifier,
}) => {
  const showQuantityColumn = advisorSpecifier === "RIA";

  const [loading, setLoading] = useState(false);
  const [adviceEntries, setAdviceEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale: adminRationale
        ? adminRationale
        : "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale:
        adminRationale ||
        "This recommendation is based on comprehensive analysis...",
    };

    setAdviceEntries([...adviceEntries, newEntry]);
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
  };

  const numericFields = ["price", "anotherNumericField"]; // Add all numeric field names here

  const handleAdviceChange = (index, field, value) => {
    let formattedValue = value;

    // Format value if the field is numeric
    if (numericFields?.includes(field)) {
      // Allow only valid numbers with up to two decimal places
      if (value) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart && decimalPart.length > 2) {
          formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        } else {
          formattedValue = value;
        }
      }
    }

    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: formattedValue } : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const isValid = adviceEntries.every((entry) => {
    const isPriceRequired =
      entry.orderType === "LIMIT" || entry.orderType === "STOP";
    return (
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.segment &&
      entry.type &&
      entry.rationale && // Add rationale validation
      (!isPriceRequired || entry.price)
    );
  });

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    }
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(adviceEntries);

  const handleRationaleChange = (index, rationaleMsg) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, rationale: rationaleMsg } : entry
      )
    );
  };

  const handleCommentsChange = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comments: commentsMsg } : entry
        )
      );
    } else {
      // Optionally, you could handle this case (e.g., showing a message or preventing further input)

      toast.error("Comment exceeds the maximum length of 256 characters", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleComments2Change = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comment2: commentsMsg } : entry
        )
      );
    } else {
      toast.error(
        "Extended comment exceeds the maximum length of 256 characters",
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  // const isValid = adviceEntries.every((entry) => {
  //   const isPriceRequired =
  //       entry.orderType === "LIMIT" || entry.orderType === "STOP";
  //
  //   return (
  //       entry.symbol &&
  //       entry.exchange &&
  //       entry.orderType &&
  //       entry.productType &&
  //       entry.segment &&
  //       entry.type &&
  //       entry.rationale &&
  //       (!isPriceRequired || entry.price) // Only require price if orderType is Limit or Stop
  //   );
  // });

  const todaydate = new Date();

  const handleSendAdvice = () => {
    setLoading(true);

    const adviceData = data.flatMap((user) =>
      adviceEntries.map((entry) => {
        // Format country code - ensure it starts with '+'
        let formattedCountryCode = String(user?.country_code || '+91').trim();
        formattedCountryCode = formattedCountryCode.charAt(0) === '+'
          ? formattedCountryCode
          : '+' + formattedCountryCode;

        return {
          email: user.email,
          userName: user?.clientName,
          phoneNumber: user?.phone,
          country_code: formattedCountryCode, // Use formatted country code
          telegramId: user?.telegram,
          trade_given_by: adminEmail,
          email_password: email_password,
          trade_given_by_fileName: fileName,
          advisor_name: advisorName,
          Symbol: entry.symbol,
          Exchange: entry.exchange,
          Type: entry.type,
          OrderType: entry.orderType,
          ProductType: entry.productType,
          Segment: entry.segment,
          Price: entry.orderType === "MARKET" ? 0 : entry.price,
          date: moment(todaydate).format(),
          Quantity: entry.quantity,
          Advised_Range_Lower: entry.adviceLower,
          Advised_Range_Higher: entry.adviceHigher,
          rationale: entry?.rationale,
          comments: entry?.comments,
          comment2: entry?.comment2,
          advisorType: advisorSpecifier,
          price_when_send_advice: getLTPForSymbol(entry?.symbol),
        };
      })
    );
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        // processClientsAndSendNotifications();
        toast.success("Advice has been sent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setTimeout(() => {
          setAdviceEntries([
            {
              symbol: "",
              exchange: "NSE",
              orderType: "MARKET",
              productType: "DELIVERY",
              quantity: 1,
              segment: "EQUITY",
              price: 0,
              type: "",
              adviceLower: 0,
              adviceHigher: 0,
              inputValue: "",
              symbols: [],
            },
          ]);
          setSendAdviceModal(false);
          setSelectedRows([]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Advice !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const sendNotifications = async (fcmTokens, notificationData) => {
    console.log("Notification Data:", notificationData[0]); // Log notificationData
    for (let token of fcmTokens) {
      try {
        const response = await fetch(
          `${server.server.baseUrl}api/notifications/send`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fcmToken: token,
              title: notificationData[0].Symbol, // Customizing the title
              body: "stock Advice", // Customizing the body
              notificationType: notificationData[0].notificationType, // Including notification type
              Symbol: notificationData[0].Symbol, // Including symbol
              Type: notificationData[0].Type, // Including types
              Price: notificationData[0].Price, // Including price
            }),
          }
        );

        const result = await response.json();
        console.log(`Notification sent to ${token}:`, result);
      } catch (error) {
        console.error(`Error sending notification to ${token}:`, error);
      }
    }
  };

  // Main function to process client data and send notifications

  const fetchUserDetailsByEmail = async (email) => {
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/user/getUser/${email}`
      );
      console.log("response:", response.data);
      return response.data; // Assuming the user data is returned in the response
    } catch (error) {
      console.error(
        `Error fetching user details for ${email}:`,
        error.response.data || error.message
      );
      return null; // Return null or an appropriate value if the user is not found
    }
  };

  const processClientsAndSendNotifications = async (notificationData) => {
    let fcmTokens = [];
    // Iterate through each user in the data array
    for (const user of data) {
      const { email } = user; // Destructure to get the email
      console.log("user:", email);
      if (!email) {
        console.warn("User does not have an email:", user);
        continue; // Skip this user if there is no email
      }

      try {
        const userDetails = await fetchUserDetailsByEmail(email); // Fetch user details by email
        console.log("userDetails:", userDetails);
        if (userDetails && userDetails.User.fcm_token) {
          console.log("FCM token:", userDetails.User.fcm_token);
          fcmTokens.push(userDetails.User.fcm_token); // Collect valid FCM tokens
        } else {
          console.warn(`No valid FCM token found for user: ${email}`);
        }
      } catch (error) {
        console.error(`Error fetching user details for ${email}:, error`);
      }
    }

    // Check if we have any tokens to send notifications
    if (fcmTokens.length > 0) {
      await sendNotifications(fcmTokens, notificationData); // Pass fcmTokens and notificationData to sendNotifications
    } else {
      console.log("No valid FCM tokens found for sending notifications.");
    }
  };
  const formatToTwoDecimals = (value) => {
    // Remove any non-digit and non-decimal point characters
    let formatted = value.replace(/[^\d.]/g, "");

    // Ensure only one decimal point
    const parts = formatted.split(".");
    if (parts.length > 2) {
      formatted = parts[0] + "." + parts.slice(1).join("");
    }

    // Limit to two decimal places
    if (parts.length > 1) {
      formatted = parts[0] + "." + parts[1].slice(0, 2);
    }

    return formatted;
  };
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEmojiPicker2, setShowEmojiPicker2] = useState(false);
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const emojiPickerRef = useRef(null);

  const toggleEmojiPicker = (index, type) => {
    if (
      activeEmojiPicker &&
      (activeEmojiPicker.index !== index || activeEmojiPicker.type !== type)
    ) {
      setActiveEmojiPicker(null);
    } else {
      setActiveEmojiPicker(activeEmojiPicker ? null : { index, type });
    }
  };

  // const handleEmojiClick = (emojiObject, index, type) => {
  //   const updatedEntries = [...adviceEntries]
  //   if (type === 'comment') {
  //     updatedEntries[index].comments += emojiObject.emoji
  //   } else if (type === 'comment2') {
  //     updatedEntries[index].comment2 += emojiObject.emoji
  //   }
  //   setAdviceEntries(updatedEntries)
  // }
  const handleEmojiClick = (emojiObject, index, type) => {
    const updatedEntries = [...adviceEntries];
    if (type === "comment") {
      updatedEntries[index].comments += emojiObject.emoji;
    } else if (type === "comment2") {
      updatedEntries[index].comment2 += emojiObject.emoji;
    }
    setAdviceEntries(updatedEntries);
    // Close the emoji picker after selecting an emoji
    setActiveEmojiPicker(null);
  };

  const handleEmojiPickerDoubleClick = () => {
    setActiveEmojiPicker(null);
  };

  const handleClickOutsideEmoji = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest("button")
    ) {
      setActiveEmojiPicker(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideEmoji);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEmoji);
    };
  }, []);

  useEffect(() => {
    const handleDoubleClick = () => {
      setActiveEmojiPicker(null);
    };

    document.addEventListener("dblclick", handleDoubleClick);
    return () => {
      document.removeEventListener("dblclick", handleDoubleClick);
    };
  }, []);

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutsideEmoji)
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutsideEmoji)
  //   }
  // }, [])

  const [activeCommentModal, setActiveCommentModal] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 backdrop-blur-sm"
        onClick={(e) => e.target === e.currentTarget && closeSendAdviceModal()}
      >
        <motion.div
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.95 }}
          className="relative w-full max-w-[87rem] max-h-[90vh] bg-white rounded-xl shadow-xl overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-md border-b border-gray-200">
            <div className="px-6 py-4 flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Send Advice to {fileName?.replaceAll(/_/g, " ")}
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Configure and send advice to {data.length} selected client
                  {data.length !== 1 ? "s" : ""}
                </p>
              </div>
              <button
                onClick={closeSendAdviceModal}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              >
                <XIcon className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* Table Section */}
          {/* Table Section */}
          <div
            className="overflow-auto custom-scroll px-6 py-4"
            style={{ maxHeight: "calc(92vh - 180px)" }}
          >
            <div className="mb-4 flex flex-row justify-between items-center space-y-2 sm:space-y-0">
              <button
                onClick={handleAddAdviceEntry}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
              >
                <Plus className="w-4 h-4 mr-2" />
                Add Entry
              </button>
              <span className="text-sm text-gray-500">
                {adviceEntries.length} entr
                {adviceEntries.length === 1 ? "y" : "ies"}
              </span>
            </div>

            <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
              <table className="min-w-full divide-y divide-gray-200">
                <colgroup>
                  <col className="w-[250px]" /> {/* Symbol - increased width */}
                  <col className="w-[150px]" /> {/* Order Type */}
                  <col className="w-[150px]" /> {/* Product Type */}
                  <col className="w-[120px]" /> {/* Segment */}
                  <col className="w-[180px]" /> {/* Type - increased width */}
                  {showQuantityColumn && <col className="w-[100px]" />}{" "}
                  {/* Quantity */}
                  <col className="w-[100px]" /> {/* LTP */}
                  <col className="w-[150px]" /> {/* Price */}
                  <col className="w-[200px]" /> {/* Advised Range */}
                  <col className="w-[200px]" /> {/* Comments */}
                  <col className="w-[300px]" /> {/* Rationale */}
                  <col className="w-[80px]" /> {/* Actions */}
                </colgroup>

                <thead className="bg-gray-50">
                  <tr className="h-16">
                    {" "}
                    {/* Increased header height */}
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Symbol <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Order Type <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Product Type <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Segment <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type <span className="text-red-500">*</span>
                    </th>
                    {showQuantityColumn && (
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quantity
                      </th>
                    )}
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      LTP
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Price <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Advised Range
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rationale <span className="text-red-500">*</span>
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Comments
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Extended Comment
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {adviceEntries.map((entry, index) => (
                    <tr
                      key={entry.id}
                      className="hover:bg-gray-50 transition-colors min-h-[120px]"
                    >
                      {" "}
                      {/* Increased row height */}
                      {/* Symbol */}
                      <td className="px-4 py-4">
                        {" "}
                        {/* Increased cell padding */}
                        <div className="relative">
                          <input
                            type="text"
                            value={entry.inputValue}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            className="w-full min-w-[220px] px-3 py-2.5 border border-gray-300 rounded-md"
                            placeholder="Enter symbol"
                          />
                          {entry.symbols.length > 0 && isOpen && (
                            <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                              {entry.symbols.map((symbol, idx) => (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    handleSymbolSelect(
                                      index,
                                      symbol.symbol,
                                      symbol.segment
                                    )
                                  }
                                  className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                                >
                                  {symbol.symbol} - {symbol.segment}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </td>
                      {/* Order Type */}
                      <td className="px-4 py-4">
                        <select
                          value={entry.orderType}
                          onChange={(e) =>
                            handleAdviceChange(
                              index,
                              "orderType",
                              e.target.value
                            )
                          }
                          className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                        >
                          {["MARKET", "LIMIT", "STOP"].map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* Product Type */}
                      <td className="px-4 py-4">
                        <select
                          value={entry.productType}
                          onChange={(e) =>
                            handleAdviceChange(
                              index,
                              "productType",
                              e.target.value
                            )
                          }
                          className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                        >
                          {["DELIVERY", "INTRADAY"].map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* Segment */}
                      <td className="px-4 py-4">
                        <select
                          value={entry.segment}
                          onChange={(e) =>
                            handleAdviceChange(index, "segment", e.target.value)
                          }
                          className="w-full min-w-[120px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                        >
                          <option value="EQUITY">EQUITY</option>
                        </select>
                      </td>
                      {/* Type */}
                      <td className="px-4 py-4">
                        <select
                          value={entry.type}
                          onChange={(e) =>
                            handleAdviceChange(index, "type", e.target.value)
                          }
                          className="w-full min-w-[150px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                        >
                          <option value="">Select Type</option>
                          {["BUY", "SELL"].map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* Quantity */}
                      {showQuantityColumn && (
                        <td className="px-4 py-4">
                          <input
                            type="number"
                            value={entry.quantity}
                            onChange={(e) =>
                              handleAdviceChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                            className="w-full  font-poppins text-basepx-3 py-2.5 border border-gray-300 rounded-md"
                            min="1"
                          />
                        </td>
                      )}
                      {/* LTP */}
                      <td className="px-4 py-4">
                        <div className="text-right">
                          {entry.symbol && getLTPForSymbol(entry.symbol)}
                        </div>
                      </td>
                      {/* Price */}
                      <td className="px-4 py-4 min-w-[150px]">
                        {" "}
                        {/* Added minimum width to td */}
                        {entry.orderType === "MARKET" ? (
                          <div className="text-right w-full min-w-[10px]">
                            {entry.symbol && getLTPForSymbol(entry.symbol)}
                          </div>
                        ) : (
                          <input
                            type="number"
                            value={entry.price || ""}
                            onChange={(e) =>
                              handleAdviceChange(index, "price", e.target.value)
                            }
                            className="w-full min-w-[10px] px-3 py-2.5 border border-gray-300 rounded-md"
                            step="0.01"
                          />
                        )}
                      </td>
                      {/* Advised Range */}
                      <td className="px-4 py-4">
                        <div className="flex space-x-2">
                          <input
                            type="number"
                            value={entry.adviceLower || ""}
                            onChange={(e) =>
                              handleAdviceChange(
                                index,
                                "adviceLower",
                                e.target.value
                              )
                            }
                            className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                            placeholder="Low"
                          />
                          <span className="self-center">-</span>
                          <input
                            type="number"
                            value={entry.adviceHigher || ""}
                            onChange={(e) =>
                              handleAdviceChange(
                                index,
                                "adviceHigher",
                                e.target.value
                              )
                            }
                            className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                            placeholder="High"
                          />
                        </div>
                      </td>
                      {/* Rationale */}
                      <td className="px-4 py-4">
                        <textarea
                          value={entry.rationale}
                          onChange={(e) =>
                            handleRationaleChange(index, e.target.value)
                          }
                          className="w-full min-w-[280px] font-poppins px-3 py-2.5 border border-gray-300 rounded-md"
                          rows="3"
                          placeholder="Enter rationale for this trade..."
                        />
                      </td>
                      {/* Comments */}
                      <td className="relative px-4 py-4">
                        <div className="relative w-full min-w-[250px]">
                          <textarea
                            value={entry.comments}
                            readOnly
                            placeholder="Click to edit comment"
                            onClick={(e) => {
                              const rect = e.target.getBoundingClientRect();
                              setModalPosition({
                                top: rect.top + window.scrollY,
                                left: rect.left + window.scrollX,
                              });
                              setActiveCommentModal({
                                index,
                                type: "comments",
                              });
                            }}
                            className="w-full px-3 py-2.5 border border-gray-300 rounded-md pr-10"
                            rows={3}
                          />

                          <CommentModal
                            isOpen={
                              activeCommentModal?.index === index &&
                              activeCommentModal?.type === "comments"
                            }
                            onClose={() => setActiveCommentModal(null)}
                            initialValue={entry.comments}
                            onSave={(value) =>
                              handleCommentsChange(index, value)
                            }
                            position={modalPosition}
                            maxLength={256}
                          />
                        </div>
                      </td>
                      {/* Comments2 */}
                      <td className="px-4 py-4">
                        <div className="relative w-full min-w-[250px]">
                          <textarea
                            value={entry.comment2}
                            readOnly
                            placeholder="Click to edit extended comment"
                            onClick={(e) => {
                              const rect = e.target.getBoundingClientRect();
                              setModalPosition({
                                top: rect.top + window.scrollY,
                                left: rect.left + window.scrollX,
                              });
                              setActiveCommentModal({
                                index,
                                type: "comment2", // Changed this to differentiate from comments
                              });
                            }}
                            className="w-full min-w-[200px] px-3 py-2.5 border border-gray-300 rounded-md"
                            rows="3"
                          />

                          <CommentModal
                            isOpen={
                              activeCommentModal?.index === index &&
                              activeCommentModal?.type === "comment2" // Match with the type above
                            }
                            onClose={() => setActiveCommentModal(null)}
                            initialValue={entry.comment2}
                            onSave={(value) =>
                              handleComments2Change(index, value)
                            }
                            position={modalPosition}
                            maxLength={256}
                          />
                        </div>
                      </td>
                      {/* Actions */}
                      <td className="px-4 py-4">
                        {adviceEntries.length > 1 && (
                          <button
                            onClick={() => handleRemoveAdviceEntry(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <XIcon className="h-5 w-5" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {activeEmojiPicker && (
              <div
                ref={emojiPickerRef}
                className="absolute z-20"
                style={{
                  right:
                    activeEmojiPicker.type === "comment" ? "120px" : "395px",
                  bottom: "20px",
                }}
              >
                <EmojiPicker
                  onEmojiClick={(emojiObject) =>
                    handleEmojiClick(
                      emojiObject,
                      activeEmojiPicker.index,
                      activeEmojiPicker.type
                    )
                  }
                  width={280}
                  height={350}
                />
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="sticky bottom-0 z-10 bg-gray-50 px-6 py-4 border-t border-gray-200">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              {!isValid && (
                <div className="flex items-center text-red-500">
                  <AlertCircle className="w-4 h-4 mr-2" />
                  <span className="text-sm">
                    Please fill in all required fields
                  </span>
                </div>
              )}
              <div className="flex space-x-3">
                <button
                  onClick={closeSendAdviceModal}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendAdvice}
                  disabled={!isValid || loading}
                  className="inline-flex items-center px-4 py-2  text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <LoadingSpinner className="w-4 h-4 mr-2" />
                      Sending...
                    </span>
                  ) : (
                    "Send Advice"
                  )}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
export default SendAdviceModel;
