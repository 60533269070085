import React, { useState, useEffect } from "react";
import axios from "axios";

import { X, ChevronDown, ChevronUp } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import TelegramSteps from "../RootSection/TelegramSteps";
import server from "../../utils/serverConfig";
import { CountryCode } from "../../utils/CountryCode";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox: "w-full px-6 py-2.5 bg-white text-lg peer text-gray-900 font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat: "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full",
  selectDiv2: "flex items-center px-2 py-2 rounded-lg mx-3 md-3 text-black-500 hover:bg-black/10 hover:text-black-500 ease-linear duration-150 cursor-pointer",
};

const UpdateUserDetails = ({ uid, setUpdateUserDetails, userEmail, advisorName }) => {
  const showTelegram = process.env.REACT_APP_TELEGRAM === "1";
  const [phoneNumber, setPhoneNumber] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const calculateProfileCompletion = () => {
    let completed = 0;
    let total = showTelegram ? 4 : 3; // Email, name, phone, (telegram if required)

    if (userEmail) completed++;
    if (userName) completed++;
    if (phoneNumber) completed++;
    if (showTelegram && telegramId) completed++;

    return Math.round((completed / total) * 100);
  };

  const updateUser = async () => {
    if (phoneNumber.length !== 10) {
      toast.error("Phone number must be at least 10 numbers.", {
        duration: 5000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth:"500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: '#e43d3d',
          secondary: '#FFFAEE',
        },
      });
      return;
    }

    setLoading(true);
    try {
      const formattedPhone = phoneNumber.startsWith(countryCode)
        ? phoneNumber
        : countryCode + phoneNumber;

      const response = await axios.put(
        `${server.server.baseUrl}api/user/update-profile`,
        {
          email: userEmail,
          advisorName,
          phoneNumber: formattedPhone,
          countryCode,
          telegramId: showTelegram ? telegramId : "",
          userName,
          profileCompletion: calculateProfileCompletion()
        },
        {
          headers: { "Content-Type": "application/json" }
        }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          duration: 5000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: '#16a085',
            secondary: '#FFFAEE',
          },
        });
        setShowSuccessMsg(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showSuccessMsg) {
      const timer = setTimeout(() => {
        setShowSuccessMsg(false);
        setUpdateUserDetails(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMsg, setUpdateUserDetails]);

  const filteredCountryCodes = CountryCode.filter((ele) =>
    `${ele.value} ${ele.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-4 xl:px-0">
      <Toaster
        position="top-center"
        reverseOrder={true}
      />

      <div className="relative bg-white w-full md:w-[90%] h-[90%] overflow-auto lg:h-auto grid grid-cols-1 py-6 lg:grid-cols-2 rounded-lg lg:py-8">
        <div
          className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 right-0 md:right-6 m-1 font-bold hover:text-red-600 ease-linear duration-150 cursor-pointer"
          onClick={() => setUpdateUserDetails(false)}
        >
          <div className="hidden md:flex">Close</div>
          <div>
            <X size={25} />
          </div>
        </div>

        {showTelegram && (
          <div className="mt-4">
            <TelegramSteps />
          </div>
        )}

        <div className="mt-10 md:flex flex-col space-y-3 px-12">
          <div className="text-xl lg:text-3xl font-bold">
            Contact Information
          </div>

          <div className="flex md:flex-row flex-col items-center">
            <div className="w-full md:w-[40%] text-[14px] md:text-lg font-semibold">
              Name:
            </div>
            <div className={style.inputStartDiv}>
              <input
                type="text"
                placeholder="Enter your name"
                className={`${style.inputBox} text-[14px] md:text-lg placeholder:text-[14px] md:placeholder:text-lg`}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col items-center">
            <div className="w-full md:w-[40%] text-[14px] md:text-lg font-semibold">
              Phone Number:
            </div>
            <div className={`flex flex-row items-center ${style.inputStartDiv}`}>
              <div
                className={`mt-3 mr-2 w-[25%] text-[#000000]/80 flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-3`}
                onClick={() => setShowCountryCode(!showCountryCode)}
              >
                <div className="text-[#000000]/80">{countryCode}</div>
                <div className="pl-1 ease-linear duration-150 text-[#000000]/25">
                  {showCountryCode ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>

              {showCountryCode && (
                <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[rgba(185,192,187,0.7)] max-h-96 overflow-y-auto">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="mx-3 px-3 py-2 mb-2 border rounded-md"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredCountryCodes.length > 0 ? (
                    filteredCountryCodes.map((ele, index) => (
                      <div
                        key={index}
                        className={`pl-3 font-medium ${style.selectDiv2}`}
                        onClick={() => {
                          setCountryCode(ele.value);
                          setShowCountryCode(false);
                        }}
                      >
                        {ele.value} {ele.label}
                      </div>
                    ))
                  ) : (
                    <div className="pl-3 font-medium text-gray-500">
                      No results found
                    </div>
                  )}
                </div>
              )}

              <input
                type="number"
                placeholder="Enter your phone number"
                className={`${style.inputBox} text-[14px] md:text-lg placeholder:text-[14px] md:placeholder:text-lg`}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>

          {showTelegram && (
            <div className="flex md:flex-row flex-col items-center">
              <div className="w-full md:w-[40%] text-[14px] md:text-lg font-semibold">
                Telegram Username:
              </div>
              <div className={style.inputStartDiv}>
                <input
                  type="text"
                  placeholder="Enter your telegram Username"
                  className={`${style.inputBox} text-[14px] md:text-lg placeholder:text-[14px] md:placeholder:text-lg`}
                  value={telegramId}
                  onChange={(e) => setTelegramId(e.target.value)}
                />
              </div>
            </div>
          )}

          <div>
            <button
              className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
              onClick={updateUser}
              disabled={!phoneNumber || (showTelegram && !telegramId)}
            >
              {loading ? (
                <span className="flex flex-row justify-center">
                  <svg
                    className="h-7 w-7 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;