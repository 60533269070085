import React, { useEffect, useState } from "react";
import moment from "moment";
import { BanIcon, CheckIcon, TimerIcon, XIcon } from "lucide-react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"

const ShowAdviceDataTable = ({
  selectedTab,
  allTrade,
  filteredAllAdviceData,
  // loading,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full max-h-[calc(100vh-225px)] lg:h-[calc(100vh-215px)] overflow-auto custom-scroll-2   mb-4">
      {loading ? (
        <span className="h-full md:p-12 p-4 text-2xl font-semibold pointer-events-auto w-full flex justify-center items-center">
  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  
                          </span>
      ) : allTrade?.length !== 0 ? (
        <>
          {filteredAllAdviceData.length !== 0 ? (

           <AnimatePresence mode="wait">
      <motion.table
        initial={{ opacity: 0 }}
        animate={{ opacity: 1}}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}  className={` w-full border-collapse border-t-[0px]  `}>
              <thead className="bg-[#f5f5f5]  text-sm sticky top-0 border-t-[0px] border-b-[1px] border-[#000000]/10  ">
                <tr className="border-b-[1px]  border-[#000000]/10">
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium text-left py-3 px-5">
                    Email{" "}
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium text-left py-3 px-3">
                    Symbol{" "}
                  </th>
                  <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Type
                  </th>
                  <th className="text-[13px] min-w-[120px] border-r-[1px]   border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Product Type{" "}
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Qty{" "}
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Rem Qty{" "}
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Traded Qty{" "}
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Limit Price
                  </th>
                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Stop Price{" "}
                  </th>
                  <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                    Traded Price{" "}
                  </th>
                  {selectedTab === "all" && (
                    <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-3">
                      Status{" "}
                    </th>
                  )}

                  <th className="text-[13px]  border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-5">
                    Order Time
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredAllAdviceData
                  ?.sort((a, b) => moment(b.date) - moment(a.date))
                  ?.map((ele, i) => {
                    return (
                      <tr
                        key={i}
                        className="border-b-[1px]   border-[#000000]/10"
                      >
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-left py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.user_email}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-semibold text-left py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.Symbol}
                        </td>
                        <td
                          className={`text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-2 lg:py-4 px-2 lg:px-3 capitalize
                    ${
                      ele.Type.toLowerCase() === "buy"
                        ? "text-[#16A085]"
                        : ele.Type.toLowerCase() === "sell"
                        ? "text-[#E43D3D] "
                        : "text-[#000000]/80"
                    }
                   `}
                        >
                          {ele.Type?.toLowerCase()}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.ProductType}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.Quantity}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.Qty ? ele.Qty : "-"}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.tradedQty ? ele.tradedQty : "-"}{" "}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.limitPrice ? ele.limitPrice : "-"}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.stopPrice ? ele.stopPrice : "-"}
                        </td>
                        <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-2 lg:py-4 px-2 lg:px-3 ">
                          {ele.tradedPrice ? ele.tradedPrice : "-"}
                        </td>
                        {selectedTab === "all" && (
                          <td className="text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-2 lg:py-4 px-2 lg:px-3 ">
                            <span
                              className={`w-[130px] mx-auto flex items-center justify-center px-2 py-1 capitalize font-medium rounded-md 
                            ${
                              ele.trade_place_status?.toLowerCase() ===
                              "complete"
                                ? "text-[#16A085] bg-[#69D4441A]"
                                : ele.trade_place_status?.toLowerCase() ===
                                  "rejected"
                                ? "text-[#E43D3D] bg-[#E43D3D1A]"
                                : ele.trade_place_status?.toLowerCase() ===
                                  "ignored"
                                ? "text-[#848080] bg-[#A5A3A31A]"
                                : ele.trade_place_status?.toLowerCase() ===
                                  "recommend"
                                ? "text-[#D49244] bg-[#F19C371A]"
                                : ""
                            }`}
                            >
                              {ele?.trade_place_status?.toLowerCase() ===
                              "complete" ? (
                                <CheckIcon className="text-[#16A085] w-4 h-4 mr-1.5" />
                              ) : ele?.trade_place_status?.toLowerCase() ===
                                "recommend" ? (
                                <TimerIcon className="text-[#D49244] w-4 h-4 mr-1.5" />
                              ) : ele?.trade_place_status?.toLowerCase() ===
                                "ignored" ? (
                                <BanIcon className="text-[#848080] w-4 h-4 mr-1.5" />
                              ) : ele?.trade_place_status?.toLowerCase() ===
                                "rejected" ? (
                                <XIcon className="text-[#E43D3D] w-4 h-4 mr-1.5" />
                              ) : (
                                ""
                              )}
                              {ele.trade_place_status
                                ? ele.trade_place_status
                                : "-"}
                            </span>
                          </td>
                        )}

                        <td className="text-[14px]  border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-2 lg:py-4 px-2 lg:px-3 text-nowrap">
                          {moment(ele.date).format("DD MMM YYYY h:mm A")}{" "}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              </motion.table>
              </AnimatePresence>           
              
            ) : (
            <div className="h-full text-2xl font-semibold  w-full flex flex-col justify-center items-center">
              <div className="text-xl lg:text-3xl font-semibold font-sans flex items-center justify-center">
                No Records Found
              </div>
              <p className="text-[14px] lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
                No matching records were found. Try adjusting your filters or
                check back later.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="h-full text-2xl font-semibold  w-full flex flex-col justify-center items-center">
          <div className="text-xl lg:text-3xl font-semibold font-sans flex items-center justify-center">
            No Recommended Stocks
          </div>

          <p className="text-[14px] lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
            You haven't recommended any stocks yet. Explore and send stock
            recommendations to populate this list.
          </p>

          <Link
            to="/admin"
            className="mt-4 py-2 px-6 bg-black text-[18px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
          >
            Send Recommendations
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShowAdviceDataTable;
