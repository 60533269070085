import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Pie, PieChart } from "recharts";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  CandlestickChartIcon,
  Share2Icon,
  TimerIcon,
  TrendingUpIcon,
} from "lucide-react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";
import RebalanceTimeLineModal from "../../AdminDashboard/StrategyDetailsPageSection/RebalanceTimeLineModal";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import UserStrategySubscribeModal from "./UserStrategySubscribeModal";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumbs";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import { fetchFunds } from "../../FunctionCall/fetchFunds";
import PlanSubscribeModal from "../PricingSection/PlanSubscribeModal";
import PaymentSuccessModal from "../PricingSection/PaymentSuccessModal";
import FormatDateTime from "../../utils/formatDateTime";

const style = {
  selected:
    "flex items-center text-[16px] md:text-[18px] font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px] font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5 bg-white text-[18px] peer text-gray-900 placeholder-transparent font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat:
    "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900 hover:bg-[#D9D9D9] hover:text-gray-100 transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
};

const StrategyDetails = () => {
  const { fileName } = useParams();
  const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
  const [user] = useAuthState(auth);
  const userEmail = user?.email;

  // State declarations
  const [userDetails, setUserDetails] = useState(null);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [selectedDataTab, setSelectedDataTab] = useState("distribution");
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const [broker, setBroker] = useState("");
  const [brokerModel, setBrokerModel] = useState(false);
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [specificPlan, setSpecificPlan] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [invetAmount, setInvestAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [funds, setFunds] = useState({});

  // Extract user details after state is set
  const userName = userDetails?.name;
  const clientCode = userDetails?.clientCode;
  const apiKey = userDetails?.apiKey;
  const jwtToken = userDetails?.jwtToken;
  const my2pin = userDetails?.my2Pin;
  const secretKey = userDetails?.secretKey;
  const userId = userDetails?._id;
  const viewToken = userDetails?.viewToken;
  const sid = userDetails?.sid;
  const serverId = userDetails?.serverId;
  const mobileNumber = userDetails?.phone_number;
  const panNumber = userDetails?.panNumber;
  const dateString = userDetails?.token_expire;
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");
  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");

  // 4. ADD THE FUNCTIONS HERE - AFTER STATE DECLARATIONS, BEFORE USEEFFECTS
  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  async function subscribeToPlan(frequency, sip_amount) {
    try {
      setLoading(true);
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription`,
        {
          plan_id: planDetails._id,
          frequency,
          user_email: userEmail,
          sip_amount,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const subscriptionData = response.data.data;

      if (subscriptionData.razorpay_subscription_id) {
        const options = {
          key: razorPayKey,
          subscription_id: subscriptionData.razorpay_subscription_id,
          name: subscriptionData.plan_id.name,
          description: subscriptionData.plan_id.description,
          amount: subscriptionData.amount,
          currency: "INR",
          handler: function (response) {
            console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            console.log(
              "Razorpay Subscription ID:",
              response.razorpay_subscription_id
            );
            console.log("Razorpay Signature:", response.razorpay_signature);

            if (userId) {
              completeSubscription(response);
            }
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "",
            email: userEmail,
          },
          theme: {
            color: "#F37254",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching subscription data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error subscribing to plan:", error);
      setLoading(true);
    }
  }

  async function completeSubscription(paymentDetails) {
    try {
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/complete-payment`,
        paymentDetails
      );

      const data = response.data;

      setPaymentSuccess(true);
      setRefresh((prev) => !prev);

      const newSubscription = {
        subId: uuidv4().slice(0, 10),
        startDate: FormatDateTime(new Date()),
        plan: fileName || "",
        capital: data.subscription.capital || 0,
        charges: data.subscription.amount || 0,
        invoice: data.subscription.razorpay_subscription_id || "",
        expiry: FormatDateTime(new Date(data.expiry)),
      };

      const newClientData = {
        clientId: uuidv4().slice(0, 7),
        clientName: userName || "",
        email: data.subscription.user_email || "",
        phone: mobileNumber || "",
        groups: [`All Client`, fileName],
        location: data.location || "",
        telegram: data.telegram || "",
        pan: data.pan || "",
        creationDate: FormatDateTime(new Date()),
        comments: data.comments || "",
        subscriptions: [newSubscription],
      };

      try {
        const response = await fetch(
          `${server.server.baseUrl}api/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: planDetails?.adminId,
              clientData: newClientData,
            }),
          }
        );

        const result = await response.json();
      } catch (error) {
        console.error("Error adding client:", error);
      }

      let payloadData = JSON.stringify({
        email: userEmail,
        action: "subscribe",
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payloadData,
      };

      axios
        .request(config)
        .then((response) => {
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });

      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: latestRebalance.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response) => {
          getStrategyDetails();
          toast.success(
            "You have been successfully subscribed model portfolio.",
            {
              duration: 3000,
              style: {
                background: "white",
                color: "#16a085",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "16px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#16a085",
                secondary: "#FFFAEE",
              },
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
      getStrategyDetails();
    } catch (error) {
      console.error("Error completing subscription:", error);
    }
  }

  // Keep all the existing useEffects and functions
  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDeatils();
  }, [userEmail]);

  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          if (portfolioData?.model?.rebalanceHistory?.length > 0) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  const getPlanDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/all-plans/check-portfolio-plan/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          setPlanDetails(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getPlanDetails();
  }, [fileName]);

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    }
  };

  useEffect(() => {
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const subscribed =
    strategyDetails?.subscribed_by.filter((email) => email === userEmail)
      .length > 0;

  const handleSubscribe = () => {
    setPaymentModal(true);
  };

  const handleCardClickSelect = (item) => {
    setSelectedCard(item);
  };

  const handleOk = () => {
    subscribeToPlan(selectedCard);
  };

  const colorPalette = [
    "#EAE7DC",
    "#F5F3F4",
    "#D4ECDD",
    "#FFDDC1",
    "#F8E9A1",
    "#B2C9AB",
    "#FFC8A2",
    "#F6BD60",
    "#CB997E",
    "#A5A58D",
    "#B7CADB",
    "#E2F0CB",
    "#C1D37F",
    "#FFEBBB",
    "#D3C4C4",
    "#D4A5A5",
    "#FFF3E2",
    "#F7B7A3",
    "#EFD6AC",
    "#FAE3D9",
    "#BCD4DE",
    "#B7B6C1",
    "#F9F3DF",
    "#E5D9B6",
    "#D5C6E0",
    "#FFE5D9",
    "#A5C4D4",
    "#F8EAD1",
    "#FDE8D7",
    "#DFD3C3",
  ];

  // Chart data preparation
  const { chartData, chartConfig, colorMap } = useMemo(() => {
    const colorMap = {};
    const data =
      latestRebalance?.adviceEntries?.map((entry, index) => {
        const color = colorPalette[index % colorPalette.length];
        colorMap[entry.symbol] = color;
        return {
          shares: entry.symbol,
          value: entry.value * 100,
          fill: color,
        };
      }) || [];

    const config = {
      value: { label: "Equity Distribution" },
      ...data.reduce((acc, entry) => {
        acc[entry.shares] = {
          label: entry.shares,
          color: entry.fill,
        };
        return acc;
      }, {}),
    };

    return { chartData: data, chartConfig: config, colorMap };
  }, [latestRebalance, colorPalette]);

  const coinBreadcrumbsList = [
    { title: "Model Portfolio", link: "/model-portfolio" },
    { title: `${fileName}`, link: "" },
  ];

  return (
    <div className="w-full relative flex flex-col max-h-[calc(100vh-60px)] lg:min-h-screen bg-[#F9F9F9]">
      <Toaster position="top-center" reverseOrder={true} />

      <div className="w-full grid grid-cols-12 h-[calc(100vh-120px)] overflow-y-auto lg:min-h-full">
        <div className="col-span-12 px-4 lg:col-span-8 xl:col-span-9 xl:px-[60px]">
          {/* Main Content Area */}
          <div className="flex flex-col space-y-6 py-6">
            <Breadcrumb breadcrumbsList={coinBreadcrumbsList} />

            {/* Strategy Header */}
            <div className="flex space-x-6">
              <div className="flex items-center justify-center">
                <img
                  src={strategyDetails?.image || Alpha100}
                  alt={strategyDetails?.model_name}
                  className="h-[50px] w-[50px] rounded-md"
                />
              </div>
              <div className="flex justify-center flex-col">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-black">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h3>
                </div>
              </div>
            </div>

            <p className="text-[14px] font-normal font-poppins text-black/60">
              {strategyDetails?.overView}
            </p>
          </div>

          {/* Mobile Performance Stats */}
          <div className="flex flex-col space-y-6 pb-8 lg:hidden">
            <div className="flex justify-evenly divide-x-2 bg-[#E4FDD5]/50">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4">
                <div className="text-[14px] text-black/70 font-poppins font-semibold">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold">
                  New portfolio
                </p>
              </div>
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4">
                <div className="text-[14px] text-black/70 font-poppins font-semibold">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold">
                  New portfolio
                </p>
              </div>
            </div>

            {/* Mobile Strategy Metrics */}
            <div className="w-full flex flex-col">
              <div className="flex items-center border-b border-black/10 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img
                    src={VolatilityIcon}
                    className="h-[26px] w-[26px]"
                    alt="Volatility"
                  />
                </div>
                <div className="flex items-center text-[14px] text-black/80 font-poppins font-medium">
                  Volatility:
                  <p className="ml-2 text-[14px] text-black font-poppins font-medium">
                    Low
                  </p>
                </div>
              </div>

              {/* Other metrics */}
              <div className="flex items-center border-b border-black/10 py-4 space-x-3">
                <div className="flex items-center justify-center text-[12px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[24px] w-[24px]">
                  10
                </div>
                <div className="flex items-center text-[14px] text-black/80 font-poppins font-medium">
                  Strategy Score
                </div>
              </div>

              {/* Continue with other metrics */}
            </div>
          </div>

          {/* Tabs Section */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between border-b border-black/10">
              <div className="flex space-x-6">
                <div
                  className={
                    selectedDataTab === "distribution"
                      ? style.selected
                      : style.unselected
                  }
                  onClick={() => setSelectedDataTab("distribution")}
                >
                  Distribution
                </div>
                <div
                  className={
                    selectedDataTab === "whyStrategy"
                      ? style.selected
                      : style.unselected
                  }
                  onClick={() => setSelectedDataTab("whyStrategy")}
                >
                  Why this Strategy
                </div>
                <div
                  className={
                    selectedDataTab === "methodology"
                      ? style.selected
                      : style.unselected
                  }
                  onClick={() => setSelectedDataTab("methodology")}
                >
                  Methodology
                </div>
              </div>
              <Share2Icon className="h-5 w-5 text-black" />
            </div>
          </div>

          {/* Tab Content */}
          {selectedDataTab === "distribution" && (
            <div className="w-full flex flex-col space-y-6 py-6">
              <div className="text-[14px] text-black/60 font-normal font-poppins">
                Check Latest Rebalance Updates issued by the Manager.
                <span
                  className="ml-2 cursor-pointer text-[14px] text-blue-500 font-normal font-poppins"
                  onClick={() => setShowRebalanceTimelineModal(true)}
                >
                  View Rebalance History
                </span>
              </div>

              {/* Rebalance Info Cards */}
              <div className="grid grid-cols-2 gap-y-5 md:gap-y-0 md:grid-cols-3 lg:w-[656px] lg:divide-x-2 divide-black/10 bg-[#f1f0f0] rounded-[4px] py-4 lg:px-0">
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-black/60 font-normal font-poppins">
                    Last Rebalance
                  </h4>
                  <p className="text-[16px] text-black font-medium font-poppins">
                    {moment(strategyDetails?.last_updated).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-black/60 font-normal font-poppins">
                    Next Rebalance
                  </h4>
                  <p className="text-[16px] text-black font-medium font-poppins">
                    {moment(strategyDetails?.nextRebalanceDate).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-black/60 font-normal font-poppins">
                    Rebalance
                  </h4>
                  <p className="text-[16px] text-black font-medium font-poppins">
                    {strategyDetails?.frequency}
                  </p>
                </div>
              </div>

              {/* Distribution Chart and List */}
              <div className="flex flex-col lg:flex-row lg:space-x-8">
                <div className="flex flex-col lg:w-[400px]">
                  <div className="grid grid-cols-5 border-b border-black/10 py-3 px-4">
                    <div className="col-span-3 text-[14px] text-black/60 font-normal font-poppins">
                      Equity
                    </div>
                    <div className="flex justify-end col-span-2 text-[14px] text-black/60 font-normal font-poppins">
                      Weightage (%)
                    </div>
                  </div>
                  {latestRebalance?.adviceEntries?.map((ele, i) => (
                    <div
                      className="relative grid grid-cols-5 border-b border-b-black/10 border-l-[4px] py-4 px-8"
                      style={{ borderLeftColor: colorMap[ele.symbol] }}
                      key={i}
                    >
                      <div className="col-span-3 text-[15px] text-black/85 font-normal font-poppins">
                        {ele.symbol}
                      </div>
                      <div className="flex justify-end col-span-2 text-[15px] text-black/85 font-normal font-poppins">
                        {parseFloat(ele.value * 100).toFixed(2)}
                      </div>
                    </div>
                  ))}
                </div>

                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[300px] h-[280px]"
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie data={chartData} dataKey="value" nameKey="shares" />
                  </PieChart>
                </ChartContainer>
              </div>
            </div>
          )}

          {selectedDataTab === "whyStrategy" && (
            <div className="flex flex-col space-y-6 py-6">
              <p className="text-[14px] font-normal font-poppins text-black/60">
                {strategyDetails?.whyThisStrategy}
              </p>
            </div>
          )}

          {selectedDataTab === "methodology" && (
            <div className="flex flex-col space-y-6 py-6">
              <div>
                <p className="text-lg font-medium">Defining the universe</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                  {strategyDetails?.definingUniverse}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Research</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                  {strategyDetails?.researchOverView}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Constituent Screening</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                  {strategyDetails?.constituentScreening}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Weighting</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                  {parseFloat(strategyDetails?.weighting).toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Rebalance</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                  {strategyDetails?.rebalanceMethodologyText}
                </p>
              </div>
              {strategyDetails?.assetAllocationText !== "" && (
                <div>
                  <p className="text-lg font-medium">Asset Allocation</p>
                  <p className="mt-2 text-[14px] font-normal font-poppins text-black/60">
                    {strategyDetails?.assetAllocationText}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Right Sidebar */}
        <div className="hidden lg:block lg:col-span-4 xl:col-span-3">
          <div className="flex flex-col space-y-6 px-6 py-10">
            {subscribed ? (
              <button className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6">
                Subscribed
              </button>
            ) : (
              <button
                onClick={handleSubscribe}
                className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
              >
                Invest Now
              </button>
            )}

            {/* Performance Metrics */}
            <div className="flex justify-evenly divide-x-2 bg-[#E4FDD5]/50">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 md:px-2 xxl:px-4">
                <div className="text-[14px] text-black/70 font-poppins font-semibold">
                  CAGR
                </div>
                <p className="text-[20px] md:text-lg xl:text-[20px] text-[#18B13F] font-poppins font-semibold">
                  New portfolio
                </p>
              </div>
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 md:px-2 xxl:px-4">
                <div className="text-[14px] text-black/70 font-poppins font-semibold">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] md:text-lg xl:text-[20px] text-[#18B13F] font-poppins font-semibold">
                  New portfolio
                </p>
              </div>
            </div>

            {/* Strategy Metrics */}
            <div className="w-full flex flex-col py-6">
              {/* Strategy metrics list */}
              <div className="flex items-center border-b border-black/20 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img
                    src={VolatilityIcon}
                    className="h-[26px] w-[26px]"
                    alt="Volatility"
                  />
                </div>
                <div className="flex items-center text-[14px] text-black/80 font-poppins font-medium">
                  Volatility:
                  <p className="ml-2 text-[14px] text-black font-poppins font-medium">
                    Low
                  </p>
                </div>
              </div>
              {/* Add other strategy metrics similarly */}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Bottom CTA */}
      <div className="lg:hidden flex items-center w-full h-[80px] shadow-[0px_-4px_4px_0px_#0000000f] px-4">
        {subscribed ? (
          <button className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6">
            Subscribed
          </button>
        ) : (
          <button
            onClick={handleSubscribe}
            className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
          >
            Invest Now
          </button>
        )}
      </div>

      {/* Modals */}
      {openSubscribeModel && (
        <UserStrategySubscribeModal
          setOpenSubscribeModel={setOpenSubscribeModel}
          userEmail={userEmail}
          getStrategyDetails={getStrategyDetails}
          strategyDetails={strategyDetails}
          fileName={fileName}
          latestRebalance={latestRebalance}
          userDetails={userDetails}
          setOpenSucessModal={setOpenSucessModal}
          setOrderPlacementResponse={setOrderPlacementResponse}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          setBrokerModel={setBrokerModel}
          clientCode={clientCode}
          apiKey={apiKey}
          secretKey={secretKey}
          jwtToken={jwtToken}
          viewToken={viewToken}
          sid={sid}
          serverId={serverId}
          broker={broker}
        />
      )}

      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStrategyDetails}
          openSuccessModal={openSuccessModal}
        />
      )}

      {brokerModel && (
        <ConnectBroker
          uid={userDetails?._id}
          userDetails={userDetails}
          setBrokerModel={setBrokerModel}
          getUserDeatils={getUserDeatils}
          broker={broker}
          setBroker={setBroker}
          brokerModel={brokerModel}
        />
      )}

      {openTokenExpireModel && (
        <TokenExpireBrokarModal
          openTokenExpireModel={openTokenExpireModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          userId={userId}
          apiKey={apiKey}
          secretKey={secretKey}
          checkValidApiAnSecret={checkValidApiAnSecret}
          clientCode={clientCode}
          my2pin={my2pin}
          panNumber={panNumber}
          mobileNumber={mobileNumber}
          broker={broker}
          getUserDeatils={getUserDeatils}
        />
      )}

      {showRebalanceTimelineModal && (
        <RebalanceTimeLineModal
          closeRebalanceTimelineModal={() =>
            setShowRebalanceTimelineModal(false)
          }
          strategyDetails={strategyDetails}
        />
      )}

      {paymentModal && (
        <PlanSubscribeModal
          userEmail={userEmail}
          strategyDetails={planDetails}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleCardClick={handleCardClickSelect}
          selectedCard={selectedCard}
          handleOk={handleOk}
          loading={loading}
          invetAmount={invetAmount}
          setInvestAmount={setInvestAmount}
        />
      )}

      {paymentSuccess && (
        <PaymentSuccessModal
          specificPlanDetails={specificPlan}
          setPaymentSuccess={setPaymentSuccess}
          setSelectedCard={setSelectedCard}
          setOpenSubscribeModel={setOpenSubscribeModel}
        />
      )}
    </div>
  );
};

export default StrategyDetails;
