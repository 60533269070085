import React from "react";
import Sbi from "./assests/sbi.png";
import Exp from "./assests/Experience.png";
import Advisor from "./assests/About/advisor.jpg";
const AboutSection = () => {
  return (
    <div
      className="flex flex-col lg:flex-row bg-stone-100"
      section
      id="aboutSection"
    >
      <div className="lg:w-1/3 sm:w-[50%] lg:h-auto">
        <div className="md:w-[440px] h-[570px] sm:w-full sm:h-full bg-cover">
          <img
            src={Advisor}
            alt="About"
            className="object-cover w-full h-full p-4 sm:p-0"
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center w-full lg:w-2/3 lg:h-[32rem] px-4 lg:px-0 mx-auto mt-6 lg:mt-0">
        <div className="w-[90%] pb-10 md:pb-0 lg:pb-0 lg:w-full">
          <div className="font-Montserrat text-[16px] text-neutral-500 font-semibold text-center lg:text-left">
            ABOUT ADVISOR
          </div>
          <div className="font-Montserrat text-[#171717] mt-0 font-bold text-[36px] md:text-[45px] md:mt-3 lg:text-4xl text-center lg:text-left">
            Punam Kucheria
          </div>

          <p className="font-poppins font-normal mt-4 text-[17px] text-[#7B7B7B] text-left  w-full lg:w-[80%] mx-auto lg:mx-0 lg:text-left">
            Mr. Punam Kucheria is a SEBI registered Research Analyst and the
            founder of Clearmind Consultancy Pvt. Ltd., a name synonymous with
            financial success. He is also a Symbiosis alumnus with over 25 years
            of experience in Stock markets. Mr. Kucheria's investment philosophy
            revolves around the power of systematic investments for the long
            term, devoid of any human bias. His passion lies in developing
            cutting-edge, fully automated high-yielding investing models,
            designed to be a reliable source of passive income and wealth
            accumulation. Driven by the belief that financial freedom should be
            accessible to all, he has dedicated his career to guiding
            individuals and businesses on their path to financial prosperity.
          </p>

          <div className="flex flex-row mt-14  md:space-x-20 lg:space-x-14 items-center justify-center lg:items-start lg:justify-start">
            <div className="flex flex-row lg:flex-row items-center space-x-2 space-y-4 lg:space-y-0 lg:space-x-6">
              <img src={Exp} alt="Logo" className="w-8 h-10  lg:w-9 lg:h-11" />
              <div className="flex flex-col  lg:text-left">
                <div className="text-lg  lg:text-xl font-bold leading-7 tracking-tight text-black">
                  20+ years
                </div>
                <div className="text-base leading-6 text-gray-500 font-semibold">
                  Years of Experience
                </div>
              </div>
            </div>

            <div className="flex flex-row lg:flex-row items-center mr-2 space-x-2 space-y-4 lg:space-y-0 lg:space-x-6">
              <img src={Sbi} alt="Logo" className="w-10 h-10" />
              <div className="flex flex-col text-center lg:text-left">
                <div className=" text-lg  lg:text-xl font-bold leading-7 tracking-tight text-black">
                  INH000010098
                </div>
                <div className="text-base leading-6 text-gray-500 font-semibold">
                  SEBI Registered
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
