import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// Default Firebase configuration values
const defaultConfig = {
  apiKey: "AIzaSyBtdlFOH_rVR-jHZdfV_ST7F44B67WG0Qs",
  authDomain: "clearmind-50822.firebaseapp.com",
  projectId: "clearmind-50822",
  storageBucket: "clearmind-50822.firebasestorage.app",
  messagingSenderId: "92298901585",
  appId: "1:92298901585:web:f04f26d630c349b633e070",
  measurementId: "G-0CYEK3T8WP",
};

// Check if any required Firebase configuration variable is missing
const requiredEnvVars = [
  "REACT_APP_FIREBASE_API_KEY",
  "REACT_APP_FIREBASE_AUTH_DOMAIN",
  "REACT_APP_FIREBASE_PROJECT_ID",
  "REACT_APP_FIREBASE_STORAGE_BUCKET",
  "REACT_APP_FIREBASE_MESSAGING_SENDER_ID",
  "REACT_APP_FIREBASE_APP_ID",
];

// Check if ANY required env variable is missing
const isAnyEnvVarMissing = requiredEnvVars.some(
  (varName) => !process.env[varName]
);

// Use either ALL env variables or ALL defaults
const firebaseConfig = isAnyEnvVarMissing
  ? defaultConfig
  : {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ||
        defaultConfig.measurementId,
    };

// Log which configuration is being used
if (isAnyEnvVarMissing) {
  console.warn(
    "One or more environment variables are missing. Using default Firebase configuration."
  );
}

// Initialize Firebase only if it hasn't been initialized already
const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

// Initialize Firestore and Auth services
const db = app.firestore();
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();

// Export the initialized services
export { db, auth, provider };

// Export the config for testing purposes
export const config = firebaseConfig;
