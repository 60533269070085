import React, { useState, useEffect } from "react";
import Bg from "./assests/headerBG.svg";
import Logo from "./assests/Logo.jpg";
import $ from "./assests/icons/$.svg";
import Tips from "./assests/icons/tips.svg";
import Derivatives from "./assests/icons/derivatives.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const HeaderSection = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  return (
    <div className="relative isolate overflow-hidden  py-12 sm:py-24 md:py-12">
      <img
        src={Bg}
        alt=""
        className="absolute inset-0 -z-10 h-[97%] w-[100%] object-cover object-right md:object-center"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      ></div>
      <div
        className="absolute -top-40 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:left-auto sm:right-0 sm:translate-x-0 sm:ml-16 sm:transform-gpu"
        aria-hidden="true"
      ></div>

      <div className="mx-auto max-w-7xl px-7 lg:px-8">
        <div className="flex flex-col items-center justify-center w-full text-center md:flex-row md:items-start md:text-left">
          <div className=" mb-6 md:mb-0 md:mr-12">
            <img
              src={Logo}
              alt="Logo"
              className="h-[120px] w-[120px] md:h-[170px] md:w-[280px] lg:h-[200px] lg:w-[250px] rounded-md"
            />
          </div>

          <div className="flex flex-col">
            <div className="space-y-4 ">
              <h1 className="text-white font-montserrat  w-full text-[24px] md:text-[35px] lg:text-[42px] font-bold leading-[1.38] md:leading-[58px] md:pt-0 text-center md:text-left ">
                Maximize Your Financial Potential with{" "}
                <span className="hidden sm:inline">
                  {" "}
                  <br />{" "}
                </span>
                {advisorTag}
              </h1>

              <p className="text-[#848484] font-poppins  text-[18px]  md:text-lg leading-7 md:leading-8 text-center md:text-left">
                Transform your wealth with {advisorTag} - where expert human
                insights are amplified with effective and efficient automations.
              </p>
            </div>
          </div>
        </div>

        <div className=" mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="flex flex-col sm:pt-5 sm:flex-row items-center justify-left gap-10 sm:gap-32 md:pt-12 md:pb-7 rounded-md w-full">
            <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-6">
              <div className="flex-shrink-0 w-[40px] h-[40px] bg-[#282828] flex items-center justify-center">
                <img
                  src={$}
                  alt="Model Portfolios Icon"
                  className="w-[15px] h-[20px]"
                />
              </div>
              <div className="flex flex-col text-center lg:text-left">
                <div className="flex flex-col">
                  <dd className="text-white font-poppins text-sm font-semibold leading-[22px] text-center lg:text-left text-primary">
                    Model Portfolios
                  </dd>
                  <dt className="text-white font-poppins text-xs sm:text-sm font-normal leading-5 sm:leading-[22px] text-center lg:text-left text-primary-foreground">
                    Invest in Curated Portfolios
                  </dt>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-6">
              <div className="flex-shrink-0 w-[40px] h-[40px] bg-[#282828] flex items-center justify-center rounded-md">
                <img
                  src={Tips}
                  alt="Stock Tips Icon"
                  className="w-[17px] h-[18px]"
                />
              </div>
              <div className="flex flex-col text-center lg:text-left">
                <div className="flex flex-col">
                  <dd className="text-white font-poppins text-sm font-semibold leading-[22px] text-center lg:text-left">
                    Stock Tips
                  </dd>
                  <dt className="text-white font-poppins text-xs sm:text-sm font-normal leading-5 sm:leading-[22px] text-center lg:text-left">
                    Receive expert Stock Recommendation
                  </dt>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-6">
              <div className="flex-shrink-0 w-[40px] h-[40px] bg-[#282828] flex items-center justify-center rounded-md">
                <img
                  src={Derivatives}
                  alt="Derivatives Icon"
                  className="w-[15px] h-[18px]"
                />
              </div>
              <div className="flex flex-col text-center lg:text-left">
                <div className="flex flex-col">
                  <dd className="text-white font-poppins text-sm font-semibold leading-[22px] text-center lg:text-left text-primary">
                    Derivatives
                  </dd>
                  <dt className="text-white font-poppins text-xs sm:text-sm font-normal leading-5 sm:leading-[22px] text-center lg:text-left text-primary-foreground">
                    Trade with expert insights
                  </dt>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
