import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import IgnoreStockCard from "./IgnoreStockCard";
import { auth } from "../../firebase";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import server from "../../utils/serverConfig";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import BanIcon from "../../assests/ban_icon.svg";
import ReviewTradeModel from "../StockRecommendation/ReviewTradeModel";
import ZerodhaReviewModal from "../StockRecommendation/ZerodhaReviewModal";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

const defaultRationale = `This recommendation is based on a comprehensive analysis of the
        company's growth potential and value metrics. This recommendation
        also accounts for potential future risks, ensuring a balanced
        approach to maximizing returns while mitigating uncertainties.
        Please contact your advisor for any queries.`;

const IgnoreTrades = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();
  const appURL = process.env.REACT_APP_URL;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  const [ignoredTrades, setIgnoredTrades] = useState([]);

  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        const filteredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "ignored";
        });

        setIgnoredTrades(filteredTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  const handleIgnoredTrades = (id, setIgnoreTradesLoading) => {
    setIgnoreTradesLoading(true);
    const tradeToRestore = ignoredTrades.find(trade => trade._id === id);

    let data = JSON.stringify({
      uid: id,
      trade_place_status: "recommend",
    });

    // Second API request to place the order
    let orderConfig = {
      method: "put",
      url: `${server.server.baseUrl}api/recommendation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(orderConfig)
      .then((response) => {
        setIgnoreTradesLoading(false);
        toast.success(`${tradeToRestore.Symbol} successfully added back to the advice screen`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
            fontFamily: "Poppins"
                    },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getAllTrades();
      })
      .catch((error) => {
        setIgnoreTradesLoading(false);
        console.error(`Error placing order for stock `, error);
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [openReviewTrade, setOpenReviewTrade] = useState(false);
  const [openZerodhaModel, setOpenZerodhaModel] = useState(false);
  const [stockDetails, setStockDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails.apiKey;
  const broker = userDetails && userDetails.user_broker;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();

  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const updatePortfolioData = () => {
    if (broker === "IIFL Securities") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}iifl/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}kotak/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Upstox") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}upstox/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "ICICI Direct") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}icici/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Angel One") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}angelone/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const placeOrder = () => {
    setLoading(true);
    if (broker === "IIFL Securities") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        clientCode: clientCode,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/iifl/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res--------", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
        })
        .catch((error) => {
          console.error(`Error placing order for stock `, error);
          setLoading(false);
          toast.error("Something went wrong. Please try again later", {
            duration: 6000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
          // Handle error for individual stock
        });
    } else if (broker === "ICICI Direct") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        secretKey: secretKey,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/icici/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Upstox") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        secretKey: secretKey,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);

          getAllTrades();
          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        apiKey: apiKey,
        secretKey: secretKey,
        jwtToken: jwtToken,
        viewToken: viewToken,
        sid: sid,
        serverId: serverId,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/kotak/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("kotak order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();

          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Hdfc Securities") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/hdfc/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("angel one order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Dhan") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        clientCode: clientCode,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/dhan/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("angel one order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();

          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/trading/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("angel one order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();

          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    }
  };

  // user login
  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(false);

  const [funds, setFunds] = useState({});

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const { getLTPForSymbol } = useWebSocketCurrentPrice(ignoredTrades);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    stockDetails.forEach((ele) => {
      if (ele.transactionType === "BUY") {
        const ltp = getLTPForSymbol(ele.tradingSymbol); // Get LTP for current symbol
        if (ltp !== "-") {
          totalAmount += parseFloat(ltp) * ele.quantity; // Calculate total amount for this trade
        }
      }
    });
    return totalAmount.toFixed(2); // Return total amount formatted to 2 decimal places
  };
  const handleZerodhaRedirect = async () => {
    localStorage.setItem(
      "stockDetailsZerodhaOrder",
      JSON.stringify(stockDetails)
    );
    const apiKey = zerodhaApiKey;

    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT") {
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
          baseOrder.variety = "regular";
        } else {
          baseOrder.variety = "regular";
          baseOrder.price = stock.limitPrice || 0; // Use limitPrice if available, or set to 0
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://kite.zerodha.com/connect/basket";
    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    const redirectParams = document.createElement("input");
    redirectParams.type = "hidden";
    redirectParams.name = "redirect_params";
    redirectParams.value = `${appURL}=true`;

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    form.appendChild(redirectParams);

    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios.put(`${server.server.baseUrl}api/zerodha/update-trade-reco`, {
        stockDetails: stockDetails,
        leaving_datetime: currentISTDateTime,
      });

      // Submit the form after the database is updated
      form.submit();
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
    }
  };

  return (
    <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
      <Toaster position="top-center" reverseOrder={true} />
      {ignoredTrades?.length !== 0 ? (
        <div className="flex flex-col w-full min-h-screen  bg-[#f9f9f9]  ">
          <div className="flex flex-col h-[80px] border-[#000000]/10">
            <div className="px-6 lg:px-6">
              <div className="text-balck text-[18px] lg:text-[22px] leading-[22px] mt-4 font-sans font-bold">
                Ignored Trades
              </div>
              <div className="text-[14px] lg:text-[15px] text-[#000000]/70 leading-[20px] font-light font-poppins">
                Trade or restore ignored stocks directly.
              </div>
            </div>
          </div>

          <div className="h-[calc(100vh-170px)] overflow-hidden  md:overflow-auto md:h-auto pb-[40px]  md:pb-0 w-full">
            {ignoredTrades.sort((a, b) => new Date(b.date) - new Date(a.date))
              .length !== 0 ? (
              <div className="h-full overflow-scroll custom-scrollbar md:overflow-auto w-full grid gap-y-4 pb-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-y-6 md:gap-x-3 xl:grid-cols-3 lg:gap-4 lg:pt-6 lg:px-[50px]  lg:pb-12">
                {ignoredTrades.map((ele, i) => {
                  return (
                    <IgnoreStockCard
                      key={i}
                      id={ele._id}
                      symbol={ele.Symbol}
                      date={ele.date}
                      Quantity={ele.Quantity}
                      action={ele.Type}
                      orderType={ele.OrderType}
                      exchange={ele.Exchange}
                      segment={ele.Segment}
                      tradingSymbol={ele.Symbol}
                      tradeId={ele.tradeId}
                      advisedRangeLower={ele.Advised_Range_Lower}
                      advisedRangeHigher={ele.Advised_Range_Higher}
                      rationale={
                        ele?.rationale ? ele.rationale : defaultRationale
                      }
                      recommendationStock={ignoredTrades && ignoredTrades}
                      setRecommendationStock={setIgnoredTrades}
                      setStockDetails={setStockDetails}
                      stockDetails={stockDetails}
                      setOpenReviewTrade={setOpenReviewTrade}
                      setOpenZerodhaModel={setOpenZerodhaModel}
                      handleIgnoredTrades={handleIgnoredTrades}
                      getLTPForSymbol={getLTPForSymbol}
                      setOpenTokenExpireModel={setOpenTokenExpireModel}
                      todayDate={todayDate}
                      expireTokenDate={expireTokenDate}
                      broker={broker}
                      brokerStatus={brokerStatus}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)]  lg:h-[calc(100vh-120px)]">
                <div className="text-xl md:text-3xl font-semibold font-sans ">
                  No Ignored Trades Available
                </div>
              </div>
            )}
          </div>

          {stockDetails.length !== 0 && openReviewTrade === true ? (
            <ReviewTradeModel
              calculateTotalAmount={calculateTotalAmount}
              getLTPForSymbol={getLTPForSymbol}
              stockDetails={stockDetails}
              setStockDetails={setStockDetails}
              setOpenReviewTrade={setOpenReviewTrade}
              openReviewTrade={openReviewTrade}
              placeOrder={placeOrder}
              loading={loading}
              funds={funds}
            />
          ) : stockDetails.length !== 0 &&
            broker === "Zerodha" &&
            openZerodhaModel === true ? (
            <ZerodhaReviewModal
              getLTPForSymbol={getLTPForSymbol}
              stockDetails={stockDetails}
              setStockDetails={setStockDetails}
              calculateTotalAmount={calculateTotalAmount}
              funds={funds}
              setOpenZerodhaModel={setOpenZerodhaModel}
              openZerodhaModel={openZerodhaModel}
              handleZerodhaRedirect={handleZerodhaRedirect}
            />
          ) : null}

          {openTokenExpireModel === true ? (
            <TokenExpireBrokarModal
              openTokenExpireModel={openTokenExpireModel}
              setOpenTokenExpireModel={setOpenTokenExpireModel}
              userId={userId && userId}
              apiKey={apiKey}
              secretKey={secretKey}
              checkValidApiAnSecret={checkValidApiAnSecret}
              clientCode={clientCode}
              my2pin={my2pin}
              panNumber={panNumber}
              mobileNumber={mobileNumber}
              broker={broker}
              getUserDeatils={getUserDeatils}
            />
          ) : null}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
              <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
                <svg
                  className="h-10 w-10 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-6 w-full relative lg:min-h-screen  bg-[#f9f9f9] ">
              <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                <img
                  src={BanIcon}
                  alt="Ban Icon"
                  className="w-[60px] lg:w-[80px]"
                />
              </div>
              <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                  No Ignored Trades
                </div>
                <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                  No trades have been ignored yet. When you ignore a trade, it
                  will be listed here.
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStockDetails}
          openSuccessModal={openSuccessModal}
        />
      )}
    </div>
  );
};

export default IgnoreTrades;
