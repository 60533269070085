import { Link } from "react-router-dom";
import React from "react";

const ContactUs = () => {
  return (
    <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
      <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
        <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
          Contact Us
        </h2>

        <Link
          to="/"
          className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
        >
          Login
        </Link>
      </div>
      <div className="mt-6 space-y-2 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
          We’re here to help! Whether you have questions about our platform,
          need assistance with your account, or want to provide feedback, feel
          free to reach out to us. Our team is available to assist you with any
          inquiries.
        </p>
        <div className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
          Get in Touch
        </div>

        <div className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
          Email:
        </div>
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left">
          For general inquiries, support, or feedback, please email us at:
          <br />
          <a href="hello@alphaquark.in" className="text-blue-500 underline">
            hello@alphaquark.in
          </a>
        </p>

        <div className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
          Phone:
        </div>
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left">
          Need to speak with us directly? Call our customer support line:
          <br />
          <span className="font-semibold"> +91 7276689226 </span>
          <br />
          <span className="font-semibold">
            Available: [Monday to Saturday, 9:00 AM - 6:00PM]
          </span>
        </p>

        <div className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
          Address:
        </div>
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left">
          Visit us or send us mail at our registered office: <br />
          <span className="font-semibold">
            AlphaQuark, Awfis, 10th floor, Tower B, Prestige Shantiniketan,
            Whitefield, Bengaluru 560048, India
          </span>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
