import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash"; // Using lodash for debouncing
import {
  ChevronRight,
  ChevronDown,
  MessageSquareText,
  Edit2Icon,
  Trash2Icon,
  PlusIcon,
  MessageSquarePlus,
  XIcon,
  CloudUploadIcon, InfoIcon,
} from "lucide-react";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import { AiFillCaretRight } from "react-icons/ai";
import UpdateCommentModal from "./updateCommentModal";
import FormatDateTime, { FormatDate } from "../../utils/formatDateTime";
import { v4 as uuidv4 } from "uuid";
import SubscriptionDeleteModal from "./SubscriptionDeleteModal";
import EditSubscriptionModal from "./EditSubscriptionModal";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import toast from "react-hot-toast";
import Excel from "../../assests/Icon/Excel.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../../utils/formatCurrency";
import { motion, AnimatePresence } from "framer-motion"
import EditClientModal from "../ClientListDetailsSection/EditClientModal";

const ClientListNewTable = ({
  userId,
  setIsDataFetching,
  allClientsData,
  allGroupsData,
  searchQuery,
  getAllClientsData,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(allClientsData);

  const allGroupsName =
    allGroupsData && allGroupsData?.flatMap((item) => item.groupName);

  const [expandedRows, setExpandedRows] = useState({});

  const [dropdownOpen, setDropdownOpen] = useState({});
  const [newClientVisible, setNewClientVisible] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const [newClient, setNewClient] = useState({
    clientId: uuidv4().slice(0, 7),
    clientName: "",
    email: "",
    phone: "",
    groups: ["All Client"],
    location: "",
    telegram: "",
    pan: "",
    creationDate: FormatDateTime(new Date()),
    comments: "",
  });

  const [newSubscription, setNewSubscription] = useState({
    subId: uuidv4().slice(0, 10),
    startDate: FormatDateTime(new Date()),
    plan: "",
    capital: 0,
    charges: 0,
    invoice: 0,
    expiry: FormatDateTime(new Date()),
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showSubscriptionDeleteModal, setShowSubscriptionDeleteModal] =
    useState(false);

  const [showSubscriptionEditModal, setShowSubscriptionEditModal] =
    useState(false);

  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [subscriptionClientId, setSubscriptionClientId] = useState(null);
  const [tableIndex, setTableIndex] = useState(null);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState(null);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
  const [newSubRow, setNewSubRow] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setData(allClientsData);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [allClientsData]);

  // useEffect(() => {
  //   setFilteredData(
  //     data.filter((user) =>
  //       user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // }, [searchQuery, data]);

  useEffect(() => {
    const filteredAndSortedData = data
      .filter((user) =>
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by latest

    setFilteredData(filteredAndSortedData);
  }, [searchQuery, data]);

  // const toggleRow = (index) => {
  //   setExpandedRows((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };

  const toggleRow = (index) => {
    setExpandedRows((prev) => {
      const newExpandedRows = {};

      // Expand the current row or collapse if it's already expanded
      if (prev[index]) {
        // Collapse the currently expanded row
        return newExpandedRows;
      } else {
        // Expand the new row and collapse any previously expanded row
        newExpandedRows[index] = true;
        return newExpandedRows;
      }
    });
  };

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGroupChange = (group) => {
    setNewClient((prevClient) => {
      let updatedGroups;

      if (group === "All Client") {
        // If "All Client" is being toggled, do nothing (it should always be present)
        return prevClient;
      } else {
        const isGroupPresent = prevClient.groups.includes(group);
        if (isGroupPresent) {
          // Remove the group if it's already present
          updatedGroups = prevClient.groups.filter((g) => g !== group);
        } else {
          // Add the group if it's not present
          updatedGroups = [...prevClient.groups, group];
        }
      }

      // Ensure "All Client" is always included
      if (!updatedGroups.includes("All Client")) {
        updatedGroups.unshift("All Client");
      }

      return { ...prevClient, groups: updatedGroups };
    });
  };

  const handleAddRow = () => {
    setNewClientVisible(true);
  };

  const handleAddSubRow = (index) => {
    setNewSubRow(true);
    setExpandedRows((prev) => ({
      ...prev,
      [index]: 1,
    }));
  };

  const handleCancel = () => {
    setNewClientVisible(false);
  };

  const handleCancelSubRow = (index) => {
    setNewSubRow(false);
    // setExpandedRows((prev) => ({
    //   ...prev,
    //   [index]: false,
    // }));
  };

  const handleEditClientModal = (client) => {
    setShowEditModal(true);
    setClientToEdit(client);
  };

  const handleDeleteModal = (client) => {
    setShowDeleteModal(true);
    setClientToDelete(client);
  };

  const handleCommentUpdateModal = (client) => {
    setShowSendMessageModal(true);
    setClientToEdit(client);
  };

  const handleAddClient = async () => {
    const newClientData = {
      ...newClient,
      subscriptions: [], // You can modify this as per your need
      groups: newClient.groups,
    };

    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            clientData: newClientData,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added successfully:", result);

        // Reset the newClient form
        setNewClient({
          clientId: uuidv4().slice(0, 7),
          clientName: "",
          email: "",
          phone: "",
          groups: ["All Client"],
          location: "",
          telegram: "",
          pan: "",
          creationDate: FormatDateTime(new Date()),
          comments: "",
        });

        setNewClientVisible(false);
        setIsDataFetching(true);
        getAllClientsData(userId);
        toast.success(
          `New Client ${newClientData.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add client :", result.message);
        toast.error(`Failed to add new client ${newClientData.clientName} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error(`Error in adding new client ${newClientData.clientName} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleUpdateClient = async (updatedClientData) => {
    console.log("updated----", updatedClientData);

    const newData = {
      ...updatedClientData,
    };
    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/edit-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: newData.clientId,
            clientName: newData.clientName,
            email: newData.email,
            phone: newData.phone,
            groups: newData.groups,
            location: newData.location,
            telegram: newData.telegram,
            pan: newData.pan,
            comments: newData.comments,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client edited successfully:", result);
        getAllClientsData(userId);
        toast.success(
          `${newData.clientName} details have been updated successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to edit client:", result.message);
        getAllClientsData(userId);
        toast.error(`Failed to update ${newData.clientName} details !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error editing client:", error);
      toast.error(`Error in updating ${newData.clientName} details !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteClient = async (client) => {
    console.log("Client data", client);
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: client.clientId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client removed successfully:", result);
        getAllClientsData(userId);
        toast.success(
          ` ${client.clientName} have been removed successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to remove client:", result.message);
        getAllClientsData(userId);
        toast.error(
          `Failed to remove ${client.clientName} from client list !`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error removing client:", error);
      toast.error(`Error in deleting ${client.clientName} from client list !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscriptionModal = (index, client, subId) => {
    setShowSubscriptionDeleteModal(true);

    console.log("client---", client);
    console.log("index---", index);

    const subData = client.subscriptions.filter((item) => item.subId === subId);
    setSubscriptionToDelete(subData[0]);
    setSubscriptionClientId(client.clientId);
    setTableIndex(index);
  };

  const handleEditSubscriptionModal = (client, subId) => {
    setShowSubscriptionEditModal(true);

    console.log("client---", client);

    const subData = client.subscriptions.filter((item) => item.subId === subId);

    setSubscriptionToEdit(subData[0]);
    setSubscriptionClientId(client.clientId);
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;

    setNewSubscription((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPlan = async (index, clientId) => {
    // console.log("New SUB -----", newSubscription);
    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-subscriptions/update/update-client-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            newSubscription: newSubscription,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("newSubscription added successfully:", result);

        setNewSubscription({
          subId: uuidv4().slice(0, 10),
          startDate: FormatDateTime(new Date()),
          plan: "",
          capital: 0,
          charges: 0,
          invoice: 0,
          expiry: FormatDateTime(new Date()),
        });
        getAllClientsData(userId);
        setNewSubRow(false);

        toast.success(
          `Subscription plan ${newSubscription?.plan} for client ${data[index]?.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add subscription:", result.message);
        toast.error(
          `Failed to add subscription for client ${data[index]?.clientName}`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription details !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscription = async (clientId, subId) => {
    console.log("Client data", clientId);
    console.log("Sub ID", subId);
    console.log("index", tableIndex);

    const client = allClientsData.filter((item) => item.clientId === clientId);
    const sub = client[0].subscriptions.filter((item) => item.subId === subId);

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            subId: subId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Subscription removed successfully:", result);
        getAllClientsData(userId);
        // Optionally update state or UI here
        // setExpandedRows((prev) => ({
        //   ...prev,
        //   [tableIndex]: false,
        // }));
        toast.success(
          `Subscription plan ${sub[0].plan} for ${client[0].clientName} deleted successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to remove subscriptions:", result.message);
        toast.error(`Failed to delete subscription ${sub[0].plan} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error removing delete-subscriptions:", error);
      toast.error(`Error in deleting subscription  ${sub[0].plan} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleUpdateSubscription = async (
    clientId,
    subId,
    updatedClientSubscription
  ) => {
    // console.log("Client data", clientId);
    // console.log("Sub ID", subId);
    // console.log("Updated Subscriptions ", updatedClientSubscription);

    const client = allClientsData.filter((item) => item.clientId === clientId);

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/edit-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            subId: subId,
            startDate: updatedClientSubscription.startDate,
            plan: updatedClientSubscription.plan,
            capital: updatedClientSubscription.capital,
            charges: updatedClientSubscription.charges,
            invoice: updatedClientSubscription.invoice,
            expiry: updatedClientSubscription.expiry,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        // console.log("Subscription updated successfully:", result);
        getAllClientsData(userId);

        toast.success(
          `Subscription plan ${updatedClientSubscription.plan} for ${client[0].clientName} updated successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed update subscriptions:", result.message);
        toast.error(
          `Failed to update subscription ${updatedClientSubscription.plan} ! `,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error updating subscriptions:", error);
      toast.error(
        `Error in updating subscription ${updatedClientSubscription.plan} !`,
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const handleAddExistingClientToGroup = async (
    checked,
    clientId,
    groupName
  ) => {
    const clientData = allClientsData?.find(
      (item) => item.clientId === clientId
    );

    if (!clientData) {
      console.error(`Client with ID ${clientId} not found`);
      toast.error(`Error: Client not found`);
      return;
    }

    const prevGroups = clientData.groups || [];
    const name = clientData.clientName || "Unknown Client";

    let updatedGroups;

    if (checked) {
      // Only add the group if it's not already present
      if (!prevGroups.includes(groupName)) {
        updatedGroups = [...prevGroups, groupName];
      } else {
        console.log(`${name} is already in group ${groupName}`);
        return;
      }
    } else {
      // Only remove the group if it's present and not "All Client"
      if (prevGroups.includes(groupName) && groupName !== "All Client") {
        updatedGroups = prevGroups.filter((group) => group !== groupName);
      } else {
        console.log(
          `${name} is not in group ${groupName} or it's "All Client"`
        );
        return;
      }
    }

    // Ensure "All Client" is always included
    if (!updatedGroups.includes("All Client")) {
      updatedGroups.push("All Client");
    }

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/add-existing-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            updatedGroups: updatedGroups,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added to existing group successfully:", result);
        getAllClientsData(userId);
        toast.success(
          checked
            ? `${name} added to group ${groupName} successfully !!`
            : `${name} removed from group ${groupName} successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        throw new Error(result.message || "Failed to update client groups");
      }
    } catch (error) {
      console.error("Error updating client groups:", error);
      toast.error(
        `Error ${checked ? "adding" : "removing"} ${name} ${
          checked ? "to" : "from"
        } group ${groupName}: ${error.message}`,
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  // Debouncing the API call
  const debouncedHandleAddExistingClientToGroup = useCallback(
    debounce(handleAddExistingClientToGroup, 300), // 300ms debounce
    []
  );

  // Fix: Update handleCheckboxChange to use clientId instead of index
  const handleCheckboxChange = (e, clientId, groupName) => {
    const { checked } = e.target;

    // Prevent unchecking "All Client"
    if (groupName === "All Client" && !checked) {
      return;
    }

    // Find the client
    const client = data.find(item => item.clientId === clientId);
    if (!client) return;

    let updatedGroups;
    if (checked) {
      // Add group if not already present
      if (!client.groups.includes(groupName)) {
        updatedGroups = [...client.groups, groupName];
      } else {
        return; // Group already exists
      }
    } else {
      // Remove group if it's not "All Client"
      if (groupName !== "All Client") {
        updatedGroups = client.groups.filter(g => g !== groupName);
      } else {
        return; // Cannot remove "All Client"
      }
    }

    // Ensure "All Client" is always included
    if (!updatedGroups.includes("All Client")) {
      updatedGroups = ["All Client", ...updatedGroups];
    }

    // Update local state immediately for better UX
    setData(prevData =>
      prevData.map(item =>
        item.clientId === clientId
          ? { ...item, groups: updatedGroups }
          : item
      )
    );

    // Call the debounced API update
    debouncedHandleAddExistingClientToGroup(checked, clientId, groupName);
  };



  return (

   <AnimatePresence mode="wait">
     <motion.div
         initial={{opacity: 0}}
         animate={{opacity: 1}}
         exit={{opacity: 0}}
         transition={{duration: 0.3}}
         className="w-full h-full max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-50px)] overflow-auto mb-4"
     >
        {/* Info Banner */}
      <div className="bg-gradient-to-r from-gray-900 to-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center space-x-3">
            <InfoIcon className="h-5 w-5 text-gray-300" />
            <div>
              <h2 className="text-sm font-semibold">Client Management System</h2>
              <p className="text-xs text-gray-300 mt-0.5">
                Efficiently manage your client portfolio with comprehensive tools for client data management, organization of clients into groups for advice generation, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
       <table className="w-full bg-white font-poppins shadow-sm rounded-lg overflow-hidden">
         {/* Table header styling improvements */}
         <thead className="bg-gradient-to-r from-gray-50 to-gray-100 sticky top-0 z-20">
         <tr>
           <th className="w-[50px]  py-3 font-medium text-[13px] font-poppins text-white border-r-[1px] border-[#000000]/10">
             <div className="flex justify-center">
               <div
                   onClick={handleAddRow}
                   className="w-5 h-5 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
               >
                 <PlusIcon
                     strokeWidth={3}
                     className="text-[#ffffff] w-[18px] h-[18px]"
                 />
               </div>
             </div>
           </th>
           <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Client Id
           </th>
           <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Client Name
             <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
           </th>
           <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Email Id
             <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
           </th>

           <th className="px-3 py-3 max-w-[200px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Group Name(s)
           </th>
           <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Phone No.
             <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
           </th>
           <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Location
           </th>
           <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             Telegram Id
           </th>
           <th className="px-2 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
             KYC
           </th>
           <th className="px-3 py-3 min-w-[150px] font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
             Creation Date
             <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
           </th>
           <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
             Plans
           </th>
           <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
             Actions
           </th>
         </tr>
         </thead>

         <tbody>
         {newClientVisible && (
             <tr className="">
               <td className="px-3 py-4 max-w-[70px]">
                 {/* Empty cell for spacing */}
               </td>
               <td className="min-w-[100px] px-4 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="clientId"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.clientId}
                     onChange={handleInputChange}
                     placeholder="Client Id"
                     readOnly
                 />
               </td>

               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="clientName"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.clientName}
                     onChange={handleInputChange}
                     placeholder="Client Name"
                 />
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="email"
                     name="email"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.email}
                     onChange={handleInputChange}
                     placeholder="Email Id"
                 />
               </td>

               <td className="min-w-[160px] px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <div
                     className="relative flex items-start justify-between"
                     ref={dropdownRef}
                 >
                   <div className="flex flex-wrap gap-[4px]">
                     {newClient.groups.length > 0 ? (
                         newClient.groups.map((group, index) => (
                             <button
                                 key={index}
                                 className="bg-white font-normal px-2 py-0.5 rounded-full text-[10px] border-[1px] border-[#000000]/10 text-[#000000]/80"
                             >
                               {group}
                             </button>
                         ))
                     ) : (
                         <span className="text-gray-400">Group Name(s)</span>
                     )}
                   </div>
                   <button
                       onClick={() => toggleDropdown("newClient")}
                       className="flex items-center justify-center"
                   >
                     <ChevronDown className="text-[#000000]/80 w-6 h-6"/>
                   </button>
                   {dropdownOpen["newClient"] && (
                       <div
                           className={`absolute z-20 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg h-[180px] overflow-y-auto`}
                       >
                         {allGroupsName && allGroupsName.length > 0 ? (
                             allGroupsName?.map((group) => (
                                 <label
                                     key={group}
                                     className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b-[1px] last-of-type:border-[0px] border-[#000000]/20 cursor-pointer"
                                 >
                                   <input
                                       type="checkbox"
                                       value={group}
                                       checked={newClient.groups.includes(group)}
                                       onChange={() => handleGroupChange(group)}
                                       className="mr-2 accent-black"
                                   />
                                   {group}
                                 </label>
                             ))
                         ) : (
                             <div className="px-4 py-2 text-sm text-gray-500">
                               No groups available
                             </div>
                         )}
                       </div>
                   )}
                 </div>
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="phone"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.phone}
                     onChange={handleInputChange}
                     placeholder="Phone No."
                 />
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="location"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.location}
                     onChange={handleInputChange}
                     placeholder="Location"
                 />
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="telegram"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.telegram}
                     onChange={handleInputChange}
                     placeholder="Telegram Id"
                 />
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="pan"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.pan}
                     onChange={handleInputChange}
                     placeholder="KYC"
                 />
               </td>
               <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                 <input
                     type="text"
                     name="creationDate"
                     className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                     value={newClient.creationDate}
                     placeholder="Creation date"
                     readOnly
                 />
               </td>

               <td
                   colSpan={2}
                   className="px-2 py-4 font-normal text-[13px] font-poppins text-[#000000]/80"
               >
                 <div className="flex justify-start ">
                   {/* <div
                    onClick={() => handleAddEmptySubRow()}
                    className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium font-poppins rounded-[4px] px-[6px] py-[4px] cursor-pointer"
                  >
                    Add Plan
                  </div> */}
                   <button
                       disabled={
                           !newClient.clientId ||
                           !newClient.email ||
                           !newClient.clientName ||
                           !newClient.creationDate ||
                           !newClient.phone
                       }
                       onClick={handleAddClient}
                       className="px-3 py-1 disabled:bg-opacity-50 disabled:cursor-not-allowed bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
                   >
                     Save
                   </button>

                   <button
                       onClick={handleCancel}
                       className="px-2 py-1  text-black font-medium text-[12px] rounded"
                   >
                     <Trash2Icon className="h-4 w-4"/>
                   </button>
                 </div>
               </td>
             </tr>
         )}

         {filteredData &&
             filteredData?.map((item, index) => (
              <React.Fragment key={item.clientId}> {/* Changed key to use clientId */}
                <tr
                  className={`hover:bg-gray-50 transition-colors duration-150 ${
                    expandedRows[index]
                      ? "border-b-0"
                      : "border-b border-gray-200"
                  }`}
                >
                     <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80 ">
                       <div className="flex items-center justify-center">
                         {item.comments && (
                             <div className="relative group w-full">
                               <MessageSquareText className="h-5 w-5 text-gray-600 cursor-pointer "/>
                               <div
                                   className="z-20 mt-1 absolute top-full min-w-[200px] mb-2 hidden group-hover:inline-block   bg-gray-800 text-white text-[11px] rounded-[3px] p-2">
                                 {item.comments}
                                 <div
                                     className="absolute -top-1 left-[12px] transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
                               </div>
                             </div>
                         )}
                       </div>
                     </td>

                     <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80 ">
                       <div className="flex items-center justify-between">
                      <span>
                        {item.subscriptions?.length > 0 && (
                            <AiFillCaretRight
                                className={`cursor-pointer h-5 w-5 text-black/80 transform ${
                                    expandedRows[index] ? "rotate-90" : ""
                                }`}
                                onClick={() => toggleRow(index)}
                            />
                        )}
                      </span>
                         <span>{item.clientId}</span>
                       </div>
                     </td>
                     <td className="px-3 py-4 max-w-[180px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    <span className="mr-[4px] text-[12px] font-medium font-poppins">
                      ({item.subscriptions?.length})
                    </span>

                       {item.clientName}
                     </td>
                     <td className="px-3 py-4 max-w-[180px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                       {item.email}
                     </td>

                     {/* Updated group dropdown */}
                  <td className="min-w-[200px] px-3 py-4">
                    <div className="relative flex items-start justify-between">
                      <div className="flex flex-wrap gap-1">
                        {item.groups?.map((group, groupIndex) => (
                            <span
                                key={groupIndex}
                                className="bg-white font-normal px-2 py-0.5 rounded-full text-xs border border-gray-200 text-gray-700"
                            >
          {group?.replace(/_/g, " ")}
        </span>
                        ))}
                      </div>
                      <button
                          onClick={() => toggleDropdown(index)}
                          className="flex items-center justify-center"
                      >
                        <ChevronDown className="w-5 h-5 text-gray-500"/>
                      </button>

                      {dropdownOpen[index] && (
                          <div
                              ref={dropdownRef}
                              className="absolute z-20 right-0 mt-8 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 max-h-[180px] overflow-y-auto"
                          >
                            {allGroupsName.map((group) => (
                                <label
                                    key={group}
                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
                                >
                                  <input
                                      type="checkbox"
                                      checked={item.groups?.includes(group)}
                                      onChange={(e) => handleCheckboxChange(e, item.clientId, group)}
                                      className="mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      disabled={group === "All Client"}
                                  />
                                  {group?.replace(/_/g, " ")}
                                </label>
                            ))}
                          </div>
                      )}
                    </div>
                  </td>


                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.phone}
                  </td>
                  <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.location}
                  </td>
                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.telegram?.includes("@") ? (
                        item.telegram
                    ) : item.telegram?.length > 0 ? (
                        <>@{item.telegram}</>
                    ) : (
                        ""
                    )}
                  </td>
                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.pan}
                  </td>
                  <td className="px-3 py-4 max-w-[130px] font-normal text-[13px] font-poppins text-center text-[#000000]/80">
                    {FormatDate(item.creationDate)}
                  </td>
                  <td className="px-2 py-4 min-w-[80px]  font-normal text-[12px] font-poppins text-[#000000]/80">
                    <div
                        onClick={() => handleAddSubRow(index)}
                        className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium font-poppins rounded-[4px] px-[6px] py-[4px] cursor-pointer"
                       >
                         Add Plan
                       </div>
                     </td>
                     <td className="px-2 py-4  font-normal text-[12px] font-poppins text-[#000000]/80">
                       <div className="flex items-center justify-center space-x-2">
                         <Edit2Icon
                             className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                             onClick={() => handleEditClientModal(item)}
                         />
                         <Trash2Icon
                             className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                             onClick={() => handleDeleteModal(item)}
                         />
                         {/* <MessageSquarePlus
                        className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                        onClick={() => handleCommentUpdateModal(item)}
                      /> */}
                       </div>
                     </td>
                   </tr>
                   {expandedRows[index] && (
                       <tr className="bg-white">
                         <td
                             colSpan="11"
                             className="py-4 px-[130px] border-b-[1px] border-[#000000]/10 "
                         >
                           <table className="w-full border-[1px] border-[#000000]/10">
                             <thead className="bg-[#F5F5F5]/80">
                             <tr>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                                 <div className="flex items-center space-x-2">
                                   <div
                                       onClick={() => handleAddSubRow(index)}
                                       className="w-4 h-4 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
                                   >
                                     <PlusIcon
                                         strokeWidth={4}
                                         className="text-[#ffffff] w-[12px] h-[12px]"
                                     />
                                   </div>
                                   <div className="font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                                     Start Date
                                     <span className="ml-1 text-[#E6626F] text-[14px]">
                                    *
                                  </span>
                                   </div>
                                 </div>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                                 Subscription Plan
                                 <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                                 Capital Deployed
                                 <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                                 Charges
                                 <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                                 Invoice No.
                                 <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                                 Expiry Date
                                 <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                               </th>
                               <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                                 Actions
                               </th>
                             </tr>
                             </thead>
                             <tbody>
                             {newSubRow && (
                                 <tr className="border-b-[1px] border-[#000000]/10">
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <input
                                         type="text"
                                         name="startDate"
                                         value={newSubscription.startDate}
                                         onChange={(e) => handleSubscriptionChange(e)}
                                         className="w-full p-2 border rounded"
                                         placeholder="Start Date"
                                     />
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <input
                                         type="text"
                                         name="plan"
                                         value={newSubscription.plan}
                                         onChange={(e) => handleSubscriptionChange(e)}
                                         className="w-full p-2 border rounded"
                                         placeholder="Plan"
                                     />
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <div className="flex relative">
                                       <input
                                           type="number"
                                           name="capital"
                                           value={newSubscription.capital}
                                           onChange={(e) =>
                                               handleSubscriptionChange(e)
                                           }
                                           className="w-full p-2 border rounded pl-[24px]"
                                           placeholder="Capital"
                                       />
                                       <div
                                           className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black font-poppins py-[7px]  px-2">
                                         ₹
                                       </div>
                                     </div>
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <div className="flex relative">
                                       <input
                                           type="number"
                                           name="charges"
                                           value={newSubscription.charges}
                                           onChange={(e) =>
                                               handleSubscriptionChange(e)
                                           }
                                           className="w-full p-2 border rounded pl-[24px]"
                                           placeholder="Charges"
                                       />
                                       <div
                                           className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black font-poppins py-[7px]  px-2">
                                         ₹
                                       </div>
                                     </div>
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <input
                                         type="number"
                                         name="invoice"
                                         value={newSubscription.invoice}
                                         onChange={(e) => handleSubscriptionChange(e)}
                                         className="w-full p-2 border rounded"
                                         placeholder="Invoice"
                                     />
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     <input
                                         type="text"
                                         name="expiry"
                                         value={newSubscription.expiry}
                                         onChange={(e) => handleSubscriptionChange(e)}
                                         className="w-full p-2 border rounded"
                                         placeholder="Expiry Date"
                                     />
                                   </td>
                                   <td className="px-4 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                                     <div className="flex space-x-0">
                                       <button
                                           disabled={
                                               !newSubscription.startDate ||
                                               !newSubscription.plan ||
                                               !newSubscription.capital ||
                                               !newSubscription.charges ||
                                               !newSubscription.invoice ||
                                               !newSubscription.expiry
                                           }
                                           onClick={() =>
                                               handleAddPlan(index, item.clientId)
                                           }
                                           className="disabled:cursor-not-allowed disabled:bg-opacity-50 px-4 py-1 bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
                                       >
                                         Save
                                       </button>
                                       <button
                                           onClick={() => handleCancelSubRow(index)}
                                           className="px-4 py-1  text-black font-medium text-[12px] rounded"
                                       >
                                         <Trash2Icon className="h-4 w-4"/>
                                       </button>
                                     </div>
                                   </td>
                                 </tr>
                             )}
                             {item.subscriptions?.map((sub, subIndex) => (
                                 <tr
                                     key={subIndex}
                                     className="border-b-[1px] border-[#000000]/10"
                                 >
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     {FormatDate(sub.startDate)}
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                     {sub.plan}
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                     {sub.capital}
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                     {sub.charges}
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                     {sub.invoice}
                                   </td>
                                   <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                     {FormatDate(sub.expiry)}
                                   </td>
                                   <td className="px-4 py-4 font-normal text-[12px] font-poppins text-[#000000]/80">
                                     <div className="flex items-center justify-center space-x-2">
                                       <Edit2Icon
                                           onClick={() =>
                                               handleEditSubscriptionModal(
                                                   item,
                                                   sub.subId
                                               )
                                           }
                                           className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                                       />
                                       <Trash2Icon
                                           onClick={() =>
                                               handleDeleteSubscriptionModal(
                                                   index,
                                                   item,
                                                   sub.subId
                                               )
                                           }
                                           className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                                       />
                                     </div>
                                   </td>
                                 </tr>
                             ))}
                             </tbody>
                           </table>
                         </td>
                       </tr>
                   )}
                 </React.Fragment>
             ))}
         </tbody>
       </table>


       {showEditModal && (
           <EditClientModal
               client={clientToEdit}
               onClose={() => setShowEditModal(false)}
               onUpdate={handleUpdateClient}
           />
       )}
       {showDeleteModal && (
           <DeleteModal
               client={clientToDelete}
               onClose={() => setShowDeleteModal(false)}
               onDelete={handleDeleteClient}
           />
       )}

       {showSubscriptionDeleteModal && (
           <SubscriptionDeleteModal
               clientId={subscriptionClientId}
               subscriptionToDelete={subscriptionToDelete}
               onClose={() => setShowSubscriptionDeleteModal(false)}
               onDelete={handleDeleteSubscription}
           />
       )}

       {showSubscriptionEditModal && (
           <EditSubscriptionModal
               clientId={subscriptionClientId}
               subscriptionToEdit={subscriptionToEdit}
               onClose={() => setShowSubscriptionEditModal(false)}
               onUpdate={handleUpdateSubscription}
           />
       )}

     </motion.div>
   </AnimatePresence>
  );
};

export default ClientListNewTable;
