import { ChevronDownIcon, ChevronUpIcon, XIcon,AlertTriangle } from "lucide-react";
import React, { useEffect, useState } from "react";
import CheckedIcon from "../../assests/checked.svg";
import FailureIcon from "../../assests/failure.svg";
import PartialSuccessIcon from "../../assests/partial_success.svg";
import Ddpi from "../../assests/DDPI.svg"
import { Link } from "react-router-dom";

import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import moment from "moment";

const RecommendationSuccessModal = ({
  setOpenSucessModal,
  orderPlacementResponse,
  setStockDetails,
  openSuccessModal,
  setSelectedRecommendation,
}) => {
  // Function to get the formatted date

  const [orderResponse, setOrderResponse] = useState(orderPlacementResponse);

  useEffect(() => {
    setOrderResponse(orderPlacementResponse);
  }, []);

  const [showStocksDetails, setShowStocksDetails] = useState(false);

  const getFormattedDate = () => {
    const date = new Date();
    return moment(date).format("Do MMM YYYY");
  };

  const toggleStocksDetails = () => {
    setShowStocksDetails(!showStocksDetails);
  };

  const successCount = orderResponse?.filter(
    (item) =>
      item?.orderStatus === "complete" ||
      item?.orderStatus === "COMPLETE" ||
      item?.orderStatus === "Placed" ||
      item?.orderStatus === "Executed" ||
      item?.orderStatus === "Ordered" ||
      item?.orderStatus === "open" ||
      item?.orderStatus === "OPEN" ||
      item?.orderStatus === "Traded"
  ).length;

  const failureCount = orderResponse?.filter(
    (item) =>
      item?.orderStatus === "rejected" ||
      item?.orderStatus === "Rejected" ||
      item?.orderStatus === "cancelled"
  ).length;

  const totalCount = orderResponse?.length;

  const successPercentage = (successCount / totalCount) * 100;
  const failurePercentage = (failureCount / totalCount) * 100;
  const partialFailurePercentage = 100 - successPercentage;

  const isDesktop = useMediaQuery("(min-width: 830px)");

  if (isDesktop) {
    return (
      <Dialog open={openSuccessModal} onOpenChange={setOpenSucessModal}>
        <DialogContent className=" max-w-[780px] w-full p-0 max-h-[90vh] animate-modal">
          <div className="flex flex-col w-full pt-4  rounded-md">
            {/* Complete Success  */}
            {successCount === totalCount && (
              <div className="flex space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12 lg:py-3">
                <img
                  src={CheckedIcon}
                  alt="Check Icon"
                  className="w-[45px] lg:w-[50px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-0 flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    All Orders Placed Successfully
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          setOpenSucessModal(false);
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}
                    below.
                  </p>
                </div>
              </div>
            )}

            {/* Complete Failure  */}

            {failureCount === totalCount && (
              <div className="mt-2 flex  space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12">
                <img
                  src={FailureIcon}
                  alt="Cross Icon "
                  className="w-[60px] lg:w-[90px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-2 md:max-w-[500px] flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    No Orders Placed
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the{" "}
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          setOpenSucessModal(false);
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}{" "}
                    below and contact your advisor for next steps.{" "}
                  </p>
                </div>
              </div>
            )}

            {/* Partial Success  */}

            {successCount >= 1 && successCount !== totalCount && (
              <div className="flex space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12 lg:py-3">
                <img
                  src={PartialSuccessIcon}
                  alt="Cross Icon"
                  className="w-[45px] lg:w-[50px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-2 flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    Some Orders Placed Successfully
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the{" "}
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          setOpenSucessModal(false);
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}{" "}
                    below for more information.{" "}
                  </p>
                </div>
              </div>
            )}

            <div className="mt-2 grid grid-cols-3 lg:mt-[16px] lg:h-[80px] border-t-[1px] border-r-[#000000]/10 bg-[#F5F5F5]  justify-between rounded-md">
              <div className="flex flex-col px-2 justify-center rounded-bl-md border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  Placed On
                </h4>
                <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                  {getFormattedDate()}
                </p>
              </div>
              <div className="flex flex-col px-2 justify-center border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  Status
                </h4>
                <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                  Placed
                </p>
              </div>
              <div className="flex flex-col px-2 space-y-[2px] lg:space-y-[8px] justify-center lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  {successCount} of {totalCount} Executed
                </h4>
                <div className="flex items-center space-x-2">
                  <div className="flex w-full bg-[#D9D9D9] h-2 lg:h-3 rounded-[3px] ">
                    {successCount === totalCount && (
                      <div
                        className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                        style={{ width: `${successPercentage}%` }}
                      ></div>
                    )}

                    {failureCount === totalCount && (
                      <div
                        className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                        style={{ width: `${failurePercentage}%` }}
                      ></div>
                    )}
                    {successCount >= 1 && successCount !== totalCount && (
                      <>
                        <div
                          className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                          style={{ width: `${successPercentage}%` }}
                        ></div>
                        <div
                          className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                          style={{ width: `${partialFailurePercentage}%` }}
                        ></div>
                      </>
                    )}
                  </div>
                  {showStocksDetails === true ? (
                    <ChevronUpIcon
                      onClick={toggleStocksDetails}
                      className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                    />
                  ) : (
                    <ChevronDownIcon
                      onClick={toggleStocksDetails}
                      className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>

            {showStocksDetails && (
              <div className=" w-full border-t-[1px] border-[#000000]/10 h-[330px] lg:h-[380px] mb-2 lg:mb-4 overflow-auto custom-scroll">
                <table className={`w-full `}>
                  <thead className="bg-[#ffffff] z-20 sticky top-0">
                    <tr className="border-b-[1px]   border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-5">
                        Stocks
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order
                      </th>
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Quantity
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderResponse?.map((ele, i) => {
                      return (
                        <tr
                          key={i}
                          className={
                            ele?.orderStatus === "Placed" ||
                            ele?.orderStatus === "complete" ||
                            ele?.orderStatus === "COMPLETE" ||
                            ele?.orderStatus === "Executed" ||
                            ele?.orderStatus === "open" ||
                            ele?.orderStatus === "OPEN" ||
                            ele?.orderStatus === "Ordered" ||
                            ele?.orderStatus === "Traded"
                              ? `border-b-[1px]   border-[#000000]/10 last-of-type:border-none`
                              : `border-b-[1px]   border-b-[#000000]/10  bg-[#FF0000]/5 last-of-type:border-none `
                          }
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-5 ">
                            {ele?.symbol}
                          </td>

                          <td
                            className={`text-[15px]  text-[#000000]/80 capitalize font-poppins font-medium text-center py-3 px-5
                          ${
                            ele?.transactionType?.toLowerCase() === "buy"
                              ? "text-[#16A085]"
                              : ele?.transactionType?.toLowerCase() === "sell"
                              ? "text-[#E43D3D] "
                              : "text-[#000000]/80"
                          }
                          `}
                          >
                            {ele?.transactionType?.toLowerCase()}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                            {ele?.quantity}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 capitalize font-poppins font-medium text-center py-3 px-5 ">
                            {ele?.orderType}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                            {!ele?.message_aq ||
                            ele?.message_aq.toLowerCase() === "no message" ||
                            ele?.message_aq === "--" ? (
                              <span className="capitalize">
                                {ele?.orderStatus}
                              </span>
                            ) : (
                              <span className="capitalize">
                                {ele?.message_aq.toLowerCase()}
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
               
              </div>
              
            )}
             <div className="font-poppins bg-white shadow-md overflow-hidden border-t-[3.5px] border-gray-600">
      <div className="flex">
        <div className="w-3/4 pl-2">
          <div className="flex">
            <div className="w-9/10 p-4 flex flex-col">
              <div className="flex items-start mb-2">
                <AlertTriangle className="text-red-500 w-5 h-5 mr-2 " />
                <div className="flex flex-col">
                  <h2 className="text-base font-poppins font-semibold text-gray-800 mb-2">
                    DDPI Inactive: Proceed with TPIN Mandate
                  </h2>
                  <ul className="list-disc pl-5 text-sm font-poppins text-gray-600 mb-4 space-y-2">
                    <li>Use TPIN for a temporary mandate to sell selected stocks</li>
                    <li>Join <span className="font-bold">90%</span> of users with DDPI enabled for fast selling</li>
                  </ul>
                </div>
              </div>

              <div className="flex space-x-2 ml-8">
                <button className="bg-[#D82F25] text-white px-6 py-2 rounded-md text-xs font-medium hover:bg-[#B82620] transition-colors duration-200">
                  Proceed with TPIN
                </button>
                <button className="bg-white text-[#3B82F6] border border-[#3B82F6] px-8 py-2 rounded-md text-xs font-medium hover:bg-gray-100 transition-colors duration-200">
                Preferred by most
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 flex items-center justify-center pr-2">
          <img
            src={Ddpi}
            alt="DDPI"
            width={150}
            height={150}
            className="object-cover"
          />
        </div>
      </div>
    </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openSuccessModal} onOpenChange={setOpenSucessModal}>
      <DrawerContent className="focus:outline-none max-h-[600px] md:min-h-[340px] md:max-h-[650px] w-full animate-modal">
        <div
          onClick={() => {
            setOpenSucessModal(false);
            setStockDetails([]);
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="h-full pt-[10px]">
          <div className="relative">
            <div className="flex flex-col  lg:w-[650px]  pt-1 bg-white  border-[#000000]/20 rounded-md">
              {/* Complete Success  */}
              {successCount === totalCount && (
                <div className="flex space-x-2 mt-[6px]  lg:space-x-4 px-4 lg:px-12">
                  <img
                    src={CheckedIcon}
                    alt="Check Icon"
                    className="w-[40px] lg:w-[60px] rounded-full mr-2 -mt-4"
                  />

                  <div className="flex flex-col   space-y-2">
                    <h3 className="text-[#000000] text-[15px] lg:text-[20px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                      All Orders Placed Successfully
                    </h3>
                    <p className=" text-[#000000] text-[13px] lg:text-[13px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                      Please review the
                      <Link
                        className=" text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                      below.
                    </p>
                  </div>
                </div>
              )}

              {/* Complete Failure  */}

              {failureCount === totalCount && (
                <div className="flex  space-x-2 mt-[6px]  lg:space-x-4 px-4 lg:px-12">
                  <img
                    src={FailureIcon}
                    alt="Cross Icon "
                    className="w-[60px] lg:w-[100px] rounded-full -mt-10"
                  />

                  <div className="flex flex-col   space-y-2 max-w-[250px]">
                    <h3 className="text-[#000000] text-[15px] lg:text-[20px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                      No Orders Placed
                    </h3>
                    <p className=" text-[#000000] text-[13px] lg:text-[13px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                      Please review the{" "}
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>{" "}
                      below and contact your advisor for next steps.{" "}
                    </p>
                  </div>
                </div>
              )}

              {/* Partial Success  */}

              {successCount >= 1 && successCount !== totalCount && (
                <div className="flex  space-x-2 mt-[6px]  lg:space-x-4 px-4 lg:px-12">
                  <img
                    src={PartialSuccessIcon}
                    alt="Cross Icon "
                    className="w-[40px] lg:w-[60px] rounded-full mr-2 -mt-4"
                  />

                  <div className="flex flex-col   space-y-2">
                    <h3 className="text-[#000000] text-[15px] lg:text-[20px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                      Some Orders Placed Successfully
                    </h3>
                    <p className=" text-[#000000] text-[13px] lg:text-[13px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                      Please review the{" "}
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>{" "}
                      below for more information.{" "}
                    </p>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-3 mt-[16px]  lg:h-[80px] border-t-[1px] border-r-[#000000]/10 bg-[#ffffff]  justify-between rounded-md">
                <div className="flex flex-col px-2 justify-center rounded-bl-md border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                  <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                    Placed On
                  </h4>
                  <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                    {getFormattedDate()}
                  </p>
                </div>
                <div className="flex flex-col px-2 justify-center border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                  <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                    Status
                  </h4>
                  <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                    Placed
                  </p>
                </div>
                <div className="flex flex-col px-2 space-y-[2px] lg:space-y-[8px] justify-center lg:px-6 py-2 ">
                  <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                    {successCount} of {totalCount} Executed
                  </h4>
                  <div className="flex items-center space-x-2">
                    <div className="flex w-full bg-[#D9D9D9] h-2 lg:h-3 rounded-[3px] ">
                      {successCount === totalCount && (
                        <div
                          className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                          style={{ width: `${successPercentage}%` }}
                        ></div>
                      )}

                      {failureCount === totalCount && (
                        <div
                          className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                          style={{ width: `${failurePercentage}%` }}
                        ></div>
                      )}
                      {successCount >= 1 && successCount !== totalCount && (
                        <>
                          <div
                            className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                            style={{ width: `${successPercentage}%` }}
                          ></div>
                          <div
                            className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                            style={{ width: `${partialFailurePercentage}%` }}
                          ></div>
                        </>
                      )}
                    </div>
                    {showStocksDetails === true ? (
                      <ChevronUpIcon
                        onClick={toggleStocksDetails}
                        className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                      />
                    ) : (
                      <ChevronDownIcon
                        onClick={toggleStocksDetails}
                        className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              </div>

              {showStocksDetails && (
                <div className=" w-full h-[250px] border-t-[1px] border-[#000000]/10 mb-2 overflow-auto custom-scroll">
                  {/* <table className={`w-full `}>
                    <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                      <tr className="border-b-[1px]  border-[#000000]/10">
                        <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-5">
                          Stocks
                        </th>

                        <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Order
                        </th>
                        <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Quantity
                        </th>
                        <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Order Type
                        </th>
                        <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderResponse?.map((ele) => {
                        return (
                          <tr
                            key={ele?.orderid}
                            className={
                              ele?.orderStatus === "Placed" ||
                              ele?.orderStatus === "complete" ||
                              ele?.orderStatus === "COMPLETE" ||
                              ele?.orderStatus === "Executed" ||
                              ele?.orderStatus === "open" || ele?.orderStatus === "OPEN" ||
                              ele?.orderStatus === "Ordered" ||
                              ele?.orderStatus === "Traded"
                                ? `border-b-[1px]   border-[#000000]/10 last-of-type:border-none`
                                : `border-b-[1px]   border-b-[#000000]/10  bg-[#FF0000]/5 last-of-type:border-none  `
                            }
                          >
                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-5 ">
                              {ele?.symbol}
                            </td>

                            <td
                              className={`text-[15px] capitalize text-[#000000]/80 font-poppins font-medium text-center py-3 px-5
                          ${
                            ele?.transactionType?.toLowerCase() === "buy"
                              ? "text-[#16A085]"
                              : ele?.transactionType?.toLowerCase() === "sell"
                              ? "text-[#E43D3D] "
                              : "text-[#000000]/80"
                          }
                          `}
                            >
                              {ele?.transactionType?.toLowerCase()}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                              {ele?.quantity}
                            </td>
                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                              {ele?.orderType}
                            </td>
                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                              {ele?.message_aq === "" ||
                              ele?.message_aq === "no message" ? (
                                <span className="capitalize">
                                  {ele?.orderStatus}
                                </span>
                              ) : (
                                <span className="capitalize">
                                  {ele?.message_aq}
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}

                  {orderResponse?.map((ele) => (
                    <div
                      className={`${
                        ele?.orderStatus === "Placed" ||
                        ele?.orderStatus === "complete" ||
                        ele?.orderStatus === "COMPLETE" ||
                        ele?.orderStatus === "Executed" ||
                        ele?.orderStatus === "open" ||
                        ele?.orderStatus === "Ordered"
                          ? `border-b-[1px]   border-[#000000]/10 last-of-type:border-none`
                          : `border-b-[1px]   border-b-[#000000]/10  bg-[#FF0000]/5 last-of-type:border-none  `
                      } flex flex-col space-y-2  py-3 px-4  font-poppins`}
                    >
                      <div className="flex items-start justify-between">
                        <div className="flex flex-col items-start min-w-[180px]  space-y-[4px] overflow-hidden">
                          <div
                            className={`${
                              ele.transactionType?.toLowerCase() === "buy"
                                ? "bg-[#16A085]/10 text-[#16A085] "
                                : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
                            } flex items-center px-2 py-[1px] rounded-[4px]`}
                          >
                            <span className=" text-[12px]   px-1 py-0  font-poppins font-medium capitalize">
                              {ele.transactionType?.toLowerCase()}
                            </span>
                          </div>
                          <div className="text-[14px] truncate max-w-[180px] capitalize   text-[#000000] font-poppins font-semibold">
                            {ele.symbol}
                          </div>
                        </div>

                        <div className=" flex flex-col items-start space-y-[4px] text-[14px]  text-[#000000] font-poppins font-medium capitalize">
                          <div className="text-[14px]  w-full   text-[#000000]/60 font-poppins font-normal">
                            Type
                          </div>

                          <span className=" text-[14px]  w-full   text-[#000000] font-poppins font-semibold">
                            {ele?.orderType}
                          </span>
                        </div>

                        <div className="flex flex-col items-center space-y-[4px]">
                          <div className="text-[14px]  w-full   text-[#000000]/60 font-poppins font-normal">
                            Qty.
                          </div>
                          <div className="flex items-end text-[14px]   text-[#000000] font-poppins font-semibold">
                            {ele.quantity}
                          </div>
                        </div>
                      </div>

                      <div className="text-[11px]  text-[#000000]/60 font-poppins font-medium">
                        Status :
                        {!ele?.message_aq ||
                        ele?.message_aq.toLowerCase() === "no message" ||
                        ele?.message_aq === "--" ? (
                          <span className="capitalize">{ele?.orderStatus}</span>
                        ) : (
                          <span className="capitalize">
                            {ele?.message_aq.toLowerCase()}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default RecommendationSuccessModal;
