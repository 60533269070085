import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import AdminDashboardLayout from "../AdminDashboardLayout";
import server from "../../utils/serverConfig";
import ClientListDetailsSection from "./ClientListDetailsSection";

const ClientListDetailsSectionPage = () => {
  const { groupName } = useParams();

  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();
  const [allClientsDataByGroup, setAllClientsDataByGroup] = useState([]);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);

  const getAllClientsData = async (userId) => {
    try {
      setIsClientDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cleint data");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const clientsData = data.data;

      const clientDataByGroup = clientsData.filter((client) =>
        client.groups.some(
          (group) => group.toLowerCase() === groupName.toLowerCase()
        )
      );

      setAllClientsDataByGroup(clientDataByGroup);
      setIsClientDataLoading(false);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      setIsClientDataLoading(false);
      return [];
    }
  };

  useEffect(() => {
    if (adminEmail) {
      getAdminDetails(adminEmail)
        .then((fetchedData) => {
          setData(fetchedData);
          getAllClientsData(fetchedData?._id);
        })
        .catch((err) => console.log(err));
    }
  }, [adminEmail]);

  const userId = data && data?._id;
  const advisorName = data && data?.advisorName;
  const email_password = data && data?.email_password;
  const adminRationale = data && data?.rationale;
  const advisorSpecifier = data && data?.advisorSpecifier;

  return (
    <AdminDashboardLayout>
      <ClientListDetailsSection
        userId={userId && userId}
        groupName={groupName}
        adminEmail={adminEmail}
        advisorName={advisorName}
        email_password={email_password}
        allClientsDataByGroup={allClientsDataByGroup}
        isClientDataLoading={isClientDataLoading}
        getAllClientsData={getAllClientsData}
        adminRationale={adminRationale}
        advisorSpecifier={advisorSpecifier}
      />
    </AdminDashboardLayout>
  );
};

export default ClientListDetailsSectionPage;
