import React from "react";
import Logo from "./assests/Logo.jpg";

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const url = process.env.REACT_APP_URL;
const navItems = [
  { name: "Disclaimer", href: `https://${url}.alphaquark.in/disclaimer` },
  {
    name: `T&C ${whiteLabelText}`,
    href: `https://${url}.alphaquark.in/terms-and-conditions-by-advisor`,
  },
  {
    name: "Privacy Policy",
    href: `https://${url}.alphaquark.in/privacy-policy`,
  },
];

const Footer = () => {
  return (
    <footer className="bg-[#FAFAFA] py-8 font-poppins">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center mb-6">
          <div className="flex items-center mb-4">
            <img
              src={Logo}
              alt="AlphaQuark Logo"
              className="mr-4 w-8 h-8 md:w-10 md:h-auto rounded-md"
            />
            <span className="text-2xl md:text-4xl font-semibold leading-[30px] text-center font-montserrat">
              {whiteLabelText}
            </span>
          </div>
          <nav className="flex flex-wrap justify-center items-center">
            {navItems.map((item, index) => (
              <React.Fragment key={item.name}>
                {index > 0 && (
                  <span className="hidden sm:inline text-gray-300 mx-2">|</span>
                )}
                <a
                  href={item.href}
                  className="text-sm text-gray-500 hover:text-gray-900 py-1 px-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </a>
              </React.Fragment>
            ))}
          </nav>
        </div>
        <div className="w-full flex justify-center mb-6">
          <div className="w-[70%] h-px bg-gray-300"></div>
        </div>
        <div
          className="text-center text-gray-500 flex flex-col items-center"
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "30px",
          }}
        >
          <p className="flex flex-col sm:flex-row items-center justify-center">
            <span>Copyright © 2024 All rights reserved</span>
            <span className="hidden sm:inline mx-2">|</span>
            <span className="flex items-center">
              Made with{" "}
              <span className="text-red-500 mx-1" aria-hidden="true">
                ❤️
              </span>
              <span className="sr-only">love</span> by AlphaQuark
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
